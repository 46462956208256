import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { environment } from './../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService implements Config {

  onStaffApiToggle = new BehaviorSubject<boolean>(false);

  private _staffApi = true;

  private _config: any = environment.config;


  constructor() {}
  get dataApiUrl(): string {
    if (this._staffApi) {
      return this._config.staffApiUrl;
    }
    else {
      return this._config.dataApiUrl;
    }
  }

  get profilesLocationUrl(): string {
    return this._config.profilesLocationUrl;
  }

  get staffApiActive(): boolean {
    return this._staffApi;
  }

  get(key: any) {
    return this._config[key];
  }

  toggleStaffApi() {
    this._staffApi = !this._staffApi;
    this.onStaffApiToggle.next(this._staffApi);
  }



}

export interface Config {
  get dataApiUrl(): string;
}
