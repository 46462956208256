import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import { StorageService } from '../storage.service';
import { RefreshResponse, TokenObject } from './authInterfaces';
import { firstValueFrom, from, lastValueFrom, Observable, of, Subject, switchMap } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { UserProfile } from './userProfile';


export type LoginTypes = 'local' | 'google';
export interface LoginParameters {
  email?: string;
  password?: string;
  token?: string;
  type: LoginTypes;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  onUserLoggedIn = new Subject<UserProfile>();
  onUserLoggedOut = new Subject<void>();
  onAccessTokenUpdate = new Subject<UserProfile>();

  private _loggedIn = false;
  private refreshTokenKey = "refreshToken";
  private accessTokenKey = "accessToken";
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private storageService: StorageService
  ) { }


  //TODO: Improve this.  Maybe check refresh token expiration?

  loggedIn(): Observable<boolean> {

    return from<Promise<string>>(this.getRefreshToken()).pipe(switchMap((token) => { //read token and check for expiration here
      if (token) {
        const decodedToken = jwt_decode<UserProfile>(token);
        const expDate = new Date(decodedToken.exp * 1000);
        if (expDate > new Date()) {
          return of(true);
        }
        else {
          return of(false);
        }

      }
      else {
        return of(false);
      }
    }
    ));

  }

  getUserProfile(): Observable<UserProfile> {
    return from<Promise<string>>(this.getAuthToken()).pipe(switchMap((token => {
      if (token) {
        const decodedToken = jwt_decode<UserProfile>(token);
        if (this.configService.staffApiActive) { //ID is stored in a different place on staff token
          decodedToken.ID = (decodedToken as any).newstaffId;
          decodedToken.isStaff = true;
        }
        return of(decodedToken);
      }
      else {
        return of(null);
      }
    })));
  }


  async getAuthToken(): Promise<string> {
    return await this.storageService.get(this.accessTokenKey);
  }

  async getRefreshToken(): Promise<string> {
    return await this.storageService.get(this.refreshTokenKey);
  }

  async setToken(tokenObject: Partial<TokenObject>) {
    if (tokenObject.accessToken) {
      await this.storageService.set(this.accessTokenKey, tokenObject.accessToken);
      this.onAccessTokenUpdate.next(jwt_decode<UserProfile>(tokenObject.accessToken));
    }

    if (tokenObject.refreshToken) {
      await this.storageService.set(this.refreshTokenKey, tokenObject.refreshToken);
    }
  }

  async clearToken() {
    await this.storageService.remove(this.refreshTokenKey);
    await this.storageService.remove(this.accessTokenKey);
  }

  async login(params: LoginParameters) {

    let loginObservable: Observable<TokenObject>;

    if (params.type === 'local') {
      loginObservable = this.http.post<TokenObject>(this.configService.dataApiUrl + '/users/refresh-login', {
        email: params.email, password: params.password
      });
    }
    else if (params.type === 'google') {
      loginObservable = this.http.post<TokenObject>(this.configService.dataApiUrl + '/users/google-oauth', {
        credential: params.token
      });
    }

    let loggedIn = false;
    try {
      const result = await lastValueFrom<TokenObject>(
       loginObservable
      );

      await this.storageService.set(this.refreshTokenKey, result.refreshToken);
      await this.storageService.set(this.accessTokenKey, result.accessToken);
      loggedIn = true;
      this.onUserLoggedIn.next(jwt_decode<UserProfile>(result.accessToken));
    }
    catch (err) {
      console.log(err);
    }

    return loggedIn;

  }



  refreshLogin() {


    return from(this.getRefreshToken()).pipe(switchMap(token =>
      this.http.post<RefreshResponse>(`${this.configService.dataApiUrl}/users/refresh`, { refreshToken: token })
    ));
  }

  logout() {


    return from(this.getRefreshToken()).pipe(
      switchMap(token => {
        if (token) {
          return this.http.post(`${this.configService.dataApiUrl}/users/logout`, { refreshToken: token });
        }
        else {
          return of(false);
        }
      }
      ),
      switchMap(response => {
        this.onUserLoggedOut.next();
        return from(this.clearToken());
      }
      ));
  }


}
