import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { of, switchMap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { ConfigService } from '../config.service';
import { GuideResponse } from './classes/guideResponse';

@Injectable({
  providedIn: 'root'
})
export class GuideService {

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
    private authService: AuthService
  ) { }


  getPageContent(guideYear: number, tripId: number, pageNumber: number) {
    let params = new HttpParams();


    return this.authService.getUserProfile().pipe(
      switchMap(profile => {
        params = params.append("guideYear", guideYear);
        params = params.append("tripId", tripId);
        params = params.append("pageNumber", pageNumber);
        params = params.append("userId", profile.ID);

        return this.httpClient.get<GuideResponse>(this.configService.dataApiUrl + "/guide/responses/", { params: params });
      }),

      switchMap(response => {

        return of(this.convertIsoDates(response));
      })
    );
  }


  setPageContent(guideYear: number, tripId: number, pageNumber: number, localLastUpdated: Date, fields: string[]) {



    return this.authService.getUserProfile().pipe(
      switchMap(profile => {

        const body = JSON.stringify({
          guideYear: guideYear,
          tripId: tripId,
          pageNumber: pageNumber,
          localLastUpdated: DateTime.fromJSDate(localLastUpdated).toISO(),
          responses: JSON.stringify(fields),
          newuserId: profile.ID
        });

        return this.httpClient.post<GuideResponse>(this.configService.dataApiUrl + "/guide/responses", body);
      }),

      switchMap(response => of(this.convertIsoDates(response)))
    );
  }

  private convertIsoDates(response: GuideResponse): GuideResponse {
    if (response) {
      return {
        ...response,
        lastUpdated: DateTime.fromISO((response.lastUpdated as unknown as string)).toJSDate(),
        localLastUpdated: DateTime.fromISO((response.localLastUpdated as unknown as string)).toJSDate()
      };
    }
    else {
      return response;
    }
  }

}
