/* eslint-disable object-shorthand */
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, catchError, firstValueFrom, from, Observable, of, Subject, switchMap } from 'rxjs';
import { TripsActive } from 'src/app/api/classes/tripsActive';
import { GuideService } from 'src/app/api/guide.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ConfigService } from 'src/app/config.service';
import { StorageService } from 'src/app/storage.service';


export interface ConflictData {
  page: UserContentPage;
  localContent: {
    content: string[];
    updatedDate: Date;
  };
  remoteContent: {
    content: string[];
    updatedDate: Date;
  };
}

const userContentPages = [3,4,5,7,8,9,13] as const;
export type UserContentPage = typeof userContentPages[number];
export interface GuideUserContent {
  page: UserContentPage;
  fields: string[];
  updated: Date;
  serverDate: Date;
  synced: boolean;
}

//****NOTE TO FUTURE SELF****** */
//May just want to create a duplicate service for new guide years rather than try
//to generalize it for any year.  Since all the content will have to be created again
//anyway, and the form field pages won't match up, either.
//
//ADDITIONAL NOTE FROM A FEW DAYS LATER.
//It's gotten a bit more complex, so not sure if the above recommendation is a good one,
//at this point
@Injectable({
  providedIn: 'root'
})
export class LocalGuideService {

  syncConflict = new Subject<ConflictData>();
  conflictResolved = new Subject<UserContentPage>();
  isSyncing = new BehaviorSubject<boolean>(false);
  selectedTripChanged = new Subject<TripsActive>();

  lastGuidePageKey = "lastGuidePage";
  userContentKey = "Guide_";
  guideYear = 2022; //hard code for now
  currentTripKey = "currentTrip";
  tripsKey = "trips";
  shirtAdShownKey = "shirtAdShown";
  lastPage: number;


  constructor(
    private storage: StorageService,
    private guideService: GuideService,
    private authService: AuthService,
    private configService: ConfigService
  ) {

    this.storage.isInitialized.subscribe(initialized => {
      if (initialized) {
        this.authService.getUserProfile().subscribe((profile) => {
          if ((profile as any)?.newstaffId && this.configService.staffApiActive === false) {
            this.configService.toggleStaffApi();
          }
        });
      }
    });

  }


  saveLastPage(page: number) {
    return this.prependUserToKey(this.lastGuidePageKey).pipe(
      switchMap(key => {
        return from(this.storage?.set(key, page));
      })
    );

    // await this.storage?.set(this.lastGuidePageKey, page);

  }

  getLastPage() {
    return this.prependUserToKey(this.lastGuidePageKey).pipe(
      switchMap(key => {
        return from(this.storage?.get(key));
      })
    );
    // return await this.storage?.get(this.lastGuidePageKey);
  }

  saveCurrentTrip(trip: TripsActive) {

    return this.prependUserToKey(this.currentTripKey).pipe(
      switchMap(key => {
        return from(this.storage?.set(key, trip).then(() => { this.selectedTripChanged.next(trip); }));
      })
    );

    // await this.storage?.set(this.currentTripKey, trip);

  }

  getCurrentTrip(): Observable<TripsActive> {
    return this.prependUserToKey(this.currentTripKey).pipe(
      switchMap(key => {
        return from(this.storage?.get(key));
      })
    );
    // return from(this.storage?.get(this.currentTripKey));
  }

  getShirtAdShownForTrip(trip: TripsActive): Observable<boolean> {
    return this.prependUserToKey(this.shirtAdShownKey).pipe(
      switchMap(key => {
        return from(this.storage?.get(key + "_" + trip.id));
      })
    );
  }

  setShirtAdShownForTrip(trip: TripsActive) {
    return this.prependUserToKey(this.shirtAdShownKey).pipe(
      switchMap(key => {
        return from(this.storage?.set(key + "_" + trip.id, true));
      })
    );
  }

  async updateUserContent(content: GuideUserContent) {

    const currentContent = await firstValueFrom(this.getUserContent(content.page));

    if (this.haveFieldsChanged(currentContent, content)) {
      content.synced = false;
      const trip = await firstValueFrom(this.getCurrentTrip());
      await firstValueFrom(this.saveUserContent(content));
    }

  }

  loadContentForPage(page: UserContentPage, numFields: number) {

    return this.getUserContent(page).pipe(switchMap((content) => {

      if (!content) {
        content = this.getEmptyUserContent(page, numFields);
      }

      return of(content);

    }));

  }


  /**
   * Syncs guide form responses to the server.  If called with no parameters, it will sync all content.
   *
   * @param pageToSync If provided, will sync only the provided page
   */
  async syncContent(pageToSync?: UserContentPage) {

    if (this.configService.staffApiActive === false) {
      this.isSyncing.next(true);
      try {

        let pagesToSync: UserContentPage[];

        if (pageToSync) {
          pagesToSync = [pageToSync];
        }
        else {
          pagesToSync = [...userContentPages];
        }

        const currentTrip = await firstValueFrom(this.getCurrentTrip());

        if (currentTrip) {

          for (const page of pagesToSync) {

            const remoteContent = await firstValueFrom(this.guideService.getPageContent(this.guideYear, currentTrip.id, page));
            const content = await firstValueFrom(this.getUserContent(page));

            if (!content && remoteContent) {
              //take the remote content

              firstValueFrom(this.saveUserContent({
                fields: JSON.parse(remoteContent.responses),
                page: remoteContent.pageNumber,
                serverDate: remoteContent.lastUpdated,
                synced: true,
                updated: new Date()
              }));
            }

            if (content && !remoteContent) {
              //nothing on the server.  Send ours
              const updatedRecord = await firstValueFrom(
                this.guideService.setPageContent(this.guideYear, currentTrip.id, page, content.updated, content.fields));
              firstValueFrom(this.saveUserContent({ ...content, serverDate: updatedRecord.lastUpdated, synced: true }));
            }

            if (content && remoteContent) {

              if (content.synced) {
                if (content.serverDate < remoteContent.lastUpdated) {
                  //our content is older.  Take the server content.
                  firstValueFrom(this.saveUserContent({
                    ...content,
                    fields: JSON.parse(remoteContent.responses),
                    serverDate: remoteContent.lastUpdated
                  }));
                }
              }

              if (!content.synced) {
                if (content.serverDate >= remoteContent.lastUpdated) {
                  //We're either up to date with the server or the server rolled back somehow.  Send our content
                  const updatedRecord = await firstValueFrom(
                    this.guideService.setPageContent(this.guideYear, currentTrip.id, page, content.updated, content.fields));

                  firstValueFrom(this.saveUserContent({ ...content, serverDate: updatedRecord.lastUpdated, synced: true }));
                }

                if (content.serverDate < remoteContent.lastUpdated &&
                  content.updated === remoteContent.localLastUpdated) {
                  //the local updated timestamp on the server matches ours.
                  //assume we missed the response on our last sync.
                  firstValueFrom(this.saveUserContent({ ...content, serverDate: remoteContent.lastUpdated, synced: true }));
                }

                if (content.serverDate < remoteContent.lastUpdated &&
                  content.updated !== remoteContent.localLastUpdated) {
                  //someone else updated it.  Need to disambiguate.  This is the difficult one.
                  this.syncConflict.next({
                    page: page,
                    localContent: {
                      content: content.fields,
                      updatedDate: content.updated
                    },
                    remoteContent: {
                      content: JSON.parse(remoteContent.responses),
                      updatedDate: remoteContent.lastUpdated
                    }
                  });
                }
              }

            }
          }

        }
      }
      finally {
        this.isSyncing.next(false);
      }
    }
  }

  resolveConflictWithLocal(page: UserContentPage): Observable<boolean> {

    return this.getCurrentTrip().pipe(
      switchMap(trip => {
        return this.getUserContent(page).pipe(
          switchMap((content) => {
            return this.guideService.setPageContent(this.guideYear, trip.id, page, content.updated, content.fields).pipe(
              switchMap((updatedRecord) => {
                return this.saveUserContent({ ...content, serverDate: updatedRecord.lastUpdated, synced: true });
              }));
          })
        );
      })
    ).pipe(
      switchMap(() => {
        this.conflictResolved.next(page);
        return of(true);
      }),
      catchError((err) => {
        console.error(err); //need to figure out what to do here...
        return of(false);
      }));

  }

  resolveConflictWithRemote(page: UserContentPage): Observable<boolean> {
    return this.getCurrentTrip().pipe(
      switchMap(trip => {
        return this.guideService.getPageContent(this.guideYear, trip.id, page);
      })
    ).pipe(switchMap(remoteContent => {
      return this.saveUserContent({
        fields: JSON.parse(remoteContent.responses),
        page: remoteContent.pageNumber,
        serverDate: remoteContent.lastUpdated,
        synced: true,
        updated: new Date()
      });
    })).pipe(
      switchMap(() => {
        this.conflictResolved.next(page);
        return of(true);
      }),
      catchError((err) => {
        console.error(err); //need to figure out what to do here...
        return of(false);
      }));
  }


  private prependUserToKey(key: string): Observable<string> {
    return this.authService.getUserProfile().pipe(
      switchMap(profile => {
        if (this.configService.staffApiActive) {
          return of("su" + profile?.ID + "_" + key);
        }
        else {
          return of("u" + profile?.ID + "_" + key);
        }
      }));
  }

  private getStorageKey(tripId: number, page: UserContentPage): Observable<string> {
    return this.prependUserToKey(this.userContentKey + "_" + this.guideYear.toString() + "_" + tripId.toString() + "_" + page.toString());

  }

  private saveUserContent(content: GuideUserContent): Observable<void> {
    content.updated = new Date();

    return this.getCurrentTrip().pipe(
      switchMap(trip => {
        let tripId = 0;
        if (this.configService.staffApiActive === false) {
          tripId = trip.id;
        }
        return this.getStorageKey(tripId, content.page).pipe(
          switchMap(key => {
            return from(this.storage?.set(key, content));
          })
        );
      }
      ));

    // const trip = await firstValueFrom(this.getCurrentTrip());
    // await this.storage?.set(this.getStorageKey(trip.id, content.page), content);
  }

  private getUserContent(page: UserContentPage): Observable<GuideUserContent> {
    return this.getCurrentTrip().pipe(
      switchMap(trip => {
        let tripId = 0;
        if (this.configService.staffApiActive === false) {
          tripId = trip.id;
        }
        return this.getStorageKey(tripId, page).pipe(
          switchMap(key => {
            return from(this.storage?.get(key));
          })
        );
      })
    );

    // return this.getCurrentTrip().pipe(
    //   switchMap(trip => from(this.storage?.get(this.getStorageKey(trip.id, page))))
    // );
  }

  private getEmptyUserContent(page: UserContentPage, numFields: number): GuideUserContent {
    return {
      fields: Array<string>(numFields),
      page: page,
      serverDate: DateTime.fromISO("1000-01-01T00:00:00.000Z").toJSDate(),
      updated: new Date(),
      synced: false
    };
  }


  /**
   * Compares the fields of two GuideUserContent objects to see if there are any changes.
   *
   * @param originalContent The current content in the local DB
   * @param newContent New content update requested by UI
   * @returns
   */
  private haveFieldsChanged(originalContent: GuideUserContent, newContent: GuideUserContent) {

    let hasChanged = false;

    if (!originalContent && newContent
      || originalContent && !newContent) {
      hasChanged = true;
    }
    else {
      //we need to check each value
      for (let index = 0; index < originalContent.fields.length; index++) {
        const origField = originalContent.fields[index];

        if (newContent.fields.length > index && origField !== newContent.fields[index]) {
          hasChanged = true;
          break;
        }

      }
    }

    return hasChanged;
  }
}
