<ion-app>
  <ion-split-pane when="(min-width: 3000px)" contentId="main-content">
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>
        <ion-list id="inbox-list">
          <ion-list-header><img class="ion-margin-end" src="assets/icons/maskable_icon_x72.png" height="32" width="32" />Leadership Handbook
          </ion-list-header>
          <ion-note></ion-note>
         
          <!-- <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index"> -->
          <ion-menu-toggle auto-hide="false">

            <ion-item routerDirection="root" routerLink="/guide" lines="none" detail="false"
              routerLinkActive="selected">
              <ion-icon slot="start" ios="book-outline" md="book-sharp"></ion-icon>
              <ion-label>{{ "guideMenu" | translate }}</ion-label>
           
            </ion-item>
            <ion-item lines="none" size="small" class="ion-margin-start">
              <ion-icon size="small" slot="start" ios="document-text-outline" md="document-text-sharp"></ion-icon>
              <a href="assets/guide/LeadershipHandbook2024.pdf" target="_system"><ion-label>{{"pdfGuideMenu" | translate}}</ion-label></a></ion-item>
            <!-- <ion-item routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false"
              routerLinkActive="selected">
              <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item> -->
          </ion-menu-toggle>
        </ion-list>


        <!-- <ion-list id="labels-list">
          <ion-list-header>Labels</ion-list-header>

          <ion-item *ngFor="let label of labels" lines="none">
            <ion-icon slot="start" ios="bookmark-outline" md="bookmark-sharp"></ion-icon>
            <ion-label>{{ label }}</ion-label>
          </ion-item>
        </ion-list> -->
      </ion-content>
      <ion-footer>
        <ion-list>
          <ion-item *ngIf="pwaService.promptEvent" (click)="pwaService.promptForInstall()">
            <ion-button fill="clear">
              <ion-icon slot="start" ios="download-outline" md="download-sharp"></ion-icon>
              <ion-label>{{"install" | translate}}</ion-label>
            </ion-button>
          </ion-item>
          <ion-item *ngIf="pwaService.updateAvailable | async" (click)="pwaService.activateUpdate()">
            <ion-button fill="clear">
              <ion-icon slot="start" ios="arrow-up-outline" md="arrow-up-sharp"></ion-icon>
              <ion-label>{{"update" | translate}}</ion-label>
              <ion-icon color="primary" ios="ellipse" md="ellipse"
                style="position: relative; right: 2px; top: -5px;overflow: visible"></ion-icon>
            </ion-button>
          </ion-item>
          <ion-item>
            <ion-button fill="clear">
              <ion-icon slot="start" ios="log-out-outline" md="log-out-sharp"></ion-icon>
              <ion-label (click)="logout()">{{"logout" | translate}}</ion-label>
            </ion-button>
          </ion-item>
        </ion-list>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet [animated]="false" id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
