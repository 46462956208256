<ion-header>
  <ion-toolbar>
    <ion-title color="primary">Page {{conflictData?.page}} Responses</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-header></ion-header>
  <p>
    {{"conflictResolutionInstructions" | translate:conflictData}}

  </p>

  <ion-card class="ion-padding ion-no-margin ion-margin-vertical">
    <p><strong>{{"Your Device" | translate}}:</strong> {{conflictData?.localContent.updatedDate | date:'medium'}}</p>
    <div *ngFor="let field of conflictData?.localContent?.content; let i = index">
      <!-- The != is intentional here so we don't trigger merge conflicts on null vs undefined vs "" -->
      <ion-item-group *ngIf="field != conflictData?.remoteContent?.content[i]">
        <ion-label>{{"Answer" | translate}} {{i + 1}}</ion-label>
        <p>{{field}}</p>
      </ion-item-group>
    </div>
    <ion-button (click)="keepLocal()">{{"keepDevice" | translate}}</ion-button>
  </ion-card>
  <ion-card class="ion-padding ion-no-margin ion-margin-vertical">
    <p><strong>{{"Your Account" | translate}}:</strong> {{conflictData?.remoteContent.updatedDate | date:'medium'}}</p>
    <div *ngFor="let field of conflictData?.remoteContent?.content; let i = index">
      <ion-item-group *ngIf="field != conflictData?.localContent?.content[i]">
        <ion-label>{{"Answer" | translate}} {{i + 1}}</ion-label>
        <p>{{field}}</p>
      </ion-item-group>
    </div>
    <ion-button (click)="keepRemote()">{{"keepAccount" | translate}}</ion-button>
  </ion-card>
</ion-content>
