export const indexContents = [undefined,undefined,undefined,`<main appHighlight [highlight]="searchTerm" >

  <div >
    <h1 >Table Of Contents</h1>

    <div >

      <div >

        <table width="100%" >
          <tr>
            <td colspan="3"><a routerLink="/guide/5"><strong>Leadership Specifics</strong></a></td>
            <td width="10%" align="right" >5</td>
          </tr>
          <tr>
            <td width="10%">&nbsp;</td>
            <td colspan="2"><a routerLink="/guide/6">PPM Mission, Vision, Values & Statement of Faith</a></td>
            <td width="10%" align="right" >6</td>
          </tr>
          <tr>
            <td width="10%">&nbsp;</td>
            <td colspan="2"><a routerLink="/guide/7">Leadership Team Roles</a></td>
            <td width="10%" align="right" >7</td>
          </tr>
          <tr>
            <td width="10%">&nbsp;</td>
            <td colspan="2"><a routerLink="/guide/9">PPM Leadership Expectations</a></td>
            <td width="10%" align="right" >9</td>
          </tr>
          <tr>
            <td width="10%">&nbsp;</td>
            <td colspan="2"><a routerLink="/guide/10">Policy Agreements</a></td>
            <td width="10%" align="right" >10</td>
          </tr>
          <tr>
            <td width="10%">&nbsp;</td>
            <td colspan="2"><a routerLink="/guide/17">Trip Leader Checklist</a></td>
            <td width="10%" align="right" >17</td>
          </tr>
          <tr>
            <td width="10%">&nbsp;</td>
            <td colspan="2"><a routerLink="/guide/18">On-Trip Guide for In-Person<br/> Trip Leader Training</a></td>
            <td width="10%" align="right" >18</td>
          </tr>
          <tr>
            <td colspan="4">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="3"><a routerLink="/guide/19"><strong>Meetings</strong></a></td>
            <td width="10%" align="right" >19</td>
          </tr>
          <tr>
            <td width="10%">&nbsp;</td>
            <td colspan="2"><a routerLink="/guide/21">Orientation Meeting</a></td>
            <td width="10%" align="right" >21</td>
          </tr>
          <tr>
            <td width="10%">&nbsp;</td>
            <td colspan="2"><a routerLink="/guide/22">Evening Team Meeting</a></td>
            <td width="10%" align="right" >22</td>
          </tr>
          <tr>
            <td width="10%">&nbsp;</td>
            <td colspan="2"><a routerLink="/guide/23">Closing Meeting</a></td>
            <td width="10%" align="right" >23</td>
          </tr>
          <tr>
            <td width="10%">&nbsp;</td>
            <td colspan="2"><a routerLink="/guide/24">Daily Staff Meeting</a></td>
            <td width="10%" align="right" >24</td>
          </tr>
          <tr>
            <td colspan="4">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="3"><a routerLink="/guide/25"><strong>Delegations and Standard<br/> Operating Procedures</strong></a></td>
            <td width="10%" align="right" >25</td>
          </tr>
          <tr>
            <td width="10%">&nbsp;</td>
            <td colspan="2"><a routerLink="/guide/27">Delegation Chart</a></td>
            <td width="10%" align="right" >27</td>
          </tr>
          <tr>
            <td width="10%">&nbsp;</td>
            <td colspan="2">Delegations and Standard Operating Procedures by Role</td>
            <td width="10%" align="right" >&nbsp;</td>
          </tr>
          <tr>
            <td width="10%">&nbsp;</td>
            <td width="10%">&nbsp;</td>
            <td width="70%"><a routerLink="/guide/29">Trip Leaders</a></td>
            <td width="10%" align="right" >29</td>
          </tr>
          <tr>
            <td width="10%">&nbsp;</td>
            <td width="10%">&nbsp;</td>
            <td width="70%"><a routerLink="/guide/34">General Team</a></td>
            <td width="10%" align="right" >34</td>
          </tr>
          <tr>
            <td width="10%">&nbsp;</td>
            <td width="10%">&nbsp;</td>
            <td width="70%"><a routerLink="/guide/45">Ministry</a></td>
            <td width="10%" align="right" >45</td>
          </tr>
          <tr>
            <td colspan="4">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="3"><a routerLink="/guide/53"><strong>Incident Management Guidelines</strong></a></td>
            <td width="10%" align="right" >53</td>
          </tr>
          <tr>
            <td width="10%">&nbsp;</td>
            <td colspan="2"><a routerLink="/guide/54">Incident Management Team<br>&amp; Responsibilities</a></td>
            <td width="10%" align="right" >54</td>
          </tr>
          <tr>
            <td width="10%">&nbsp;</td>
            <td colspan="2"><a routerLink="/guide/56">Work-related Injury or<br>Serious Illness of Paid Staff</a></td>
            <td width="10%" align="right" >56</td>
          </tr>
          <tr>
            <td width="10%">&nbsp;</td>
            <td colspan="2"><a routerLink="/guide/57">Support Services for<br>International Locations Only</a></td>
            <td width="10%" align="right" >57</td>
          </tr>
          <tr>
            <td colspan="3"><strong>Ministry Skills Training</strong></td>
            <td width="10%" align="right" ></td>
          </tr>
          <tr>
            <td width="10%">&nbsp;</td>
            <td colspan="2"><a routerLink="/guide/59">Life Story, Relational, Icebreakers, Prayer Walking, The Gospel in One Verse</a></td>
            <td width="10%" align="right" >59</td>
          </tr>
          <tr>
            <td width="10%">&nbsp;</td>
            <td colspan="2"><a routerLink="/guide/66">More Ministry Skills Training</a></td>
            <td width="10%" align="right" >66</td>
          </tr>
          <tr>
            <td colspan="4">&nbsp;</td>
          </tr>
          
        </table>


      


    </div>
  </div>

</main>
`,`<main appHighlight [highlight]="searchTerm" >

</main>`,`  <main appHighlight [highlight]="searchTerm" >

    <h1>Leadership Specifics</h1>
    <p><strong>Welcome to the PPM family.</strong></p>
    <p>If you have been around before, welcome back! Thank you for the care you have shown our partners and our teams.</p>
    <p>If you are new, we are so happy and grateful you are here. </p>
    <p>We hope this guide serves you well on the practical front! The pages ahead are filled with the details that have helped us serve partners and teams well over the last 20 years of leading mission trips. </p>
    <p>On a heart level, the trips in front of you will hold the gamut of emotions that our God has designed us to feel. I love how being out in the field leading trips still stretches, surprises, challenges, and absolutely fills me up. It was true on trip number one. It’s still true today.</p>
    <p>So, how can you prepare best? I’m sure it’s no surprise that I’m going to point you directly to God. As you start each trip, I urge you to take a moment to remember your why. </p>
    <p>Are you here out of complete obedience? You felt the Holy Spirit nudging you and you said “yes!”? </p>
    <p>Was your life changed on the mission field or through a mission team and you are called to serve others in a way that brought you such a tangible connection with God? </p>
    <p>Is your heart overwhelmed with love for the Church and how God delights when we serve and worship Him together? </p>
    <p>Let that reason be the North Star that points back to God, time and time again. And then link arms with others who have a slightly different story of <strong><em>why</em></strong> they are here, but the exact same story of <strong><em>Who</em></strong> called them. </p>
    <p>Can’t wait to serve alongside you.</p>
    <p>Laura Yoch </p>
    <p>Vice President of Operations</p>
  </main>
`,`<main appHighlight [highlight]="searchTerm" >

  <h1>PPM Mission, Vision, Values &amp; Statement of Faith</h1>
<p><strong>MISSION:</strong></p>
<p>To build up, encourage, and assist the local Church in serving and reaching their communities for Christ.</p>
<p><strong>VISION:</strong></p>
<p>To see a united global Church reaching its fullest potential for the glory of God.</p>
<p><strong>VALUES:</strong></p>
<p>We operate internally on the guiding principles of: </p>
<p><strong>Humility</strong></p>
<p>We are unifiers, building bridges through our commonalities as Jesus followers.</p>
<p>We cultivate a culture of honor and dignity, putting our partners first.</p>
<p>We hire locally, investing in trained, year-round leaders who know their communities best.</p>
<p><strong>Integrity</strong></p>
<p>We strive to do what's right, guided by biblical principles.</p>
<p>We work diligently to be lifelong learners and good stewards of the calling before us.</p>
<p>We lead with professionalism and experience in our field to do what we say we're going to do.</p>
<p><strong>Prayer</strong></p>
<p>We are passionately rooted in Christ.</p>
<p>We submit first in prayer, ensuring that our bold "yes" aligns with the Holy Spirit's leading.</p>
<p>We move forward with confidence, trusting God as we make ourselves available to His calling.</p>
<p>We carry out our mission through the lens of:</p>
<p><strong>Genuine Partnerships</strong></p>
<p>Our heart is to be wholly invested in the communities in which we serve, providing a connecting point for the global Church.</p>
<p><strong>Long-term Relationships</strong></p>
<p>We build lasting relationships with local churches and ministries, partnering alongside them 365 days a year.</p>
<p><strong>Sustainable Ministry</strong></p>
<p>We operate under the authority and direction of the local Church, ensuring that the ministry we do leads to a healthy and lasting impact for the Kingdom.</p>
<p><strong>Statement of Faith</strong></p>
<p>At the core of who we are and what we stand for is our statement of faith. The following statements outline what we at Praying Pelican Missions believe.</p>
<ol>
  The Bible is God’s complete and perfect Word and our ultimate authority as followers of Christ. (2 Timothy 3)
  <li>We are all lost, fallen short of the glory of God, and in need of a Savior. (Romans 3)</li>
  <li>Jesus came to earth for the purpose of seeking and saving the lost. (Luke 19:10)</li>
  <li>Jesus died on the cross. Three days later, He rose from the grave, paying the ultimate sacrifice and conquering death for all who believe in Him. (1 Peter 3:18, 1 John 2:2, Romans 5:8, Romans 10:9)</li>
  <li>As a result of God’s grace alone, through faith in Christ alone, we are saved. (Ephesians 2, John 14)</li>
  <li>Jesus, God, and the Holy Spirit are One. (John 1)</li>
  <li>Jesus Himself commissioned His followers (the Church) to go into the world and make disciples. (Matthew 28)</li>
</ol>

  </main>`,`<main appHighlight [highlight]="searchTerm" >

    <h1>Leadership Team Roles</h1>
<p><em>What part do you play in all of this?</em></p>
<p><strong>You Are A Bridge Builder</strong></p>
    <p>Your goal is to create as many pathways as possible for the members of the team to connect with and serve the local church. This may include creating opportunities for the team to connect with members of the community, listening, sharing and learning. Your job is to help the team appreciate the heart of the ministry and the richness of the culture. We need you to be an expert “bridge builder” who overcomes the obstacles that may separate the team and the local church during the week.</p>
<p><strong>Servant Leadership</strong></p>
    <p>We coach every team that comes on a mission trip to submit to the authority of the local church leadership. It helps us to take on the posture of a servant and stands as a safeguard against causing unintentional harm to the ministry. Our hope is for these teams to come alongside the ministry that is already happening to provide needed help and encouragement. You will set the tone for each week of ministry by how you lead with a servant's heart and demonstrate a genuine respect for the leadership of the local church. If you serve with humility and grace, in most cases, the team will follow suit.</p>
<p><strong>Care For The Partners</strong></p>
    <p>There will be times when you are not with the team, but rather you are working alongside a local pastor, community member, or staff member. A life of ministry can be very challenging and exhausting. As a staff member with PPM, we are asking you to give attention and care to those with whom we are partnering. This often begins by building a personal friendship and listening to them. It goes even further when you are able to pass along their vision and suggest ways for the team to build those relationships and minister to the heart of those leaders.</p>
<p><strong>Leadership Positions</strong></p>
<p><strong>Ministry Leader/Trip Leader (TL)</strong></p>
<p>This role is filled by full-time, part-time, or seasonal staff members. The TL is ultimately responsible for the big-picture view of the trip. This includes communication between host pastor and group leader, handling trip-setup, overseeing completion of ministries, and taking care of logistics pertaining to trip finances, lodging, meals, transportation, and nightly meetings.</p>
<p><strong>Assistant Trip Leader (ATL)</strong></p>
<p>This role is filled by local or non-local seasonal staff members. An ATL is responsible for relational ministry with the team, local staff, and host partner. The ATL spends the majority of their time getting to know the team, serving alongside them, and reporting back any issues to the TL. ATLs are also responsible for their given delegations (which change week to week) throughout the trip, such as meals, trip journals, water, leading devotions, etc. </p>
<p><strong>Location Leader (Operations Manager, Operations Coordinator, Ministry Coordinator)</strong></p>
<p>This is the PPM staff person who oversees the specific location. Roles, responsibilities, and titles vary based on several factors. They communicate location-specific information and nurture partnerships in their area year round along with the Partner Advocate. Operations Managers report to Regional Operations Managers. </p>
<p><strong>Group Leader (GL)</strong></p>
<p>This person is the leader of the group who is traveling to serve. They are consulted in the plans that connect to their group members and are responsible for their group. </p>
<p><strong>Host Pastor/Host Partner (HP)</strong></p>
<p>This is the local ministry pastor or leader who is responsible for the local ministry that we are coming to encourage with our partnership. They are consulted in the plans that connect to their ministry and community.</p>

</main>`,`	<main appHighlight [highlight]="searchTerm" >

	 

	</main>
`,`<main appHighlight [highlight]="searchTerm" >
  
    <h1>PPM Leadership Expectations</h1>
    <p>Staff members read through these at the beginning of each week on the mission field. </p>
    <p>Servant Leadership</p>
    
      Represent Jesus and PPM positively in your attitude, effort, and speech.
      <li>Follow the Policy Agreements.</li>
      <li>Dress appropriately and professionally by adhering to the clothing guidelines given to our teams, and with
        location-specific guidance.</li>
      <li>Aim to have the group and the host ministry desire to continue with PPM. Work alongside them for a healthy,
        genuine partnership.</li>
      <li>Set yourself aside for the betterment of others. Go above and beyond to help others feel comfortable and to
        give them the best mission partnership possible.</li>
      <li>Be sensitive to the local people and the culture. Guide the teams in cultural areas as you see needs arise.
      </li>
      <li>Be on time for all ministry events and meetings. Attend nightly meetings with the group and leadership team.
        Prepare your areas ahead of time.</li>
      <li>Read through the participant forms prior to the trip to get a feel for where each team member is coming from
        physically and spiritually. Use these forms to understand the goals for the trip so we can have successful
        ministry.</li>
      <li>Fulfill your delegations to the best of your ability. You are not required to do everything yourself in your
        assigned areas, but to see that everything gets accomplished by someone. </li>
      <li>Follow the trip budget. Record the details of your expenses daily. Take photos of all your receipts from all
        of your transactions and submit those promptly at the end of the week.</li>
      <li>Get to know the locals in your delegation areas and thank them. We want them to know that they are
        appreciated.</li>
    
    <p>Health &amp; Spiritual</p>
    <ul>
      <li>Watch for health concerns continually. Encourage the group to drink water, eat the right foods, stay with the
        group, and participate in all ministries.</li>
      <li>Take care of yourself and stay healthy by eating appropriate foods, getting enough sleep, using time off as
        time off, drinking lots of water, using sunblock, etc.</li>
      <li>Obey the law and be cautious. Always bring your driver’s license with you when you are driving.</li>
      <li>Have your own personal daily devotions and quiet time to renew yourself physically, spiritually, and
        emotionally.</li>
      <li>Spend time in prayer every day for the ministry partners, Leadership Team, and for the ministry. </li>
    </ul>
    <p>Communication</p>
    <ul>
      <li>Keep your cell phone on you where you can hear it at all times.</li>
      <li>Set up the group and community members to succeed in their ministry roles.</li>
      <li>Be an expert by continuing to learn and share about the location’s culture, history, economy, spiritual
        condition, and geography.</li>
      <li>Talk to all of the participants every day. Make it a goal to have at least one 8-10 minute conversation with
        each group member over the course of the week.</li>
      <li>Use positive and encouraging words. There should be no put-downs or jokes at someone’s expense.</li>
      <li>Be aware of team members who are not fitting in or seem to be loners; encourage them and help them connect.
      </li>
    </ul>
  
</main>`,`<main appHighlight [highlight]="searchTerm" >
<h1>Policy Agreements</h1>
<p><strong>Leadership Team Member Policy Agreement</strong></p>
<p>Revision Date November 2022 </p>
<p><em>This Policy Agreement details commitments that you are making to your co-leaders, trip participants, PPM partners, host pastors and PPM as a whole while you serve as a team leader:</em></p>
<ol>
   As a representative of my own community, PPM, and above all else, Jesus Christ, I will behave at all times in a manner that brings honor to these institutions. 
  <li> I am aware that my words and actions are noticed as a PPM team member in the partnership community. Therefore, I agree to act and speak in a manner that glorifies the name of Jesus Christ. I want everything I do or say to draw people to Jesus. </li>
  <li> I will be spiritually, physically, and mentally prepared for mission trips that will stretch me and mold me. I will do everything I can ahead of time to prepare myself for a "trip of a lifetime." </li>
  <li> I understand I am a guest in the community I am visiting. I will respect and follow the traditions and customs of the local people and leaders. For example: if the way I dress offends them, I will change. If I am offered food, I will graciously accept it with a "thank you" and a smile. </li>
  <li> I will not criticize the culture, efficiency, work habits, food, traditions, etc. that I am exposed to during my time on the trip. I have come not to judge, but to learn and to share the person of Jesus through my words, actions, and deeds. </li>
  <li> I will respect my fellow PPM Trip Leaders, Host Pastors, and Group Leaders, and their decisions. </li>
  <li> I agree to have a servant attitude at all times. In all tasks and ministries, I will focus on finding where I can use my gifts and talents to encourage and assist others. </li>
  <li> I will use words that encourage and build up my team members and those in our host community at all times. This means I will refrain from gossip or words that bring dissension and take focus away from the goals of the trip. </li>
  <li> I will have a positive attitude at all times. Every trip has its challenges and unforeseen circumstances. People could get sick, items lost, and plans changed. I realize that there are times I will need to be flexible and look at challenges as opportunities to be a witness for Jesus Christ. </li>
  <li> I will refrain from pursuing romantic relationships during the trip. Even if my spouse or significant other is on the trip with me, I will make every effort to spend time with each person in the group and interact with all team members. </li>
  <li> I will attend all team meetings and fulfill the responsibilities I have agreed to before, during, and after the trip. If I am not able to attend a meeting, I will proactively be in contact with another co-leader as to what I missed. </li>
  <li> I will refrain from initiating or engaging in divisive conversation regarding politics and other sensitive subjects during the trip. I will put aside my opinions in these types of sensitive matters to protect the ministry and focus on our mission. </li>
  <li> I will abstain from using, consuming, purchasing, or possessing alcohol, cannabis, tobacco, other smoking-related products, and illegal drugs while on the trip. </li>
  <li> I will not steal, commit any crime, or do anything illegal while on the trip. </li>
  <li> I will meet all medical, logistical, and legal travel requirements. I will follow all import and export laws. I will not attempt to bring any illegal or banned item/good into or back from the location in which I will be serving. </li>
  <li> I acknowledge that I will not bring a weapon, including firearms, on my PPM trip. </li>
  <li> I will follow the travel, supply, clothing, financial, and medical guidelines, etc. set forth and explained to me prior to the trip. </li>
  <li> If, in the future, I desire to return to the community and location on a mission trip to visit any of the connections or ministry partnerships, I must do so through PPM or receive PPM’s written permission to do so. This will enable the overall vision for the ministry as agreed upon through PPM connections in the host community to continue. </li>
  <li> I realize that to attend this trip and represent Jesus Christ on the mission field is a privilege, and if I abuse that privilege, that abuse constitutes the right for my trip to end prematurely. </li>
</ol>
<p><em>I agree and acknowledge that if I violate this Policy Agreement in any way, PPM or a PPM representative, at their sole discretion, may remove me from the mission trip and send me home at my own expense.</em></p>
<p><strong>Personal Use of Social Media Policy</strong></p>
<p>Praying Pelican Missions recognizes the right of employees to engage in the use of social media in their personal lives. However, employees often fail to understand the impact that such use can have upon their employer. We, therefore, have established the following rules for the use of social media during personal time to ensure that such activity does not adversely affect Praying Pelican Missions.</p>

  <li>Personal use of social media should not interfere with employees’ work duties and should be limited to non-work time.</li>
  <li>Posting or “Liking” comments, photos, or links with content that violates PPM policy is unacceptable. If you question the appropriateness of the content, speak with your supervisor prior to posting.</li>
  <li>Posting or “Liking” political views may be construed as a direct reflection of the views and ethical principles of Praying Pelican Missions and, therefore, should be carefully considered and is strongly discouraged. </li>
  <li>You must inform others that any opinions expressed in regard to Praying Pelican Missions, anyone affiliated with Praying Pelican Missions, the organization’s business or the general topic of short term missions are personal and do not represent the views of Praying Pelican Missions.</li>
  <li>You may not share information that is confidential and proprietary about Praying Pelican Missions or confidential information about a trip participant, church or community partner or staff member. If you have questions about whether a particular subject or issue is confidential or proprietary, speak with your supervisor. </li>
  <li>You may not use the logo, trademark or any other symbol associated with Praying Pelican Missions without explicit written permission from your supervisor. </li>
  <li>You may not comment or address matters relating to competitors and/or their capabilities.</li>
  <li>You may not sell any product or service that would compete with any of Praying Pelican Missions’ products or services without permission in writing from the organization. This includes, but is not limited to training, books, products and freelance writing. If in doubt, talk with your supervisor.</li>

<p>Praying Pelican Missions asks employees to remember that personal accounts also reflect on the organization, just as a face-to-face conversation with a peer would leave an impression about an employee and the organization. Failure to comply with any or all of the Personal Use of Social Media policy may result in disciplinary action, up to and including termination of employment. </p>
<p><strong>Clothing Guidelines for Staff and Participants</strong></p>
<p>What is considered appropriate dress varies widely across our ministry locations, and some clothing can be considered inappropriate or offensive to the people you’ll be serving. Don’t allow your clothing to be a barrier between you and the community you’re serving. With that in mind, we opt to err on the side of respect and humility when it comes to how we dress. Please read through these clothing and packing  guidelines carefully.</p>
<p>Check with your Location Leader to find out if there’s anything special you need to know about what to wear in your ministry location. Keep in mind that laundry facilities will not be available on your trip. </p>
<p><strong>What to Wear:</strong> </p>
<p>Ministry clothes and shoes: You will likely want to pack 3-4 outfits for ministry. Pack clothes that can get dirty and sweaty, shirts that cover your shoulders and torso, and shorts that are fingertip length or longer. Pack athletic shoes or waterproof sandals. If service projects are in your ministry plans, bring a pair of long pants and closed-toed shoes.</p>
<p>Church clothes and shoes: You will likely want to pack 1-2 outfits for church. For women, pack slacks, skirts, or dresses that are at least knee-length, a nice shirt (no sleeveless), and nice sandals or shoes. For men, bring long dress pants, a nice shirt, and closed-toed shoes. For some USA church locations, church dress may be more casual. </p>
<p>Casual clothes and shoes: You will likely want to pack casual clothes to change into after ministry. Pack shirts that cover your shoulders and torso, and bottoms of appropriate length. Pack athletic shoes or waterproof sandals/flip flops. </p>
<p>Pajamas: Pack bottoms of appropriate length and an appropriate top to sleep in. Pack a pair of waterproof flip-flops for showering. </p>
<p>Swimwear: If your trip has opportunity for recreation in the water, you will want to be prepared. For women, please pack a full coverage swimsuit not showing any midriff. For men, please pack appropriate-length swimming trunks.</p>
<p>Packing according to PPM’s clothing guidelines is one way we rely on staff and participants to be considerate of our ministry partners as we represent them in the community where we will serve together. Keeping these guidelines is one way we show humility as we work together in diverse communities around the world and in our backyard. </p>
<p><strong>Here is what NOT to bring or wear on the field:</strong></p>
<ul>
  <li>Items or attire that could be construed as taking a political or divisive stance</li>
  <li>Jewelry or clothing that are valuable and at risk of being lost or damaged</li>
  <li>Tank tops, muscle shirts, or sleeveless tops</li>
  <li>Shorts, dresses, or skirts that are shorter than fingertip length</li>
  <li>Two-piece bathing suit showing midriff</li>
</ul>
<p>Thank you for your intentionality and care! Please confirm if there are additional expectations in your Location Packet information, provided by your location leader. </p>
<h2>Non-Harassment Policy</h2>
<p><strong>Policy:</strong></p>
<p>Praying Pelican Missions (PPM) is committed to providing a work environment that is free from all forms of discrimination and conduct that can be considered harassing, coercive, or disruptive, including sexual harassment. </p>
<p>Actions, words, jokes, or comments based on an individual's sex, race, color, national origin, age, religion, disability, veteran status, genetic information or any other legally protected characteristic will not be tolerated.</p>
<p> </p>
<p><strong>What is considered harassment?</strong></p>
<p>Sexual harassment is defined as unwanted sexual advances, or visual, verbal, or physical conduct of a sexual nature. This definition includes many forms of offensive behavior and includes gender-based harassment of a person of the same sex as the harasser.</p>
<p> </p>
<p>Unwelcome sexual advances (either verbal or physical), requests for sexual favors, and other verbal or physical conduct of a sexual nature constitute sexual harassment when: </p>
<ul>
  <li>(1) submission to such conduct is made either explicitly or implicitly a term or condition of employment; </li>
  <li>(2) submission or rejection of the conduct is used as a basis for making employment decisions; or, </li>
  <li>(3) the conduct has the purpose or effect of interfering with work performance or creating an intimidating, hostile, or offensive work environment.</li>
</ul>
<p><strong>Procedure:</strong></p>
<p>If you experience or witness sexual or other unlawful harassment in the workplace, <strong>report it </strong> <strong><em>immediately</em></strong> to the Director of Human Resources, Therese Kaletka, at <a href="tel:6129655471">612-965-5471</a>. </p>
<p>If Human Resources is unavailable, report it to a senior member of our leadership team. Those leaders are:</p>
<ul>
  <li>Jim Noreen, President <a href="tel:6512168009">(651) 216-8009</a></li>
  <li>Laura Yoch, VP of Operations  <a href="tel:6513479336">(651) 347-9336</a></li>
</ul>
<p>You can raise concerns and make “good faith” reports without fear of reprisal or retaliation. PPM will not retaliate against any staff member who makes a claim of harassment. </p>
<p>All allegations of harassment will be quickly and discreetly investigated. <em>To the extent possible</em>, your identity and that of any witnesses and the alleged harasser will be protected against unnecessary disclosure. You will be informed when the investigation is completed.</p>
<p> </p>
<p>Any supervisor or manager who becomes aware of possible sexual or other unlawful harassment must immediately advise Human Resources or a member of PPM’s Executive Leadership Team, so it can be investigated in a timely and confidential manner.</p>
<p> </p>
<p>Anyone engaging in sexual or other unlawful harassment will be subject to disciplinary action, up to and including termination of employment.</p>
<p><strong>Summary:</strong></p>
<ul>
  <li>Harassment in the workplace is to be taken seriously and will not be tolerated.</li>
  <li>Through PPM leadership and all team members’ respectful actions and diligent observation, risk of harassment can be minimized. </li>
  <li>Immediate reporting of any suspected harassment of any form is expected. </li>
  <li>Confidentiality will be maintained to the extent possible in the investigation of the claim. </li>
</ul>
<p><strong>Abuse Prevention</strong></p>
<p><strong>Introduction:</strong></p>
<p>Praying Pelican Missions (PPM) has adopted an Abuse Prevention Policy and Reporting Guidelines to help prevent abuse against individuals.  This policy is designed to reduce the risk of abuse in order to:</p>
<ol>
  <li>Provide a safe and secure environment for children, youth, adults, volunteers, participants, visitors and paid staff.</li>
  <li>Assist PPM in evaluating a person’s suitability to supervise, oversee and/or exert control over the activities of children, youth and vulnerable adults.</li>
  <li>Satisfy the concerns of parents, family members and staff members by having a screening process for paid staff and volunteers.</li>
  <li>Provide a system to respond to alleged victims of abuse and their families, as well as the alleged perpetrator.</li>
  <li>Reduce the possibility of false accusations of abuse made against volunteers and paid staff.</li>
</ol>
<p><strong>Policy Definitions:</strong></p>
<p>The following terms are used herein and defined as follows:</p>
<ol>
  <li> Paid Staff: Any employee or contracted staff who is paid by PPM.</li>
  <li>Child/Youth/Minor: Any person who has not reached his/her 18<sup>th</sup> birthday or the age of majority as defined by state law.</li>
  <li>Adult: Any person who has reached his/her 18<sup>th</sup> birthday or as defined by state law.</li>
  <li>Volunteer: Any unpaid person engaged in or involved in activities and who is entrusted with the care and supervision of minors or adults, or a person who directly oversees and/or exerts control or oversight over minors or adults.</li>
  <li>Abuse:
    <ol type="a">
      <li><u>Sexual Abuse:</u> The employment, use, persuasion, inducement, enticement or coercion of any minor or adult to engage in, or assist any other person to engage in, any sexually explicit conduct or any simulation of such conduct for the purpose of producing any visual depiction of such conduct or as defined by federal and state law. This includes and is not limited to unwelcome sexual remarks, jokes, advances, leering, whistling or sexual gestures; sexual touching, fondling, molestation, assault or other intimate physical contact; compelling another person to engage in a sexual act by threats or fear or undue influence; and providing or displaying pornographic materials to another person.</li>
      <li><u>Emotional/Mental/Verbal Abuse:</u> Verbal or nonverbal conduct including mental exploitation, degrading communication, persistent behavior, or humiliating or threatening conduct that causes an individual to perceive himself or herself as inept, not cared for, and worthless, or to build the abuser’s sense of dominance and control, any of which may or may not include bullying, or as defined by state law.</li>
      <li><u>Physical Abuse:</u> A physical act of punching, beating, shaking, throwing, kicking, biting, burning or neglect which causes serious physical injury to a person.  Although not considered accidental, it may not have occurred with the intent to hurt the person. </li>
    </ol>
  </li>
</ol>
<p> </p>
<p><strong>Reporting and Response Procedures:</strong></p>
<p>It is the responsibility of each staff member or volunteer to <strong><em>immediately</em> report all potential abuse allegations</strong> directly to the Director of HR, Therese Kaletka, at <a href="tel:6129655471">612.965.5471</a>.  DO NOT use our incident reporting system for allegations of abuse.</p>
<p>If Human Resources is unavailable, report it to a senior member of our leadership team.  Those leaders are:</p>
<ul>
  <li>Jim Noreen, President <a href="tel:6512168009">(651) 216-8009</a></li>
  <li>Laura Yoch, VP of Operations  <a href="tel:6513479336">(651) 347-9336</a></li>
</ul>
<p>It is important to be appropriately respectful to the needs and feelings of those who allege abuse and those who have been accused of abuse. Consult with the Director of HR for guidance on how to best address individuals involved in alleged cases of abuse.</p>
<p>Staff members and volunteers are also required to notify the Human Resources Manager of any allegations reported to authorities, or any legal investigations against the team member, and the reason for the allegation or investigation. Failure to do so may result in disciplinary action, up to and including termination of employment/service. </p>
<p>PPM will take all allegations of abuse seriously and will investigate each allegation promptly..</p>
<p>Upon informing individuals involved, the PPM Human Resources Manager, President or an appointed person will begin investigating the allegations and may use the assistance of legal counsel or other consultants. The investigation will be conducted by appropriate PPM personnel including the following steps as appropriate:</p>
<ol>
  <li>Protect the confidentiality of the situation to the extent possible. </li>
  <li>Report to, cooperate with, and follow the directives of appropriate authorities in accordance with the state mandatory reporting laws, PPM’s insurance carrier, and legal counsel as necessary in regards to the incident including conversations with the alleged perpetrator, alleged victim and related parties and ministries.</li>
  <li>PPM may suspend the alleged offender while a confidential investigation is being conducted.</li>
  <li>Designate a spokesperson for media response or statements, per advice of legal counsel. </li>
</ol>
<p><strong>Preventative Supervisory Procedures:</strong></p>
<p>Unless an extenuating situation exists, PPM will:</p>
<ol>
  <li>Have a minimum ratio of 20 minors or vulnerable adults to one paid staff or volunteer. Supervision will increase in proportion to the risk of the activity. When transporting minors or vulnerable adults in vehicles, two paid staff members or volunteers will be present whenever possible. Minors or vulnerable adults will not be left in a one-on-one supervision situation whenever possible. </li>
  <li>Monitor facilities during activities involving children and vulnerable adults for safety risks and potential perpetrators as well as require that young children or vulnerable adults be accompanied to the restroom and the paid staff or volunteer wait outside the facility to escort the child or vulnerable adult back to the activity. Whenever possible, the escort will be the same sex as the minor.</li>
  <li>Require all PPM teams and host partners to obtain written parental or guardian permission, including a signed medical treatment form and emergency contacts, before taking minors or vulnerable adults on trips. Information regarding the trip will be provided to the adult authorizing permission.</li>
</ol>
<p><strong>Summary:</strong></p>
<ul>
  <li>Abuse is to be taken seriously and will not be tolerated.</li>
  <li>Through PPM leadership and all team members’ respectful actions and diligent observation, risk of abuse can be minimized. </li>
  <li>Immediate reporting of any abuse allegations is expected. </li>
  <li>Confidentiality will be maintained to the extent possible in the investigation of the claim. </li>
</ul>
</main>`,undefined,`<main appHighlight [highlight]="searchTerm" >

  
</main>`,`	<main appHighlight [highlight]="searchTerm" >

    
  </main>`,`<main appHighlight [highlight]="searchTerm" >


    </main>
`,`<main appHighlight [highlight]="searchTerm" >

</main>
`,`  <main appHighlight [highlight]="searchTerm" >

      </main>
`,`<main appHighlight [highlight]="searchTerm" >




    <h1>Trip Leader Checklist</h1>
    <p>This checklist is a guide to help ensure that Trip Leaders know how to be successful. </p>

    <div >
        <ion-button color="danger"  (click)="resetToDefault()">Clear</ion-button>
    

    <ng-container *ngFor="let checklist of checklists;index as checklistIndex">
        <div >
            <p><strong><u>{{checklist.title}}</u></strong></p>
            <ng-container *ngFor="let item of checklist.items;index as itemIndex">
            <div >
                <div >
                    <input  />
                    <span [innerHTML]="item.text"></span>
                </div>
            
            </div>
            </ng-container>
        </div>
    </ng-container>

</main>`,`<main appHighlight [highlight]="searchTerm" >

  <h1>On-Trip Guide for In-Person Trip Leader Training</h1>
<p>The trainer and trainee will need to access to:</p>

  <strong>Mission Control</strong> (log-in, finding trips, itinerary work, roster, forms)
  <li><strong>Leadership Handbook</strong> (application or print version. Pre-reading is highly recommended before the on-trip training begins.)</li>

<p>Connect with each other about the level of experience the trainee has prior to this trip. As appropriate, add more of the Trip Leading responsibilities to the trainee so that they get experience with each of the components needed to lead. Ideally, TLs should have two or more trips ATLing before leading as a TL. If it is helpful during this week of training, let the Group Leader know that on-the-job training is happening so they can be supportive and understanding.</p>
<p>Give an overview of the Leadership Specifics section of the Leadership Handbook. Prepare to complete the <strong>Trip Leader Checklist</strong> for that trip, talking through each of the items with the trainee. </p>
<p>Set aside and allow for connection with <strong>question and answer times daily</strong> with the trainee. Explain any hurdles and strategies for success. Talk through common issues on trips and solutions. Give timely input to allow for transparency about performance and enhanced growth in learning.</p>
<p>Model for the trainee good, strong nightly Group <strong>Meetings</strong> and components, using the Meetings section of the Leadership Handbook. After they have seen some good examples, assign the trainee some of the meetings components as appropriate so they can grow and get experience leading them while the trainer is there to observe. Share with the trainee their strengths and growth opportunities clearly and in a positive way. </p>
<p>Use the <strong>Delegations</strong> section of the Leadership Handbook to assign Trip Leader and Assistant Trip Leader tasks. Allow time for the trainee to read the Standard Operating Procedures in each assigned delegation area before the team arrives. </p>
<ul>
  <li>Talk through the TL delegations and show/demonstrate how those are being completed, allowing the trainee to reference the SOPs. Help them to see the TL model completing those well. With supervision, allow for the trainee to experience some hands-on learning of these TL delegations as well. </li>
  <li>Assign the General Team delegations and allow time to read and complete the SOPs in their areas. </li>
  <li>Ministry delegations vary with each trip. Allow for training on these delegations and SOPs so that the trainee can ask questions, anticipate future trip leading needs, and prepare to lead well. </li>
</ul>
<p><strong>Pray together and encourage</strong> each other with affirmations at the end of the trip. Take time to identify their strengths and growth areas for Trip Leading on future trips.  We are always improving and we trust God to help us as we serve and lead.</p>

</main>
`,`<main appHighlight [highlight]="searchTerm" >
	<h1>Meetings</h1>
<p>Meetings are an essential part of each Praying Pelican Missions trip. They help to establish consistent communication and help to guide our teams and staff through an experience of a lifetime. This “Meetings” section gives you the resources you need to lead successful meetings throughout the week, establish clear guidelines, and empower staff and team participants. </p>
<p><strong>Best Practices:</strong></p>
<p><strong>Bring the Pelican Guide to all meetings.</strong> The Pelican Guide is a required resource that each trip participant receives before their trip. It has many helpful resources including the daily devotions, worship song lyrics, language help, the participant policy agreement, and further resources both in print and available on the accompanying app. Take some time before the trip to familiarize yourself with the guide so you can point team members in the right direction should they need something that can be found within it. (abbreviated as PG below)</p>
<p><strong>Assign meeting responsibilities to different staff members.</strong> From a nightly devotional to going through the Policy Agreement during the orientation meeting, many of these portions of the meetings can be led by ATLs. Make sure each person knows in advance what their responsibilities are for each meeting.</p>
<p><strong>Prepare the space.</strong> Some meetings are best held in a circle. Sometimes in rows. Know your space, talk with your GL, and determine how best to set up your meeting space.</p>
<p><strong>Watch the clock and be aware of the team.</strong> Be aware of the team and in communication with the GL when it comes to the length of meetings.The orientation meeting and closing meeting will likely be about 2 hours, but the evening meetings will be shorter. If it’s getting long and the team is tired, make modifications.</p>
<p><strong>Communicate with the GL(s) in advance.</strong> Know who will be leading worship, doing the devotional, sharing the schedule, or leading the debriefing sections. The GL can choose if they want to lead their own devotions at meetings or if they want PPM staff to lead them. Communication is key.</p>
<p><strong>Prepare announcements to be meaningful and helpful to the group.</strong> “Great job at..., We need your help with...Something to be mindful of is…” demonstrating servant leadership to the team.</p>
<p><strong>Devotional Themes:</strong></p>
<p>In addition to the Pelican Guide devotional materials and your own personal devotions, here are some themes for enhancing your group meetings. </p>

  God knows our name and comes to us. Jesus brings salvation and <u>change</u> to our lives! (Luke 19:1-10)
  <li>We can focus on what’s good and learn to be <u>content</u> in any circumstance. (Philippians 4:4-13)</li>
  <li>Like the generations before us, we are sent out to make <u>disciples</u>. (Matthew 28:16-20)</li>
  <li>What does God require? <u>Love</u>! (Matthew 22:36-40)</li>
  <li>We long to see the partner church for <u>mutual encouragement</u> in our faith. (Romans 1:8-17)</li>
  <li>We honor each other when we learn each other’s <u>names</u>. Write them down! Keep growing in friendship and encouragement. (Romans 16:21-24)</li>
  <li>Disciples go into the mission field with “nothing”. What do we <u>need</u> with us? (Luke 9:1-6)</li>
  <li>We are being sent to <u>share</u> the Good News about Jesus. (Luke 10:2)</li>
  <li><u>Step out</u> of your comfort zone this week, like Peter stepped out of the boat. Jesus is with you every step of the way. (Matthew 14:22-33)</li>
  <li>Our <u>unity</u> is good and precious. God blesses it with life forevermore. (Psalm 133)</li>
  <li>We <u>work</u> heartily, and it is ultimately all in service for Jesus. (Colossians 3:23-24)</li>

<p><strong>Meeting modification for multiple teams on one trip:</strong></p>
<ul>
  <li><strong>Corporate announcements and worship:</strong> Have organized corporate announcements and worship in the evenings as an entire large mission team, with all groups involved.</li>
  <li><strong>Debrief/plan/team meetings:</strong> After corporate worship, it’s important for some individual teams to have their own meeting time. This will allow the group’s leader to feel comfortable addressing any issues, debrief anyway they’d like, and go over detailed ministry plans for tomorrow. It’s important that the PPM ATL assigned to the team is a part of this meeting as well.</li>
</ul>
<p><strong>Take time with your staff:</strong></p>
<p>Staff meetings are essential for communication, checking in, encouragement, and fellowship. Make sure you take this time together as a staff team.</p>
</main>`,`<p>
  guide-page20 works!
</p>
`,`<main appHighlight [highlight]="searchTerm" >
  <h1>Orientation Meeting</h1>
  <p><strong>Welcome and Introduction:</strong></p>
  
    Share PPM’s heart for ministry 
    <li>Invite the Host Pastor to share about their church/community (if not now, another time)</li>
    <li>Introduce the participants <em>- Use provided icebreakers in training section, time permitting</em></li>
    <li>Introduce the PPM staff team</li>
  
  <p><strong>How to have a good trip:</strong></p>
  <ul>
    <li>PPM Policy Agreement - <em>Have each participant take turns reading the policies out loud.</em></li>
    <li>Stay Present, Stay Healthy
      <ul>
        <li>Distractions, Expectations, Gratitude, Journaling</li>
        <li>Health/Safety</li>
        <li>Location-specific safety</li>
        <li>Devotions </li>
      </ul>
    </li>
    <li>Lodging Information and Guidelines
      <ul>
        <li>Basics, respect, rooming, bathrooms/showers, curfew, security, emergency</li>
      </ul>
    </li>
    <li>Other Miscellaneous Guidelines
      <ul>
        <li>Follow guidance on dress code</li>
        <li>Giving out donations can be sensitive. Whenever possible, we want to give donations through the leadership
          of the local church. We lift them up as the resource managers in the community. If a community member
          approaches you about a need, let’s get them connected with local leadership.</li>
        <li>Be prepared each day with your backpack filled with the following supplies: Bible, Pelican Guide, pen,
          insect repellent, sunscreen, water bottle, sunglasses, watch, toilet paper, hand sanitizer, appropriate
          clothes, and supplies for the upcoming activities. </li>
      </ul>
    </li>
  </ul>
  <p><strong>Schedule for tomorrow:</strong></p>
  <ul>
    <li>When is breakfast? </li>
    <li>Basic times for ministry activities/church etc.</li>
    <li>What to pack/wear reminders</li>
  </ul>
  <p><strong>Closing:</strong></p>
  <ul>
    <li>Encourage the group that now is the time. This is what they’ve prepared for. Make the most out of every
      opportunity. Praise the Lord for bringing us here, etc.</li>
    <li>Worship: 1-3 songs led by group or staff (if the group is not too tired)</li>
    <li>Prayer</li>
  </ul>
</main>`,`<main appHighlight [highlight]="searchTerm" >

    <h1>Evening Team Meeting</h1>
<p><strong>Opening</strong></p>
<p>Prayer, worship: 2-4 songs, devotional thought</p>
<p><strong>Time of Debriefing:</strong> The following are suggestions of how to help the team reflect, process, and debrief each evening throughout the week. Choose one each night.</p>

  <strong>AFFIRMATIONS:</strong> Divide into groups of 15 or less with a PPM staff member in each group to lead this time. Each person gets three short affirmations. Staff needs to be ready to also affirm and start with a person that can be affirmed quickly. Introduction: <em>“We will have a time of affirmation tonight. We will go around the circle and affirm each person individually. If you would like to affirm, please raise your hand to avoid any chaos, and we will only do three affirmations per person to be fair. Remember that this is a time that everyone should be giving affirmations because everyone will be receiving them.” </em>
  <li><strong>JUST ONE THING:</strong> Large group sharing of a “thing” that you could bring home with you and put on your desk that would always remind you of something that happened today. </li>
  <li><strong>POPCORN PRAYER</strong>: Have a popcorn prayer of thanksgiving with lots of thanks for what God has done and needs we saw today. Include lots of quick prayers. People can share two to five times each if they want. Have this go 10-15 minutes.</li>
  <li><strong>MISCONCEPTIONS:</strong> Tell the real story. Lead the whole group through the following questions: “What were some common misconceptions you heard about (your location) before you came? Why do you think people have that view? What did you really see this week?” When you go back home, we want you to share what you really saw and experienced here instead of contributing to “misconceptions.” This is a way you can speak on behalf of your host community.</li>
  <li><strong>USE YOUR SENSES:</strong> Highlight one ministry area the team embarked on today. Have them close their eyes and ask them the following questions to engage their senses leaving 2-3 minutes between for reflection: <em>“What did you hear? What did you smell? What did you see? What did you touch? What did you taste? What did you feel?”</em> After you have the team open their eyes, talk through this experience as a group.</li>
  <li><strong>WALKING THROUGH THE DAY</strong>: Mention the two to four major ministry areas for the day, one by one. Take each area separately starting with the morning. Ask for two to four people to share in each area:<em> “Ways God touched your heart today, or ways you thought God’s heart was touched today”.</em></li>
  <li><strong>DESCRIBE YOUR DAY:</strong> Groups of three to four or in partners. Share two description words of how you felt today during ministry. Share two best words from your group in the large group, being intentional in describing how God is working on your heart.</li>
  <li><strong>GOD TEACHING ME:</strong> This will be a time of journaling or quiet reflection for a short time to allow all participants to have time to reflect. (A song could be played during this time if  possible.) Things to think about are: <em>“What are some things that you learned today?” “What has God tried to teach or reveal to us this week?”</em> After individual reflection, come back together and address the whole group to allow individuals to share with each other.</li>
  <li><strong>PAPER TESTIMONY:</strong> Pass out one piece of paper and crayons to participants. Ask them to answer the question, <em>“How has God changed/challenged me today/this week?”</em> Draw, write, list words, fold, rip, etc. Then give time to share.</li>

<p><strong>Schedule for Tomorrow</strong></p>
<ul>
  <li>Reiterate times for breakfast, what to wear, what to bring, things to work on, and curfew</li>
</ul>
<p><strong>Closing</strong></p>
<ul>
  <li>Worship (1 song) and prayer</li>
  <li>Talk through with the GL that this time is designated as their time to gather/plan.</li>
</ul>

</main>`,`<main appHighlight [highlight]="searchTerm" >
<h1>Closing Meeting</h1>
<p><strong>Looking Back</strong></p>
<p>Worship: 1-2 songs led by staff or team</p>
<p>Reflect on the week together:</p>

  Ministries that impacted lives. List ministries the team was involved in this week and mention some highlights. Ask for stories using questions like:
    <ul>
      <li>“What ministry event did you enjoy the most? Which one stretched you the most?”
      <li>“What is a ‘once in a lifetime’ experience you had this week?”</li>
      <li>“What is a specific moment that you want to remember from this experience?”</li>
    
  </li>
</ul>
<p>Reflect individually (optional depending on time and GL preference):</p>
<ul>
  <li>Give them a few minutes to jot some things down then a chance to share with the group. </li>
  <li>Share a devotional thought to transition from reflection to encouragement.</li>
</ul>
<p><strong>Looking Forward</strong></p>
<p>Bringing the Mission Home:</p>
<ul>
  <li>Invite the GL to speak and to process with the team how to serve at home</li>
  <li>Stay connected with each other with accountability, prayer, and staying in the Word.</li>
  <li>Keep the memories alive. The Trip Journal is always there.</li>
</ul>
<p>Invite them to stay connected with PPM. They can: </p>
<ul>
  <li>Share feedback in the survey email. </li>
  <li>Join our staff.</li>
  <li>Become a Church Champion.</li>
  <li>Serve with PPM again next year.</li>
</ul>
<p>Worship: 1 song</p>
<p><strong>Final announcements</strong></p>
<ul>
  <li>Lodging clean-up requirements, donations, thank you cards, schedule for tomorrow.</li>
</ul>
<p>
</main>`,`<main appHighlight [highlight]="searchTerm" >
  <h1>Daily Staff Meeting</h1>
  <p><strong>Devotional Thought</strong></p>
  
    Have a different person lead each night
  
  <p><strong>Debriefing:</strong> Choose one or two options.</p>
  <ul>
    <li>Share personal highlights.</li>
    <li>What is a funny story from today?</li>
    <li>What did we learn today? What was challenging today?</li>
    <li>How did we see God at work today?</li>
    <li>Check-in: How are you doing physically, emotionally, spiritually, and mentally?</li>
  </ul>
  <p><strong>Affirmation and Encouragement:</strong> Take a few minutes to affirm each other–either highlight one staff
    each night or make it random.</p>
  <p><strong>Training Moment</strong> (2-3 nights–optional for remaining nights): go through one of these items with all
    the staff present. Choose one each night.</p>
  <ul>
    <li>Post a Trip Journal</li>
    <li>Complete daily expense reporting</li>
    <li>Meet with the Group Leader on the next day’s schedule</li>
    <li>Highlight a specific training in the handbook that may be used this week</li>
  </ul>
  <p><strong>Schedule for tomorrow:</strong> Delegation review, meeting responsibilities, potential changes and updates
  </p>
  <p><strong>Close in prayer:</strong> Share prayer requests: each other, trip, participants, host church, leadership
    team members, other trips currently going on.</p>
    <br/>
  <h2>Initial Staff Meeting Suggestions</h2>
  <p><strong>Devotional Thought</strong></p>
  <p><strong>Introduction:</strong> Staff, Team, Host Church/Partnership</p>
  <p><strong>Roles and Responsibilities:</strong> Cover Delegations, Expectations, Meeting Responsibilities–especially
    for the Orientation Meeting</p>
  <p><strong>Schedule for the Week:</strong> Highlight basics, emphasize tomorrow’s schedule</p>
  <p><strong>Closing Prayer</strong></p>
  <br/>
  <h2>Final Staff Meeting Suggestions</h2>
  <p><strong>Devotional Thought</strong></p>
  <p><strong>Staff Appreciation: “</strong>Thank you”s all around with specific areas you really saw each staff member
    thrive or grow. Have a special snack/treat/outing if possible</p>
  <p><strong>Highlights:</strong> Everyone gets to share a highlight of the week</p>
  <p><strong>Affirmations:</strong> Individually, “I saw the Lord work through you this week, when…” </p>
  <p><strong>Feedback:</strong> How did this trip go? How is everyone feeling?</p>
  <p><strong>Final Responsibilities:</strong> Lodging clean up, sending team off, etc</p>
  <p><strong>Logistics Planning:</strong> Communicate with staff the plan for going to the airport, meeting the next
    Trip Leader, or preparing for the next trip so there is no confusion</p>
  <p><strong>Closing Prayer</strong></p>
</main>`,`<main appHighlight [highlight]="searchTerm" >
  <h1>Delegations and Standard Operating Procedures</h1>
  <p>We are all part of a team and we need each other to be able to minister well. Like Moses had to learn from his
    father in law, Jethro, it's important to build up a team around you so you do not have to bear the entire weight of
    leadership on your own. Moses was trying to deal with all of the Israelites and their problems as they were
    wandering through the wilderness on his own and he was getting tired and worn out. Jethro told him this in Exodus
    18:17b-18: “What you are doing is not good. You and these people who come to you will only wear yourselves out. The
    work is too heavy for you; you cannot handle it alone.” </p>
  <p>This section outlines how to assign specific tasks to each staff member and gives detailed instructions on how to
    complete each delegation according to PPM’s Standard Operating Procedures. The staff work together to make sure the
    partners and groups have what they need. We are preparing and serving together for healthy ministry partnerships to
    develop and grow.</p>
  <p><strong>Assigning Delegations and Upholding PPM’s Standard Operating Procedures:</strong></p>
  
    <strong>Delegation chart:</strong> Fill the Delegation chart out completely for each trip and talk through
      assigned responsibilities during your initial staff meeting. Enter the leadership team member names in the top
      row. Mark assignments to the areas in the columns below with an “x” to show who will oversee it. Remember one
      person is in charge of each delegation, so take leadership in your areas and ask for help when needed. 
    <li><strong>Trip Leader responsibilities:</strong> This section is packed with useful information that will help you
      lead a successful and impactful mission trip. These delegations are almost always assigned to and fulfilled by the
      Trip Leader. From time to time, an assistant trip leader may have to step into some of these roles, especially in
      training to learn about trip leading standards. </li>
    <li><strong>General team:</strong> From safety to meals or lodging, these delegations can be shared among all the
      PPM team leaders, handed off to Assistant Trip Leaders when possible.</li>
    <li><strong>Ministry delegations:</strong> This is what the trip is all about and we want the ministries to be set
      up and run well. Make sure, if you have a ministry delegation, that you read through the specifics of that
      ministry and are ready to be proactive and ensure its success.</li>
  
  <p>Colossians 3:23-24 says: “Whatever you do, work at it with all your heart, as working for the Lord, not for human
    masters, since you know that you will receive an inheritance from the Lord as a reward. It is the Lord Christ you
    are serving.” In the big things and the small things, be encouraged, be strong, and serve the Lord well.</p>
  <h1>&nbsp;</h1>
</main>`,`<main appHighlight [highlight]="searchTerm" >
 
</main>`,undefined,undefined,`<main appHighlight [highlight]="searchTerm" >
  <h1>Delegations &amp; Standard Operating Procedures- Trip Leaders</h1>
  <h2>Budget and Finance</h2>
  <p><strong>Goals:</strong> To use healthy and appropriate finance practices; preparing and being good stewards of the trip resources entrusted to us, ensuring payments happen on time with accuracy, and reporting financial transactions according to PPM policies.</p>
  <p><strong>Before the Trip</strong></p>
  <ol>
    Follow the budget that has been set for the trip to the best of your ability. Ask questions if you are needing more help to understand what the budget is and how to follow it.
    <li>Connect with your Location Leader to see if there are any special circumstances for payments to providers related to your trip such as advances or payment via Check/ACH/Wire that have been or need to be set-up with the New Vendor Set-up Form.</li>
    <li>Confirm that the Trip expense report is readily accessible to you. If internet connectivity may be a challenge for you during your trip, enable offline editing of the document for ease of daily use.</li>
  </ol>
  <p><strong>During the Trip</strong></p>
  <ol>
    <li>Allow yourself time to double-check the price before paying each itemized bill to be sure that we were given the correct price before the payment. </li>
    <li>Build time into your daily schedule on the trip for entering transaction details into your trip expense report.</li>
    <li>Pay vendors on time to keep healthy financial practices and partnerships going strong. </li>
    <li>Make sure that all cash is kept in a secure location and that payments are being made discreetly and accurately.</li>
    <li>If a vendor/partner is unable to provide an original receipt, write out a handwritten receipt with the appropriate vendor signature. Handwritten receipts should include:
      <ol>
        <li>Date, Payment amount, Name of vendor, Detailed memo of service or product provided, and Signatures of vendor/partner and PPM leader</li>
      </ol>
    </li>
    <li>If you go over budget on a trip because of unforeseen circumstances, see what can be done to adjust your spending in other areas to compensate for the overage, without compromising the priorities of the trip. If there is a big discrepancy, ask your location leader for help. </li>
    <li>If any of the other staff members have transactions related to the trip, connect with them about those details. Collect the receipts and/or cash remainders, and enter those transaction details on the expense report as appropriate.</li>
    <li>Review expenses daily for adherence to the budget </li>
    <li>For CASH expenses, take receipt photos and upload them into the Cash Receipts folder, found on the CASH tab of your expense report.</li>
    <li>CREDIT CARD expenses are entered on the CARD/OTHER tab of the expense report. Take receipt photos of the credit card transactions. Those need to be uploaded and submitted in Abacus with a note about what it is and entries selected to identify the trip.</li>
    <li>If your trip has allocated Ministry Money, make sure these expenses are kept separate from PPM money. Use the Ministry Money to purchase materials for their planned projects. Keep daily entries up to date on the dedicated tab of the expense report. These materials should be purchased in conjunction with the Group Leader and the Host partner accordingly. These donated funds are given toward the trip’s ministry and they need to be used up by the end of the trip - any remainder is to be given using exactly the amount allotted.</li>
  </ol>
  <p><strong>On the Last Day of Your Trip</strong></p>
  <ol>
    <li>Ensure all trip-related transaction details are logged correctly on the trip expense report.</li>
    <li>Scan or take a photo of all cash receipts and save them in the Cash Receipts Folder.</li>
    <li>All credit card receipt photos must be uploaded in Abacus and linked to the appropriate “Team” (Trip Location) and Category. Adding a note within Abacus for that transaction can be helpful, but is not required. </li>
    <li>Make sure all bills are paid for the entire trip as appropriate.</li>
    <li>Make a note on your expense report about any reason(s) for why the overall trip budget was not met or why it was very easily met on the Expense Report &gt; Summary tab &gt; TL Note section (on the left side).</li>
    <li>Connect with the location leader for their approval about what to do with any remaining trip cash. Make a receipt to track the fund transfer. Expense report entries for cash coming into and going out of each trip need to be entered on both of the appropriate trips expense reports. The location leader will direct you to one of these options:
      <ol>
        <li>Transfer funds to another trip leader in that location</li>
        <li>Transfer funds to your next trip, keeping it securely with you until that trip ends</li>
        <li>Write a check/transfer money to PPM. If writing a check to the PPM Office, please address it to the attention of the Finance Office noting these are returned trip funds and give the Trip ID # or the Location and Start date so that they can find the corresponding expense report where this is logged and confirm those funds are received. If you prefer to transfer the funds electronically from your personal account, reach out to Finance for our banking information.</li>
      </ol>
    </li>
    <li>Sign and date your expense report once it is completed, as soon as possible, within 3 days of the trip ending.</li>
  </ol>
  <p>If questions arise, contact the location leader. Remember, any time cash changes hands from one PPM staff to another, a written receipt acknowledged by both parties is required. Please do not hesitate to contact the Praying Pelican Missions Finance Office if you have any questions or concerns. We want to help you succeed in leading great mission trips!</p>
  <p>Address: 8011 34th Ave S, Suite 400</p>
  <p> Minneapolis, MN 55425</p>
  <p>Home Office Phone: 888-776-4090</p>
  <p>Email: finance@ppm.org</p>
  <h2>Meet The Team On Arrival</h2>
  <p><strong>Goal:</strong> To welcome the team upon arrival and, starting the week well as we are attentive and available to them, bringing peace of mind.</p>
  <p><strong>Standards</strong></p>
  <ol>
    <li>Wear identifying Praying Pelican Missions clothing.</li>
    <li>Have a cell phone with you in case of delays and to help find each other. Message the group leader about where you are waiting, if possible. </li>
  </ol>
  <p>Give an enthusiastic welcome! Introduce yourself and other PPM staff.</p>
  <p><strong>Team Driving Arrivals</strong></p>
  <ol>
    <li>Greet the group at the arranged site, most likely the church. Help them find their way to the bathrooms, sleeping areas, meeting room and when to gather there next.</li>
  </ol>
  <h2>Airport Pick up Arrivals</h2>
  <ol>
    <li>Be at the airport prior to their scheduled arrival and be ready to receive them so that they do not need to wait or look around for us. </li>
    <li>Have flight schedules with you to verify correct flights and times.</li>
    <li>If you are meeting an individual, make a paper sign with their name on it so they can find you.</li>
    <li>Contact the bus driver, have their cell number handy, and have them be at the airport 15 minutes prior to the scheduled flight arrival. Confirm the bus driver knows the schedule for the day and how to handle the pickup once the entire group has arrived.</li>
    <li>Let the group know not to get any help with luggage from a stranger or costs per bag might be incurred without their knowledge.</li>
    <li>Group members should load their own luggage so as not to accidentally load luggage that is not their own. </li>
    <li>If there is a long bus ride ahead, inform the group members that now is the time to use the bathroom. Make sure they have water and a snack.</li>
    <li>Load the bus and welcome them. Introduce them to the leadership team and bus driver, ask them to take in the sites on the drive.</li>
    <li>Give them the schedule for the rest of the day as they are seated on the bus before the bus departs.</li>
  </ol>
  <h2>Group Meetings</h2>
  <p><strong>Goal:</strong> To keep the group informed, to focus on what God is doing, to bond as a group, to pray and worship together.</p>
  <ol>
    <li>Orientation, debriefing, and daily team meetings are required. Follow the outline for the meetings provided. Give thought to the meetings beforehand and what additional issues or events need to be covered. Touch base with the leadership team to see if they have anything to add and bring good notes.</li>
    <li>Connect with the Group Leader regarding their priorities or concerns before daily meetings. Address any areas of concern.</li>
    <li>See that there is seating for everyone and that everyone is attending the meetings and participating. Make sure that the temperature is as comfortable as possible and that distractions are kept to a minimum. Shorten meetings if your team is unable to focus. </li>
    <li>Notify the leadership team members of their involvement and delegations for the meeting in advance with enough time to prepare.</li>
    <li>Have the worship person and those who are leading located where everyone can see them.</li>
    <li>Share appropriate scripture to encourage the group. </li>
    <li>The meetings should be encouraging, reflective, celebratory, informative and focused. Watch to be sure everyone is sharing throughout the week. Try to prevent individuals from dominating the time or oversharing.</li>
    <li>Keep the overall attitude positive. Share something they did well and celebrate all God is doing through them. If needed, mention areas where the group can grow or improve.</li>
    <li>At the end of the meeting, allow time for participants who need to work on ministry planning and communication. </li>
    <li>Lift up the host community. Always end in prayer! </li>
  </ol>
  <h2>Training</h2>
  <p><strong>Goal:</strong> To provide training to the group and the trip leadership team, allowing them to grow in their confidence and have more ministry tools on the trip and in the future.</p>
  <p><strong>Training the group and/or community</strong></p>
  <ol>
    <li>Be prepared to provide training on each trip, depending on the ministry goals of the group leader and/or host pastor. </li>
    <li>Review the Training section of this handbook and share the options with the other leaders. Get their input to determine what might be the best fit and how to best use the time available. Always have these training opportunities ready with you in case there is an unexpected opening or down time in the schedule.</li>
    <li>Follow the instructions and outlines. Have each person participate and allow enough time for everyone to have an opportunity to participate. </li>
  </ol>
  <h2>Training the PPM trip leadership team</h2>
  <ol>
    <li>Make time to be intentional with the PPM staff to receive training from you or others on how to lead trips well and grow in their depth of experience as they show progress. </li>
    <li>Nightly leadership team meetings are helpful times to highlight what went well and share general ideas for improvement each day. </li>
    <li>One-on-one conversations are more appropriate for sharing specifics on how each staff can keep improving. </li>
    <li>Delegating to your staff will help them to take personal ownership of their own training and growth by asking questions, stepping out, and using the resources they have been given, such as Pelican University, the Leadership Handbook, Trip Leading Checklist, On-Trip Training Guide, Pelican Guide, etc.</li>
  </ol>
  <h2>Transportation</h2>
  <p><strong>Goal:</strong> To have safe, reliable, informed and timely transportation which will allow the ministry to happen.</p>
  <ol>
    <li>Designate a person in each vehicle to make sure all people, water and supplies are aboard before leaving.</li>
    <li>Get the phone numbers of the drivers and communicate with them regarding plans for the week. Share the itinerary. Explain that things are subject to change during the week and that start/end times will depend on what the partners need. Supply a list of the site addresses and emergency medical locations to the drivers.</li>
    <li>Communicate with the drivers regarding location, directions, and timeframe each day. Don’t assume that they know where to go or are familiar with the typical traffic patterns in the area. Be prepared ahead of time to provide help with directions, so the driver can get there safely and on time. </li>
    <li>If using a hired driving service, share with the drivers about the bigger picture. Make them feel welcome and a part of the week. They are always invited to eat meals with us while we are on the road. They are a part of the ministry this week. Recognize and thank them in front of the group.</li>
    <li>Make sure vehicles are locked or being closely watched when they are unoccupied.</li>
    <li>Connect about fueling up vehicles ahead of time so that the team is not delayed for a fuel stop unnecessarily.</li>
    <li>PPM staff drivers must adhere to the Vehicle Usage and Safety Policy, found in the MC Document Library.</li>
  </ol>
  <h2>Trip Setup</h2>
  <p><strong>Goal:</strong> To prepare well to come alongside the local church and facilitate a healthy long-term relationship, a genuine partnership, and sustainable ministry.</p>
  <ol>
    <li>Be intentional about talking individually with the Group Leader and the Host Pastor about next year to see if they are interested in a long-term partnership together. Share the vision and benefits of a long-term relationship and a partnership together through PPM. Give examples of groups already doing that.</li>
    <li>Be intentional in connecting the Group Leader and the Host Pastor a few times throughout the week.</li>
    <li>Sit down with the Group Leader and talk through a plan and timeframe for next year’s trip if they are open to that. This can happen anytime during the week or at the end of the week. </li>
    <li>After the team and trip dynamics are running and are in good shape, set aside time to maintain communication for the other mission trips and partnerships that you are building. Be aware of your upcoming trip(s) needs and any time sensitive plans to confirm. Be careful not to miss your priorities on the current trip. </li>
  </ol>
</main>`,`<main appHighlight [highlight]="searchTerm" >

</main>`,`<main appHighlight [highlight]="searchTerm" >

    

</main>`,undefined,undefined,`<main appHighlight [highlight]="searchTerm" >
  <h1>Delegations &amp; Standard Operating Procedures - General Team</h1>
  <h2>Safety</h2>
  <p><strong>Goal:</strong> To have the trip participants, PPM leaders, and community members be safe and secure at all
    times on the trip so they can minister effectively and Jesus Christ can be proclaimed.</p>
  <ol>
    Be on the lookout for and take care of any safety issues with the group and PPM staff on the trip. These could
      include areas such as food, transportation, behavioral, cultural, relational, or other areas. Work in conjunction
      with your leadership team to help ensure a safe trip for all. Bring awareness to any areas that could affect the
      team negatively in regard to safety. Remember, safety is our number one priority.
    <li>Take time to review the lodging facility with the local host prior to the team arriving. Ensure that the lodging
      is secure/safe, clean, and ready for the group. See that window and door locks work. Keep rooms locked whenever
      the group is not there. Verify a contact name and number should you need to reach someone if a problem arises</li>
    <li>Make sure tools and supplies are being used appropriately and that no one is taking unnecessary risks. Help to
      ensure working teams stay balanced, healthy, and hydrated, taking breaks as needed so they can continue serving
      well.</li>
    <li>Oversee the enforcement of the dress code and intentionally check daily to make sure everyone is wearing
      appropriate clothing, including recreation activities. If the dress code needs to be addressed with a participant,
      tell the GL so they can handle it. </li>
    <li>If doing outside ministry, remind participants to apply sunscreen regularly if necessary. Bug spray should be
      applied as well if necessary. Bring bottles with you to reapply when needed.</li>
    <li>Make sure all backpacks and ministry bags are in a secure place or being watched over while the group is doing
      ministry.</li>
    <li>When able, utilize a local staff member or church leader to help deal with any suspicious persons.</li>
    <li>Make sure that no one leaves the group without permission or is hanging out in an unsafe place.</li>
    <li>When splitting into smaller groups, ensure there is always an adult present in each group. Make sure adults and
      PPM staff have shared phone numbers.</li>
    <li>When the group is walking places, keep a sharp eye out to see that they are out of the way of traffic, walking
      in safe areas, and sticking together.</li>
    <li>Make sure group members are keeping a safe distance from animals. Be aware of the potential dangers with the
      local animals and plants to have an appropriate respect and distance from the “wildlife.”</li>
    <li>Report any qualifying incidents to the Trip Leader and/or the Incident Management team as needed for support
      with handling serious accidents, injuries and other incidents. </li>
  </ol>
  <h2>First Aid and Medical</h2>
  <p><strong>Goal:</strong> To have plans in place and supplies on hand to treat any medical situations that arise. This
    should give peace to the group and allow for the best care.</p>
  <ol>
    <li>Make sure a first aid kit is brought for the group and is fully stocked at the beginning of the trip. If needed,
      stock it yourself. Ensure it contains the following:
      
        <li>Ibuprofen</li>
        <li>Tylenol</li>
        <li>Pepto Bismol</li>
        <li>Imodium</li>
        <li>Benadryl</li>
        <li>Assorted Band-Aids</li>
        <li>Antiseptic wipes (or hydrogen peroxide and cotton swabs)</li>
        <li>Triple antibiotic ointment</li>
        <li>Anti-itch cream</li>
        <li>Gauze</li>
        <li>Medical tape</li>
        <li>Non-latex gloves</li>
        <li>Tweezers</li>
        <li>Thermometer</li>
        <li>Check with location leaders on location-specific First Aid items. </li>
      
    </li>
    <li>Confirm the location of the nearest acceptable urgent care facility, clinic, or hospital. Be prepared to help a
      driver to get to this location.</li>
    <li>Download the recommended American Red Cross First Aid mobile app on your phone for use and reference.</li>
    <li>See that the first aid kit goes wherever the group goes on the trip. This includes all ministry sites, lodging,
      during meals, recreation activities – everywhere!</li>
    <li>Let the team know where they can locate the first aid kit and that it is available to them.</li>
    <li>Read through the participant forms to see if there are any medical concerns or allergies to be aware of during
      the trip. Your Trip Leader may have an electronic copy.</li>
    <li>If there are any medical professionals in the group, ask them on the first day at the orientation meeting if
      they would be willing to help if there are any medical situations that arise that are in their areas of expertise.
      If there are any lifeguards in the group, ask if they would be willing to keep a watch over the group when
      swimming. </li>
    <li>Assist with miscellaneous medical issues. This could include such things as finding crutches, bringing
      eyeglasses to be repaired, bringing someone to the dentist, etc.</li>
    <li>Make sure the first aid kit is kept safe and that it is returned or transferred to the next Trip Leader. Restock
      it or make the next team aware of items needed.</li>
    <li>Alert the Incident Management team and complete a report for qualifying health and medical incidents within 24
      hours of the incident.</li>
  </ol>
  <h2>Lodging and Bathrooms</h2>
  <p><strong>Goal:</strong> To have lodging that provides the group with safe, clean, working and appropriate
    accommodations.</p>
  <p><strong>Hotel:</strong></p>
  <ol>
    <li>Identify and reserve an appropriate meeting area for evening meetings.</li>
    <li>Build a good relationship with the on-site management since we probably will want to return in the future. Thank
      them!</li>
    <li>Communicate with the person responsible for breakfasts, to ensure they have what they need.</li>
    <li>If packed lunches need to be made in the mornings, confirm with the hotel where the best place for that to take
      place would be.</li>
    <li>Connect with the hotel staff ahead of time to see what can be done to make the check-in and check-out processes
      happen as quickly and smoothly as possible. </li>
    <li>Ask if they will be able to provide you with the room keys ahead of time to be ready upon arrival so that you
      can distribute them as you welcome the team members. </li>
  </ol>
  <p><strong>Non-Hotel Lodging:</strong></p>
  <ol>
    <li>Make sure there is a plan for bathroom usage and showers. Check everything to ensure it is all working properly!
    </li>
    <li>If showers are shared between genders, be explicit in directions of separate guys’ and girls’ shower times.
      Remind participants to not leave their shower supplies in the bathroom.</li>
    <li>Have adequate trash facilities and plans. Know where to find extra trash bags and where the dumpster is located.
    </li>
    <li>Obtain a key for the building and keep them locked when off-site. Have a plan for a common meeting area and
      eating area. Get extra keys for PPM staff if the team is splitting into different groups for multiple worksites.
    </li>
    <li>Have a plan for a common meeting area and eating area.</li>
    <li>The ideal setup is a guys’ sleeping room, girls’ sleeping room, and two staff rooms. It’s helpful to take
      pictures of the rooms before the team arrives so that they can be put back properly if things need to be moved.
    </li>
    <li>Discuss any potentially difficult conversations privately so that the group is not able to hear them.</li>
    <li>Any people from outside the group who are on the premises should visit in a common area. Visitors are not
      allowed in the sleeping rooms.</li>
    <li>No guys in girls’ sleeping areas and vice versa.</li>
    <li>Designate a plan for staff (and/or participants for some teams) to do bathroom clean-up on a twice/day schedule.
      Wipe sinks and toilets clean. Clear out the shower area. Empty trash. Ensure that toilet paper, trash bags and
      cleaning supplies are available to keep things in good condition.</li>
    <li>Emphasize taking care of the facility. Encourage group members to keep their areas neat and zip up food tightly.
      Make time at the end of the trip for the team to clean up the areas they were using. Know where to find a vacuum
      cleaner if needed as well as broom, mop, and buckets.</li>
    <li>Encourage modesty in clothing and sleeping attire at all times. No walking around in towels. Participants must
      go into the shower fully dressed and come out the same way.</li>
  </ol>
  <h2>Rooms and Curfew</h2>
  <p><strong>Goal:</strong> To keep the group safe and rested during the entire trip, while allowing for bonding of
    roommates to occur.</p>
  <ol>
    <li>One person on the team should have the rooming list and keys and give directions to the team members upon
      arrival.</li>
    <li>Set clear expectations of cleanliness and care for rooms in the orientation meeting. Make teams aware of items
      or places that are off limits. Property damage caused by participants is not covered by PPM, but by the group.
    </li>
    <li>Make the team aware ahead of time of any times they need to move their bedding/suitcases and where to properly
      store them. </li>
    <li>The rooming list should be made in coordination with the Group Leader before the trip. Share a copy of the
      rooming list with the leadership team members and the Group Leader. A hotel rooming list should be made and shared
      with the hotel ahead of time. Check on any needed changes and share updated lists. </li>
    <li>Separate the guys’ rooms from the girls’ rooms with the guys’ rooms being more toward the entrance/exit.</li>
    <li>No girls should be in guys’ rooms or vice versa. To fellowship, they should do so in a common area. If they need
      to make an exception, they must receive permission.</li>
    <li>Each night at the evening meeting, announce the curfew time.</li>
    <li>One person each night, preferably rotating throughout the team, should give a 10-minute warning to participants
      before curfew time and then enforce curfew. That person should check to make sure all is okay 15 minutes after
      participants are in their rooms.</li>
    <li>Curfew for adult teams should be more flexible and tailored to the group and the location</li>
    <li>.Everyone should know which rooms the leaders are in and feel free to knock on the door at any time of night in
      case of emergency.</li>
  </ol>
  <h2>Music and Worship</h2>
  <p><strong>Goal:</strong> To oversee the music and worship that happens in group meetings and as a part of ministry on
    the trip. This will enable the group to praise and worship God effectively and hopefully to be able to use their
    gifts during the week.</p>
  <ol>
    <li>Communicate with the Trip Leader to find out when songs should be played during the evening meeting. </li>
    <li>Work with any music people in the group. Communicate expectations and allow them to succeed. </li>
    <li>If the group does not have a guitar player and song leader willing to lead evening worship, determine if PPM
      leadership team members are able to step in and fill those roles. </li>
    <li>See that a guitar is available and there is someone to play it for evening worship and the closing meeting
      whenever possible.</li>
    <li>If possible, 2-4 worship songs at each evening and played straight through with breaks only to read scripture or
      for a short appropriate devotional. This sets the mood for the meeting and allows for an uninterrupted time of
      praise and worship.</li>
    <li>See that appropriate equipment is brought to meetings and ministry sites. Help out in any way that is needed.
    </li>
    <li>Check with people singing special music and leading worship in church and get their plan of action. Make sure
      the songs are appropriate for the church and culture. Do your best to allow for people to read the words with song
      sheets, overheads, etc.</li>
  </ol>
  <h2>Trip Journals</h2>
  <p><strong>Goal:</strong> To provide daily updated blogs and pictures on the PPM website with detailed information of
    events occurring on the trip. </p>
  <ol>
    <li>If you have not done this before, complete the Trip Journals training in Mission Control.</li>
    <li>On the first day of the trip, take and upload a group picture to the trip journal. If you have several groups,
      take an overall group picture of everyone. </li>
    <li>Connect daily/nightly to the internet to share the best overall photos and to appropriately blog about what
      happened throughout the day. </li>
    <li>Keep the writing positive. Remember, this trip journal will be a representation of their trip on our website
      forever!</li>
    <li>Include at least one picture for each day of the trip. If it is possible, highlight each type of ministry that
      happens throughout the trip. If you have more photos with shorter descriptions of the ministry day, that works
      well too.</li>
  </ol>
  <h2>Recreation</h2>
  <p><strong>Goal:</strong> To ensure the group has what they need for a safe and positive experience during recreation
    time.</p>
  <ol>
    <li>Confirm with the Group Leader their expectations and wants for their recreation time.</li>
    <li>If applicable, confirm any necessary reservations or tickets have been purchased ahead of time.</li>
    <li>Confirm or share the meal options with the group leader ahead of time (options vary by location).</li>
    <li>Prepare participants to dress in weather/activity appropriate attire in line with clothing guidelines (swimsuit,
      hiking shoes, sun protection, jacket, etc.).</li>
    <li>Group Leader and participants should know when and where to meet up if the team splits up.</li>
    <li>Let participants know how long they will be gone and what they need to bring for the day (i.e., water bottle,
      change of clothes, layers of clothing, sandals). </li>
    <li>See location-specific documents for more information.</li>
  </ol>
  <h2>Birthdays and Miscellaneous Supplies</h2>
  <p><strong>Goal:</strong> To have needed miscellaneous supplies ready, including birthday treats, during the trip.</p>
  <ol>
    <li>Connect with the Trip Leader to see what has been or can be arranged, what tips they have, and what budget is
      allowed. Stay within budget!</li>
    <li>Check regularly that enough cups, napkins, trash bags, plastic utensils, toilet paper, etc. have been purchased
      for all meetings and ministry locations.</li>
    <li>Check before the trip to see if there are any group members who have birthdays occurring during the week.
      Connect about the plans to celebrate. Ask cooks if they can provide a birthday cake for the evening meal.</li>
    <li>If the local cooks are unable to make a birthday cake, plan on no more than a $5 budget for a single serving of
      cake (with candles), ice cream, etc. Sing “Happy Birthday” and bless them.</li>
  </ol>
  <h2>Water</h2>
  <p><strong>Goal:</strong> To provide the group with a safe, unlimited supply of water in order to keep them hydrated
    during the entire trip.</p>
  <ol>
    <li>Arrange for a supply of as much purified drinking water as the group members can drink and set up a communal
      water filling station. If tap water is available, ask that it is safe for the team to drink. See that enough
      drinking water will be available at each ministry site throughout the day. Bring a filled water cooler or jugs
      with you to ministry sites.</li>
    <li>Remind the group members to fill their water bottles before leaving each day for ministry. Keep reminding them
      to drink enough water. Make it your personal mission that no one will get dehydrated during the week.</li>
    <li>If necessary, make people who you don’t think are drinking enough water drink right in front of you. Remind the
      group that if they aren’t going to the bathroom and having clear urine, they aren’t drinking enough water. </li>
    <li>Let group members know that they should drink water before they are thirsty and that soda is no substitute for
      water. Ask the team to keep track of how much water they drink so they can help measure their intake and stay
      hydrated.</li>
    <li>Make sure that no one is sharing water out of their bottle with anyone else. We are happy to provide water to
      the community members or team members who lost their bottle, but they must drink out of their own cups. If team
      members lose their water bottle, make sure they are able to purchase a wide mouth bottle to use the rest of the
      week. They need a water bottle. </li>
    <li>Make a plan and share it so that people can adequately wash their dirty water bottles.</li>
    <li>Keep track of refillable water jugs. Keep a close count on how many are there and where they are located. Let
      the group know we need to keep track of these bottles and treat them gently. In some places, we pay a hefty
      deposit on the jugs.</li>
    <li>If you pay deposits for jugs in your location: At the end of the trip, return your water jugs and collect the
      deposit. In some cases, you may need to get help to manage the return or transfer of empty jugs and the refund of
      bottle deposits.</li>
  </ol>
  <h2>Breakfasts/Lunches/Dinners</h2>
  <p><strong>Goal:</strong> To provide the group with nourishment that will strengthen them and provide them with a
    variety of food that they will enjoy.</p>
  <ol>
    <li>Connect with the Trip Leader to see what has been arranged, what tips they have, and what budget is allowed.
      Stay within budget!</li>
    <li>Read through each participant’s medical form to see if there are any necessary dietary restrictions that need to
      be accommodated.</li>
    <li>See that the meal is prepared and ready to be eaten on time and in accordance with our Food Safety guidelines.
      Be aware of changes in the schedule.</li>
    <li>Give a timeframe for breakfast. For example, 8-8:40. The group members can come and grab breakfast anytime
      during that time frame. This will allow for flexible morning bathroom schedules.</li>
    <li>Encourage all leadership team members to be present during most of the breakfast time.</li>
    <li>Provide a variety of foods at each meal. Some trips may include bagged lunches prepared by the team.</li>
    <li>Some meals could be picked up from restaurants, eaten at restaurants, or eaten at the ministry site. Provide
      cups, napkins, plates, silverware, and any necessary condiments.</li>
    <li>Check the lunch/dinner menu plans for the week and ensure we serve a good variety of entrees.</li>
    <li>Connect with the GL during the week to make sure the meals are meeting all dietary needs and expectations. Make
      changes as appropriate. For example, you may need to add protein sources for vegetarians, provide more fruit, or
      look for new drink options. </li>
    <li>Sanitize all surfaces where food will be kept, plated, and eaten. </li>
    <li>Remind the group members to wash their hands and to use hand sanitizer before eating.</li>
    <li>Arrange for someone to pray for the meal before the group eats. </li>
    <li>Be available during meals to visit, stay on top of items running low, clean-up, etc. </li>
    <li>Be sure to store foods in containers that are sealed tightly and spills are cleaned up well. This prevents
      drawing critters to our food or people slipping on the floor. </li>
    <li>Some of the staff should eat last. As appropriate, help serve the meal, see if the food is good, and that
      everyone is having a good time and filling up.</li>
    <li>Make necessary adjustments during the week. If there is someone who is not eating, be aware of who he/she is and
      talk to the group leader or him/her about adequately nourishing themselves.</li>
    <li>Post a copy of the <a
        href="https://drive.google.com/file/d/1OM2KhOXCLwjBl40TYaQqmyN6F03zirgK/view?usp=sharing">Food Safety
        guidelines</a> where food preparation is happening if possible.</li>
  </ol>
  <p><strong>Meals Prepared by Host Partner Cooks:</strong></p>
  <ol>
    <li>Share PPM’s Food Safety guidelines with your cooks for the week. </li>
    <li>Work with the cooks to see that adequate quantities and variety of food is being purchased and prepared. Help
      cooks plan with portions equalling a full plate of food for everyone at each meal.</li>
    <li>Arrange for the cooks to purchase the food. Make sure the cooks have enough money to purchase the food. </li>
    <li>Remember to save some of the budget to pay/tip the cooks at the end of the week. Check your location budget for
      what is appropriate. </li>
    <li>Make sure that the food is being prepared with purified water and are foods that the participants can handle.
    </li>
    <li>See that the cooks have all the necessary supplies for serving the food. This includes plates, cups, napkins,
      etc.</li>
    <li>Become friends with the cooks. Encourage them and help them however you can. They are a vital part of the week
      and of our ministry. Bring them out and clap for them once or twice during the week in front of the group and make
      sure the group members are thanking them.</li>
    <li>Arrange for adequate seating.</li>
    <li>Make sure there is a clear plan to throw garbage away after the meal.</li>
  </ol>
  <p><strong>Restaurant Meals:</strong></p>
  <ol>
    <li>Stay within budget and negotiate! Catering may be the best option for large groups. </li>
    <li>Order your catering in advance. Many restaurants require 24-48 hours notice for groups.</li>
    <li>Find out if PPM has eaten at the restaurant before and what foods have been good.</li>
    <li>At restaurants, prepare to give a few options in an “adjusted menu” verbally or have a buffet arranged. Don’t
      let the restaurant pass out menus (to prevent confusion and ensure timeliness).</li>
    <li>Be clear on drink orders and let the group know what is covered by PPM. Try to arrange for drinks as part of the
      meal. This often can be included in a set price. </li>
    <li>Make sure that the meals are being prepared with purified water and with food that the team can handle.</li>
    <li>If possible, go ahead of the group to make sure everything is set and on time. </li>
    <li>Welcome the group to the restaurant and give directions as far as where to sit, where bathrooms are located, and
      what the meal plan is. </li>
    <li>Make sure the bill is correct and is paid before you leave. </li>
    <li>Thank the restaurant and workers and tip according to the local cultural norms. </li>
  </ol>
</main>`,undefined,`<main appHighlight [highlight]="searchTerm" >
			
  <div >
    <h1 >Ministry Skills Training</h1>
  
    <div >
      
      <p>The opportunity we have to be able to offer training tools to participants to use during the week long mission trip and take home with them for continued refining and use is unique in that they will be able to use these skills and practice them in a place where the mindset of ministry is priority. Once they use these tools here, they are more likely to put them into practice at home! So, whether this is time set aside on day one or a preparation for a planned ministry, use these ministry skills training guides to help your teams serve well.</p>
      
      <h3>Categories of Training:</h3>
      
      
        <b>Team Building:</b> Life Story Training,Foot Washing Training, Prayer Web Training, Ice Breakers
        <li><b>Outreach and Evangelism:</b> The Gospel in One Verse, The Canyon and the Cross, Testimony Training, Prayer Walking</li>
        <li><b>Deeper Development:</b> ACTS Prayer Pattern Training, Healing Conversations, SOAP Bible Study Method, Relational Treasure Hunt</li>
      
      
      
      <h3>Best Practices:</h3>
      <ul>
        <li><b>Train your staff to train your team:</b> Use some time in your staff meetings to review one of these training guides during the week. Have other staff members lead the training.</li>
        <li><b>Be Prepared:</b> When there is some down time, connect with your GL to see if a training time would be a good alternative and which training you would like to offer. This could happen with a weather delay or waiting for another team.</li>
        <li><b>This tool will stay with them forever:</b> This is a great time for participants to learn something new, try something they aren’t necessarily comfortable with, and practice in a safe environment.</li>
        <li><b>Pray through the training:</b> God can use these types of training in powerful ways. Pray for those going through it and watch for those who may have been stretched or impacted in a deeper way.</li>
        <li><b>Allow for Time:</b> Depending on the involvement of each of the individuals in these groups, the training could take as little as 20 minutes or as much as 2 hours. Plan it out, but be sensitive to how the group is responding and how God is moving.</li>
      </ul>
      
      
    
  </div>
</main>`,`<main appHighlight [highlight]="searchTerm" >
			
  <div >
    <h1 >Team Building Trainings</h1>
  
    <div >
      
      <h3>Life Story Training</h3>
      <p><b>Purpose/Objective:</b> To build community in the group through the use of telling our life experiences that can also be a tool for use in the mission field.</p>
      
      <p><b>Key Scripture:</b> Hebrews 10:24-25 &quot;And let us consider how we may spur one another on toward love and good deeds, not giving up meeting together, as some are in the habit of doing, but encouraging one another—and all the more as you see the Day approaching.&quot;</p>
      
      <p><b>Introduction:</b> Sharing a life story is a tool that can be used across cultures by everyone. We have all lived our own lives and have experiences that are ours alone. We are the experts on our own lives. We’ll have the opportunity in a little while to get together in groups of four to five people and share our life story. We will each have seven minutes of sharing time. And for some reason if you finish before the seven minutes are up, those minutes still belong to you, so the group can ask you questions about what you’ve said.</p>

      <p><b>Instructions:</b></p>

      
        <b>Example:</b> Trip Leader or Assistant Leader gives their life story. The other person should do timing with a three-minute, one-minute warning and call time discreetly when the seven minutes is up. Sharing your story will give people an idea what they will be doing and time to think about their story. You will model wrapping up your story and using the full seven minutes. Ask for the person timing you to also model giving a brief affirmation of one or two words about you.
        <li><b>Work Time:</b> Ask questions like &quot;what can be included in a life story? Childhood, high school, adult years? Events, people, experiences, challenges, family faith journey?&quot; Then give them seven minutes to write down some notes to share about as they think through their story.</li>
        <li><b>Give Stories and Affirmations:</b> Get in groups of four to five and share life stories in the room where everyone is gathered. Use a central timer and be clear to keep the groups on track allowing all people to share. As soon as one person is done sharing their story, each of the other people in the group will share a word or two of what they appreciate or qualities they see in that person. Give a full minute for affirmations before the next person in the group shares their life story until everyone has a turn.</li>
      
      
      <p><b>Review:</b> Ask, &quot;Why did we just do this? What was challenging and what was easy? How can this be used in our lives back home? How can this be used on a short-term mission trip?&quot;</p>
      
      <p><b>Conclusion:</b> You have just had the opportunity to get to know some people in the group better and added another tool to be used back home and on a mission trip. We encourage you to ask at least one person about their life this week.</p>

    
  </div>

</main>`,`<main appHighlight [highlight]="searchTerm" >
			
  <div >
			
    <div >
      
      <h3>Foot Washing Training</h3>
      <p><b>Purpose/Objective:</b> This is meant for groups/teams to be a time of showing servanthood and leadership between PPM staff or group leaders and the members of the team. It’s also meant to be a time of reflection and prayer.</p>
      
      <p><b>Key Scripture:</b> John 13:14-16 &quot;Now that I, your Lord and Teacher, have washed your feet, you also should wash one another’s feet. I have set you an example that you should do as I have done for you. Very truly I tell you, no servant is greater than his master, nor is a messenger greater than the one who sent him.&quot;</p>
      
      <p><b>Supplies Needed:</b> wash basins or buckets of water, towels</p>
      
      <p><b>Introduction:</b> Read John 13:1-17. Highlight the important lessons and application: Jesus is teaching the importance of letting God wash and cleanse us, forgiving us of our sins and protecting us from living in a broken and sinful world. Jesus is also displaying the importance of servanthood because, although Jesus was a leader, doing a &quot;dirty task&quot; was not beyond his scope of involvement. Washing feet was a symbolic act representing that Christ followers are to serve others in ways that are humbling and simple.</p>

      <p><b>Instructions:</b> Now, we (PPM staff and group leaders) want to wash your feet.</p>

      
        Have multiple leaders wash feet. A ratio of one foot washer to every six to eight people is appropriate.
        <li>While the foot washing happens, worship music plays in the background to allow time to reflect and pray OR have other Bible passages being read to expand on the idea of servanthood including: Luke 22:27; Phil 2:1-30; Rom 10:15; James 1:27; 1 John 3:17; Luke 6:35.</li>
        <li>Team members who are waiting to have their feet washed can use this time for journaling and reflecting.</li>
      
      
      <p><b>Review:</b> What was it like to have your feet washed? How do you think the disciples felt when Jesus washed their feet? How can you serve others this week?</p>
      
      <p><b>Conclusion:</b> We will have so many opportunities to serve others this week in our group and in this community. Look for big and little ways you can serve. When you serve, you may have the opportunity to share why you serve: because Jesus showed us how!</p>

    
  </div>

</main>
`,`<main appHighlight [highlight]="searchTerm" >
			
  <div >
			
    <div >
      
      <h3>Prayer Web Training</h3>
      <p><b>Purpose/Objective:</b> To unite a group of people together in a &quot;web of prayer&quot; for the week or any specific amount of time.</p>
      
      <p><b>Key Scripture:</b> James 5:16 &quot;Therefore confess your sins to each other and pray for each other so that you may be healed. The prayer of a righteous person is powerful and effective.&quot;</p>

      <p><b>Introduction:</b> In praying for each other, we become connected in a really amazing way. This training gives us an opportunity to pray for others within our group and share a little of our own heart at the same time.</p>
      
      <p><b>Supplies Needed:</b> Bible, yarn, paper, and pens</p>

      <p><b>Instructions:</b> This training is best done in groups of 15 or less and set up with the group sitting in a circle.</p>

      
        Preparation: give the team a few minutes to choose one discussion passage of Scripture that is significant to them and write it down on the provided piece of paper.
        <li>Lead by example: We are going to share our verse with one person in the group, pray for that person, then use this yarn to show how prayer and encouragement connects us as a group. I'll go first to show you how. Walk over to a person across the circle, share your verse with them and why it is important to you. Ask them how you can pray for them, then pray. Give them the paper with your verse on it and the ball of yarn while you hold on to the end of the yarn and go back to your spot. Now, it's your turn to give your verse to someone else and pray for them. Choose someone across the circle. Let each person have a turn until everyone has a new verse and is still holding the yarn forming a &quot;Web&quot;.</li>
      
      
      <p><b>Review:</b> We can literally see how prayer has connected us. Sharing Scripture with each other has given us the opportunity to encourage one another. Was this easy or hard for you? Which part was most challenging? How can you use this tool this week?</p>
      
      <p><b>Conclusion:</b> This is how it is with the Body of Christ. This is meant to not only be a time of prayer among the group, but that each individual can continually pray for the person they said a prayer for and shared their Scripture with throughout the rest of the mission trip. Close in prayer.</p>
      
      <p><i>Concepts for this illustration are taken from Discipleship Journal, issue sixty-one, 1991, &quot;One-Verse Evangelism&quot; by Randy Raysbrook, pp. 34-37.</i></p>

    
  </div>

</main>`,`<main appHighlight [highlight]="searchTerm" >
			
  <div >
			
    <div >
      
      <h3>Icebreakers</h3>
      <p><b>Purpose/Objective:</b> To help a team or teams that may not know each other to become more comfortable with one another and to use in a ministry setting such as a youth event.</p>
      
      <p><b>Key Scripture:</b> Proverbs 17:22a &quot;A cheerful heart is good medicine.&quot;</p>

      <p><b>Introduction:</b> Sometimes the first few minutes or days on a trip can feel awkward. Since we are together for such a short time, it’s important that we get to know and feel comfortable with each other quickly.</p>
      
      <p><b>Supplies Needed:</b> Bible, yarn, paper, and pens</p>

      <p><b>Instructions:</b> Choose one of the following Icebreakers to use with their team.</p>

      
        <b>Draw your Coat of Arms:</b> <i>Need: paper/pens or crayons.</i> Have the team get into groups of four. Each person thinks of a thing that could easily represent them. As a group, combine those 4 things into one coat of arms. Draw it as best as you can and share with the large group after all the groups are finished. You never know what each group will come up with!
        <li><b>Line up:</b> The group's goal is to work together to arrange themselves into a line according to predetermined criteria. Start with some to practice verbal communication: arrange in alphabetical order by first name, last name, and in numerical order by age. Then move to nonverbal communication (they cannot speak): arrange by height, eye color, shirt color from lightest to darkest.</li>
        <li><b>Name Game:</b> Have the group sit in a circle where everyone can see the others. The first person says their name. The next person continues, but after saying their own name, they repeat the first person’s name. This continues with each person repeating one more name. Reassure people towards the end that it’s ok if they get stuck &amp; encourage the others to jump in to help if anyone is lost.</li>
        <li><b>No Smiling:</b> Instruct everyone to keep a straight face and do not smile under ANY circumstance in the first five minutes of the meeting. People turn into children with an instruction like this, and immediately start looking at others, seeing how they cope. The anticipation makes everyone giggly, so after a while they cannot suppress their laughter anymore.</li>
        <li><b>Toilet paper:</b> <i>Need: a roll of toilet paper.</i> Pass the toilet paper asking and instruct everyone to take as much as they think they'll need. Once everyone has done toilet paper, inform the group that for each square they will have to share one thing about themselves. Start with yourself then move around the circle until everyone has shared.</li>
        <li><b>Unique and Shared:</b> In groups of 4-5 people, let each group discover what they have in common, along with interesting characteristics that are unique to a person in the group. This icebreaker promotes unity as it gets people to realize that they have more common ground with their peers than they first might realize. Take a few minutes to share as a large group.</li>
        <li><b>Whose Story is it?</b> <i>Need: paper, pens, a bowl.</i> Instruct the group to write their funniest or weirdest story on a small piece of paper. Then fold the paper up and drop it into a bowl. The facilitator or the person leading the program randomly reads every story &amp; the group has to guess who the writer is.</li>
      

    
  </div>

</main>`,`<main appHighlight [highlight]="searchTerm" >
  <div >
			
    <div >
      
      <h1 >Outreach Trainings</h1>
      
      <h3>The Gospel in One Verse</h3>
      <p><b>Purpose/Objective:</b> To equip team members with a tool to share the Gospel with others.</p>
      
      <p><b>Key Scripture:</b> Romans 6:23 (NIV) &quot;For the wages of sin is death, but the gift of God is eternal life in Christ Jesus our Lord.&quot;</p>
      
      <p><b>Supplies Needed:</b> Bible, paper, pen</p>

      <p><b>Introduction:</b> Sharing the Gospel can seem intimidating, but knowing how to walk through the simple truth and how to explain it to someone else can help a lot. This is one way to present the Gospel with others.</p>

      <p><b>Instructions:</b></p>

      
        Read Romans 6:23
        <li>Write out and box in the following words taking time to talk through each one:
          <ul>
            <li><b>WAGES:</b> How would you define wages? Payment for work done or the reward we receive for what we have done. It is getting what we deserve.</li>
            <li><b>SIN:</b> What is &quot;sin&quot;? Sin is not as much an action as it is an attitude. It is often an attitude that is hostile to or ignores God.</li>
            <li><b>DEATH:</b> What do you think of when you think of death?</li>
            <li><b>BUT:</b> How does the word &quot;but&quot; change the sentence? &quot;But&quot; is the most important word in the verse because it indicates hope.</li>
            <li><b>GIFT:</b> How would you describe the difference between wages and a gift? A gift is not earned or worked for; it was purchased for us by someone else.</li>
            <li><b>OF GOD:</b> The verse indicates that there is a gift offered by God. No one else can give you this gift. Why does anyone want to give a precious gift to someone else?</li>
            <li><b>ETERNAL LIFE:</b> In your thinking, what is eternal life? Eternal life is the opposite of death. Rather than separation from God as in death, eternal life is harmony with God, a relationship with Him forever.</li>
          
        </li>
      </ul>
      
      <p><b>Review:</b> Get into groups of 4 and talk through Romans 3:23. See if you can add anything to what has already been said. Which word made you think the most?</p>
      
      <p><b>Conclusion:</b> The Gospel is the most important thing we can ever share with anybody. Romans 3:23 is absolutely true and knowing how you can share the Truth about this wonderful gift can empower you. Know that your job is not to save people, but it is to plant seeds around you.</p>

    
  </div>

</main>
`,`<main appHighlight [highlight]="searchTerm" >


</main>
`,`<main appHighlight [highlight]="searchTerm" >
	<h1>Food Safety</h1>

  <h2>Hygine</h2>
  
    Wash hands with soap for 20 seconds, ideally with warm water and paper towels to dry
    <li>Wash hands again when handling a new food or after touching anything other than food or utensils to avoid cross contamination</li>
    <li>Long hair should be held back in a ponytail.  Hats or hair nets are preferred.</li>
    <li>Nobody feeling ill is allowed to be in the kitchen or serving food</li>
  

  <h2>Water</h2>
  <ul>
    <li>Confirm with host partner that water is  safe for team to drink </li>
    <li>If water is not ok for team consumption,  purchase purified water for kitchen use  </li>
    <li>If ice is used, ensure it is purified and any  containers it is stored in are sanitized </li>
    
  </ul>

  <h2>Temperature</h2>
  <ul>
    <li>Cook food to a proper internal temperature. When in  doubt: 165°F </li>
    <li>If perishable food has been sitting out for less than 1  hour, properly cool for future use </li>
    <li>Cool food in shallow containers to bring temperature  down evenly </li>
    <li>Reheat to 165°F before serving (reheat food only once  for team before throwing away leftovers) </li>
    <li> Thaw frozen food in the refrigerator or under cool,  running water </li>
    
  </ul>

  <h2>Time</h2>
  <ul>
    <li>If food has been sitting out for more than 1 hour,  throw leftovers away after meal is complete </li>
    <li>Food can be sitting out in room temperature and  served for up to 2 hours (1 hour in temperatures  above 90°F) </li>
    <li>If saving food for future use, follow proper  temperature protocol </li>
    
  </ul>


  <h2>Boxed Food</h2>
  <ul>
    <li>Food can be packaged and brought to the team  as long as it is consumed within 2 hours • Throw out all leftovers </li>
<li>If transporting cold food in hot temperatures,  transport in coolers with ice </li>

  </ul>

  <h2>Food Storage</h2>
  <ul>
    <li>Invest in bins to store dry food in kitchen </li>
    <li>After each trip, cans, spices, and unopened  boxes are ok to transfer to next trip </li>
    <li>Throw away any opened or refrigerated  food or donate to church or individuals  </li>
    
  </ul>

  <h2>Prevent Cross Contamination</h2>
  <ul>
     <li>Store meat at bottom of refrigerator • Use separate cutting boards, knives, and utensils  for each item of food </li>
    <li>Wash and sanitize food prep area after each  new food item </li>
    
  </ul>

  <h2>Produce</h2>
  <ul>
    <li>Wash produce under cool, drinkable, running water </li>
    <li>If peeling, still rinse produce prior to peeling so  bacteria and dirt are not transferred to utensils </li>
    <li>If there is no way to wash produce in drinkable water,  only serve team produce that can be peeled  </li>

  </ul>

  <h2>Gloves</h2>
  <ul>
    <li>Gloves are required for anyone handling  or serving food </li>
    <li>Have a box of gloves available at all times </li>
    
  </ul>


  <h2>Flies</h2>
  <ul>
    <li>If flies are a concern, keep food covered until serving </li>
    <li>If food is covered in flies, throw it away. That food is  not ok to serve. </li>

  </ul>

</main>
`,`<main appHighlight [highlight]="searchTerm" >
	
  <div >
			
    <div >
      
      <h3>Prayer Walking Training</h3>
      <p><b>Purpose/Objective:</b> To lead team members on a guided prayer journey through the community.</p>
      
      <p><b>Key Scripture:</b> Ephesians 6:18 &quot;And pray in the Spirit on all occasions with all kinds of prayers and requests. With this in mind, be alert and always keep on praying for all the Lord’s people.&quot;</p>

      <p><b>Introduction:</b> As Christians, prayer should be an essential part of our lives. It doesn’t need to be filled with fancy words, all God requires is you. He already knows what’s going on in your life, but He wants you to invite Him into your life and to allow Him to enter the conversation. Sometimes, prayer can be hard because we don’t know how to &quot;pray correctly&quot; or it can feel uncomfortable. The best way to become comfortable with anything is through practice. You were placed here, in this community, for a purpose. God brought you here for a reason. There are people here that the only way to help them is through prayer.</p>

      <p><b>Instructions:</b> Your testimony can be shared at almost any point during your mission trip.</p>

      
        <b>Gather and explain the prayer walking plan:</b> <i>&quot;This tool can be used as a pre-evangelism exercise, can increase our awareness of the need and the layout of the community, can create change in the spiritual climate, and can open doors for ministry opportunities.&quot;</i>
        <li><b>Break the team into groups of three or four and give them slightly different directions to walk.</b> <i>&quot;The goal is to pray as we are walking. Pray as if you are in a conversation with each other and God. Pray for things you notice. Pray for people you pass. Pray for the homes you see and pass by. Pray intentionally for blessings on the community, the churches, the leaders, softening of hearts, protection from sin, revival, strongholds in peoples’ lives and anything else that comes to mind. If God leads you to stop and talk to people or pray longer in one place–do it, but just keep praying and walking.&quot;</i></li>
        <li><b>Pray over the entire team and send them out for 30-60 minutes.</b></li>
      
      
      <p><b>Review:</b> As the team starts coming back together, encourage them to continue praying and pray as a large group until everyone returns. Close the prayer time and ask a few questions to debrief the prayer walking time: <i>&quot;What was the purpose of this prayer walk? How do you think you can continue this at home? What did you learn from this time of prayer and reflection? Do you think you accomplished anything?&quot;</i></p>
      
      <p><b>Conclusion:</b> 1 Thessalonians 5:17 says &quot;Pray without ceasing&quot; and today we practiced that. We can have a conversation with God wherever we are and can pray for things as they come to our attention. The prayers don’t have to be long, but we can keep that conversation with God going and He loves to hear from us!</p>

    
  </div>

</main>`,`<main appHighlight [highlight]="searchTerm" >
  <h1>Delegations &amp; Standard Operating Procedures - Ministry</h1>
<h2>Adult or Youth Ministry</h2>
<p><strong>Goal:</strong> To come alongside the local church as they desire to minister to youth our adults, allowing the group members to use their gifts and talents.</p>
<ol>
  Talk to the Trip Leader to determine what plans are in place and what still needs to be set up or confirmed.
  <li>Talk to the Group Leader to confirm plans for ministry, timeframe, and supplies brought. See if there are any questions or concerns. Stay in communication to see how help can be given. Put them in contact with the host leader before the ministry starts whenever possible to confirm final plans. </li>
  <li>Confirm teaching plans and topics with the host leader to make sure they are appropriate for the culture, community, and venue. Check the curriculum beforehand to lend support and to make sure that what is being taught is in line with the church’s beliefs. </li>
  <li>Develop a plan with the host leader in charge of this ministry to promote the ministry and to compel people to attend.</li>
  <li>Be available to help in any way possible. For example: Does daycare need to be arranged? Does the group need more chairs? Are there enough handouts? Make sure facilities, supplies, equipment, and promotion are all appropriate and running smoothly.</li>
  <li>Write an outline of what is going to happen. Allow all the people involved to have the outline to know when they are doing their part.</li>
</ol>
<h2>Children’s Ministry</h2>
<p><strong>Goal:</strong> To come alongside the local church as they desire to minister to children, allowing the group members to use their gifts and talents to minister to children.</p>
<ol>
  <li>Talk to the Trip Leader to determine what plans are in place and what still needs to be set up or confirmed.</li>
  <li>Talk to the Group Leader to confirm plans for ministry, timeframe, and supplies brought. See if there are any questions or concerns. Check the curriculum beforehand to lend support and to make sure that what is being taught is in line with the church’s beliefs. </li>
  <li>Allow the team time to prepare materials as needed beforehand.</li>
  <li>Talk to the host pastor to confirm the time frame and children’s ministry details, including: promoting the children’s ministry, providing snacks, and having some adults join in on the ministry. Are any church members or Sunday School leaders coming to help? We hope to do this ministry together with their church, not just at their church. </li>
  <li>Connect with the local church member(s) to open up the church and assist with the setup together, if possible. Ask for their insights on the kids attending. If there is no one available, get a key from the pastor for the church so setup can begin. </li>
  <li>Before it starts, see if more promotion is needed. If so, have the group members go out inviting kids to the event. Flyers help.</li>
  <li>Confirm that all supplies are brought to the ministry site on time. Make sure supplies such as toilet paper, cups for drinking water, etc. are on hand.</li>
  <li>Be on-site to help out however possible as the ministry begins. Make sure there is adequate water supply, snacks are brought, the group’s belongings are secure, pictures are being taken, and everyone is safe.</li>
  <li>Get a total head count each day of children participating.</li>
</ol>
<h2>Church Ministry</h2>
<p><strong>Goal:</strong> To bless and connect the local church partner and the group through local church worship services.</p>
<ol>
  <li>Talk with the Trip Leader to determine what details are known about the church services and Sunday school. </li>
  <li>Check on availability of sound system items and musical instruments.</li>
  <li>Get contact information for the pastor. Check the time of the services and Sunday school and plan to arrive on time.</li>
  <li>Meet with the host pastor upon arriving. Bring a list of what the group is prepared to share to the host pastor at least one hour before the service. Help integrate the list with what the host pastor has for an order of worship until it is finalized together. Allow all the people involved to have the outline to know when they are doing their part.</li>
  <li>Clearly communicate what the pastor is expecting for the church service and Sunday school. Remind those who will be participating when they will be involved in the service.</li>
  <li>If not enough participants step forward, recruit people to participate with testimonies, music, etc.</li>
  <li>Be willing to make announcements in church to thank the church for hosting the group. Give information on PPM, introduce the Group Leader, and share what we will be doing during the week.</li>
  <li>Be considerate of preaching expectations and confirm with the host pastor the plan. Experience and confidence is expected. If a female is scheduled to preach, please confirm if that is accepted in their church.</li>
  <li>Sit near the front in the worship service in order to communicate with the pastor, with the person going next, and to help out however possible.</li>
</ol>
<h2>Outreach</h2>
<p><strong>Goal:</strong> To share the Good News, draw people to Jesus, and connect them to the local church.</p>
<ol>
  <li>Connect with the Trip Leader on details regarding what has been discussed so far in the plans.</li>
  <li>Talk to the Group Leader to confirm plans for ministry, timeframe, and supplies brought. Ask about prior experience for the group and if there are any questions or concerns.</li>
  <li>Check with the host pastor
    <ol>
      <li>Get their advice on doing outreach in the community.</li>
      <li>If possible, see if any church members can join the outreach.</li>
      <li>Confirm that they are ready to do follow-up. Have a method in place to collect names and home addresses or email addresses for follow-up. </li>
      <li>Get tips regarding safety: where to go, what to expect, etc. </li>
      <li>Make a plan for visiting in the community while providing safety for everyone. Make sure an adult male is in each group if possible. </li>
    </ol>
  </li>
  <li>Prepare the group to be ready to share the host church name, the address, and the pastor’s name. This will help interested people get connected there. </li>
  <li>Be prepared to give training and instruction. Many people have never participated in outreach. There are many methods of outreach, such as evangelism, sharing the Good News of Jesus, helping people get connected to a church community, offering prayer, etc. Ensure that the group is confident with the plan. If it’s the first time for many of them, allow for some time to model potential conversations so they have some ideas about how to engage people in a positive way. Introduce yourself, ask questions as you meet people to get to know more about them, and respond to the people you meet as you are led by the Holy Spirit. Questions like:
    <ol>
      <li>“What do you believe about God? (listen to what they say) If they ask, share, "I believe…”</li>
      <li>“How do you get to heaven?...Nobody's good enough - we need Jesus!”</li>
      <li>“Is there anything going on that I could help with?” “What is your most pressing unmet need?”</li>
      <li>"Do you have a church community? Our local church partner is here…”,</li>
      <li>“Can I pray for you?”</li>
    </ol>
  </li>
  <li>Pray before heading out into the community.</li>
</ol>
<p>Onsite</p>
<ol>
  <li>Recognize people’s nervousness and reassure them. Give them clear instructions as they go as to time frame, locations, what to do, etc.</li>
  <li>Encourage all participants to participate in sharing, but not forcing anyone if they are too uncomfortable. </li>
  <li>Remind them to be present, focused, reliant on God, and culturally appropriate. Take community member concerns and safety concerns seriously. Assist people in their next steps with resources for care.</li>
  <li>Gather the group together when they are done and debrief. Ask how it went, what highlights they have, how they encountered God, what impact this will have, what they learned about the neighborhood and locals they came across, etc.</li>
</ol>
<h2>Medical Ministry</h2>
<p><strong>Goal:</strong> To bless and serve the community and to allow the group members to use their gifts and skills to provide health services.</p>
<p>Prepare</p>
<ol>
  <li>Talk to the Trip Leader to determine what plans are in place and what still needs to be set up or confirmed. The consultant or trip leader needs to confirm the skillsets of the medical professionals and that they are currently licensed to practice medicine. </li>
  <li>Talk to the Group Leader to confirm plans for ministry, timeframe, and supplies. See if there are any questions or concerns.</li>
  <li>Talk to the host pastor or the key local contact overseeing the medical screening service.
    <ol>
      <li>Confirm the time and services available. </li>
      <li>Make sure the event was promoted sharing the time and services available.</li>
      <li>Confirm the location for the health screening/clinic. </li>
      <li>Get a key if necessary to allow the group to set up before the clinic begins.</li>
      <li>Identify the primary local medical contact that can be present at the clinic and assist in follow-up care for patients.</li>
    </ol>
  </li>
  <li>Confirm that all supplies are brought to the ministry site on time.</li>
  <li>Make sure the group has a working system in place for taking names, prioritizing needs, communicating services provided, seeing patients, and providing care.</li>
</ol>
<p>Onsite</p>
<ol>
  <li>Have an opening prayer before the medical ministry begins and turn it over to the Group Leader to make any announcements.</li>
  <li>Be onsite to help out however possible as the ministry happens and to ensure everyone is safe.
    <ol>
      <li>Make sure there is adequate water supply with cups for locals. </li>
      <li>Keep the group’s belongings secure.</li>
      <li>Ensure that pictures are being taken with permission.</li>
      <li>Doctors or nurses have a copy of their medical license on hand.</li>
    </ol>
  </li>
  <li>Get a total headcount each day of patients seen at a health clinic.</li>
</ol>
<h2>Mercy Ministry</h2>
<p><strong>Goal:</strong> To bless and serve the community and to allow the group to bless and serve others.</p>
<p>Prepare</p>
<ol>
  <li>Talk to the Trip Leader to determine what plans are in place and what still needs to be set up or confirmed.</li>
  <li>Connect with the contact person at the appropriate mercy ministry site.
    <ol>
      <li>Share about PPM’s connection with the local church partner. Ask if they are already connected with the church partner too. Highlight and strengthen the connection with the local church and the mercy ministry by sharing with each of the leaders and groups involved.</li>
      <li>Confirm the group’s arrival, time frame, and what is expected. Check to be aware of any age restrictions, screening, photography restrictions, and dress code required.</li>
      <li>Get the location and directions to the site and share that with the drivers. Prepare to pass on any parking, arrival or entrance notes.</li>
      <li>Ask the director to give a five to ten-minute introduction to the group upon arrival, if possible. If they or their staff are unable to share an introduction and orientation, take notes about the mercy ministry site so that you will have things to share, including: history, number of people, purpose behind ministry, and expectations. </li>
    </ol>
  </li>
  <li>Talk to the Group Leader to confirm the plans for ministry, timeframe, activities and any supplies to bring. </li>
  <li>Connect with the team the night before their upcoming visit, so they have time to ask questions. Prepare the team to look for intentional connections (listen, serve, help, play a game, share the Good News, invite someone to church, sing, pray with someone, etc.). </li>
  <li>Make sure appropriate equipment is brought along and ready for use. If the group plans to give out gifts or donations there, talk to staff onsite about the best way to give those. Make a plan and follow it. </li>
</ol>
<p>Onsite</p>
<ol>
  <li>Orient the group upon arrival. Share the plan for the ministry connection and overview of the time. Make sure the group is aware of how to act, where to go, what is appropriate, what are some good ways to engage with the people, etc. </li>
  <li>Create a positive atmosphere and encourage good interaction. Invite people to join in and connect. </li>
</ol>
<h2>Prayer Ministry</h2>
<p><strong>Goal:</strong> To pray for the community, to allow the group to connect in the community and to grow through prayer for others.</p>
<ol>
  <li>Make plans to be as safe as possible.
    <ol>
      <li>Only do prayer walking in the daylight in a safe area. </li>
      <li>Arrange for church members to join the team if possible.</li>
      <li>Decide how many groups you will have and where they will go. </li>
      <li>Make the route(s) clear and ensure a working phone is in each group.</li>
      <li>Confirm when to be back.</li>
    </ol>
  </li>
  <li>Confirm the plan with the Group Leader. Allow the Group Leader to organize the groups to include an adult and a mix of genders in each group. </li>
  <li>Make sure participants have weather appropriate gear such as hats, sunglasses, sunscreen, rain coat, jacket, water bottle, etc. as needed.</li>
  <li>Introduce the prayer ministry to the group prior to arrival using the Prayer Walking training in the Training section of this handbook or another resource in accordance with the Group Leader’s preferences. Introduce the topic and set the tone for this ministry. Most people have not participated in prayer walking before, so give an overview of why the group is doing this ministry. </li>
  <li>Encourage all participants to participate in praying aloud, but not forcing anyone if they are too uncomfortable. </li>
  <li>Remind them to be present, focused, and culturally appropriate.</li>
  <li>Gather the group together when they are done and debrief. Ask how it went, what highlights they have, how they encountered God, what impact this will have, what they learned about the neighborhood and locals they came across, etc.</li>
</ol>
<h2>School Ministry</h2>
<p><strong>Goal:</strong> To bless and serve the community, allowing the group to use their gifts and interests to reach out to students during their school day.</p>
<ol>
  <li>Talk to the Trip Leader to determine what plans are in place and what still needs to be set up or confirmed. </li>
  <li>Talk to the Group Leader about their plans, supplies, and needs. Answer questions.</li>
  <li>Connect with the contact person at the school to confirm:
    <ol>
      <li>The group’s arrival time</li>
      <li>Time frame</li>
      <li>What is expected from the group during their visit</li>
      <li>Where to go</li>
      <li>Who they should connect with onsite</li>
    </ol>
  </li>
  <li>Share your group’s plans with the contact person from the school for their approval, input, or changes. Take interest in the school and make notes, including: history, number of students, ages and grades, and expectations.</li>
  <li>Meet with the contact person ahead of the group to introduce yourself and make sure things are ready to go upon the group’s arrival.</li>
  <li>Introduce the school ministry to the group before/upon their arrival. Talk to the group about how to act, where to go, what is appropriate, and the timeframe.</li>
</ol>
<p><strong>Service Projects</strong></p>
<p><strong>Goal:</strong> To bless and serve the community, allowing the group to use their gifts and talents to serve.</p>
<p>Prepare</p>
<ol>
  <li>Talk to the Trip Leader to determine what plans are in place and what still needs to be set up or confirmed. Is any Ministry Money available? </li>
  <li>Visit the project areas to see what will be done. Do this with the foreman, host pastor or local person in charge. Talk through the projects. Ask the following questions and take notes:
    <ol>
      <li>What are the goals? </li>
      <li>What supplies have been purchased? </li>
      <li>What still needs to be purchased? </li>
      <li>Who will get them?</li>
      <li>Which funds will be used: the partner’s, or is Ministry Money available? </li>
      <li>Where will they be purchased? </li>
      <li>Will there be a foreman or local expert on site to give technical direction? </li>
      <li>What tools are needed? </li>
      <li>Can you get a key to the church or project? </li>
      <li>Does the work space, skill level, and timeframe match the itinerary and participants?</li>
      <li>How many people can be actively engaged in the work at each site?</li>
      <li>How long do we estimate the project will take with this team?</li>
    </ol>
  </li>
  <li>Plan work or activities for all participants to be engaged during the project time. Get help if needed to estimate project timelines and potential needs.</li>
  <li>Talk to the Group Leader to confirm plans for ministry, timeframe, tools, and supplies brought. See if there are any questions or concerns. </li>
  <li>Double-check before leaving for the project to make sure appropriate tools, clothing, materials, and supplies are being brought to the site.</li>
</ol>
<p>Onsite</p>
<ol>
  <li>Before the project starts each day, talk to and motivate the group about the importance of the project and the goals. Organize the team to accomplish the task.</li>
  <li>Prepare the team to look for intentional ministry opportunities (share the Good News, invite someone to church, pray with someone, etc.).</li>
  <li>Emphasize safety above all else!
    <ol>
      <li>Make sure group members are being cautious, getting enough breaks, and drinking enough water. </li>
      <li>Have a first aid kit on hand. </li>
      <li>Make safety checks as you observe the work. Ladders need to be stable. Wear work gloves to prevent blisters and safety glasses when appropriate. Ventilation with fumes. Remove hazards like nails on the ground. Block off areas with exposed wires. </li>
      <li>If a participant gets injured, get them immediate help. If you are an ATL onsite, connect with the TL of the trip to keep them aware of the situation and get further instructions.</li>
    </ol>
  </li>
  <li>Keep the project and group on track regarding the timeframe for each day and the overall timeframe for the entire service project plans. Confirm with the leaders the priorities of the construction project if adjustments need to be made so that the goals of the group and the needs of the host are considered.</li>
  <li>Oversee the work. Don’t get immersed in a specific project so much that oversight gets lost. Your job is not to do the work but to set the group up to do it safely and successfully. Find projects for those who don’t have work and motivate those not working.</li>
  <li>Check the quality of the work happening and make sure the project is up to a good standard.</li>
  <li>Be the go-to person for group members who have other ideas as to what additional projects or what additional materials are needed.</li>
  <li>Have a clean-up plan and execute it.</li>
  <li>Finish each day’s project highlighting accomplishments and talking about tomorrow’s project. Get the leaders’ thoughts on any more supplies or preparations for tomorrow’s project.</li>
  <li>Stay within the budget allowed. Keep receipt photos for project transactions. Communicate with the Trip Leader about purchases each day. Ministry money for the construction project is tracked separately from general PPM trip money.</li>
  <li>We value people over projects. The service project is just a means to show that you care, and deepen relationships in a different way. Make a respectful, genuine connection.</li>
</ol>
<h2>Sports Ministry</h2>
<p><strong>Goal:</strong> To bless and serve the community, allowing the group to use sports as an avenue for evangelism, fellowship, or discipleship.</p>
<p>Prepare</p>
<ol>
  <li>Talk to the Trip Leader to determine what plans are in place and what still needs to be set up or confirmed.</li>
  <li>Talk to the Group Leader to confirm plans for ministry, timeframe, and supplies brought. Plan a ministry connection time before people are ready to leave. It could be an ice-breaker, short testimony, prayer, gospel sharing, local church invitation, etc. Answer any questions or concerns. </li>
  <li>Communicate with the person in charge of sports for the community to see if there is a specific ministry time, what the goals are for sports ministry, and what needs to be shared or announced during the event. </li>
  <li>Find the appropriate venues and make sure they will work. For example: Does the soccer field have goals? Does the basketball court have nets?  Where is a bathroom we can use? </li>
  <li>Develop a plan to promote sports ministry to make sure enough of the community members know about it and come to participate.</li>
  <li>Make sure appropriate equipment is brought along and ready for use before the sports ministry starts</li>
</ol>
<p>Onsite</p>
<ol>
  <li>Share the plan for the ministry connection and overview of the time. Prepare the team to look for intentional ministry opportunities (share the Good News, invite someone to church, pray with someone, etc.).</li>
  <li>Ask the group to explain the rules of the game if needed. Create a positive atmosphere and encourage good sportsmanship. Invite those who are not “sports people” to join in and play. Allow everyone who wants to participate to have a turn.</li>
  <li>Encourage those on the sidelines to cheer, welcome guests, invite people to church, etc.</li>
  <li>Keep the first aid kit nearby.</li>
  <li>Bring plenty of drinking water for everyone. Make sure people are hydrating themselves every 30 minutes with their refillable bottles. Provide cups for water if needed. </li>
</ol>
<p>
</main>
`,`<main appHighlight [highlight]="searchTerm" >
	<div >
			
    <div >
      
      <h3>Healing Conversations</h3>
      <p><b>Purpose/Objective:</b> To equip teams serving to be able to allow healing conversations to happen through learning the art of asking powerful questions and listening well.</p>
      
      <p><b>Key Scripture:</b> Romans 12:15 &quot;Rejoice with those who rejoice, weep with those who weep.&quot;</p>

      <p><b>Introduction:</b> Think of an event in your life when everything changed. Maybe it was a natural disaster, a move to a new school or church, the loss of a loved one. You may have felt helpless or hopeless. People we will encounter this week may have faced an event like that. When people are feeling helpless, hopeless, or powerless, we can give them a voice, enabling them to move from &quot;victim&quot; to &quot;survivor&quot;. This is not replacing professional counseling or therapy if that is needed. It is simply asking powerful questions and listening well.</p>
      

      <p><b>Instructions:</b> Processing a crisis happens over time. Today, we are going to talk through some powerful questions and keys to listening well, then we are going to practice with a partner.</p>

      
        <b>Powerful Questions 101:</b> Powerful questions will help cycle through facts, thoughts, and feelings. Here are some helpful hints:
          <ol>
            <li>Invite conversation by using open-ended questions
            <li>Offer a starting place – &quot;What have the last few weeks been like for you?&quot;</li>
            <li>Clarify details – &quot;Who was with you when...&quot;</li>
            <li>Help to dig deeper - &quot;What has been the hardest part of this crisis?&quot;</li>
            <li>Give opportunities to express feelings – &quot;what emotions do you see on others’ faces?&quot;</li>
            <li>Find hope – &quot;What good can you see in this hard time?&quot;</li>
          </ol>
        </li>
        <li><b>Explain the basic &quot;keys&quot; to listening well:</b>
          <ol>
            <li>Get curious – try to understand their experience</li>
            <li>SLOW down – put aside other distractions</li>
            <li>Pay Attention – watch and listen to the speaker</li>
            <li>Show you are listening – respond with nods and facial expressions</li>
            <li>Provide Feedback – ask clarifying questions and rephrase what you heard back to them</li>
            <li>Allow silence – even if it feels awkward</li>
            <li>Don’t interrupt</li>
            <li>Respond with respect – acknowledge that there is no wrong way to feel and share thoughts and opinions only when asked.</li>
            <li>Never try to fix it or say &quot;I understand&quot;.</li>
          </ol>
        </li>
        <li><b>Practice:</b> We are all going to practice listening to our peers as they process something that has changed the world: COVID-19. The youngest of the two gets to listen first. Typically, we don’t want to put a time limit on these kinds of conversations, but, for today, we are going to give you each 10 minutes.</li>
      
      
      <p><b>Review:</b> Was there anything that stood out to you when you were practicing asking questions and listening? Was it hard to not interrupt and share your experiences? How can you use this tool this week? Can you use this tool at home?</p>
      
      <p><b>Conclusion:</b> We live in a broken world where crisis exists. These experiences affect us and we need to process them. We can use these tools for ourselves, our families, and for those we encounter. God has given us such a great hope, let’s use these opportunities to be a blessing to others and help them process hard times.</p>

    
  </div>

</main>
`,`<main appHighlight [highlight]="searchTerm" >
	<div >
			
    <div >
      
      <h3>S.O.A.P. Bible Study Method</h3>
      <p><b>Purpose/Objective:</b> To help teams continue to grow in their faith and give them confidence to be able to study the Bible on their own and lead a Bible Study.</p>
      
      <p><b>Key Scripture:</b> 2 Tim 3: 16-17 &quot;All Scripture is breathed out by God and profitable for teaching, for reproof, for correction, and for training in righteousness, that the man of God may be complete, equipped for every good work.&quot;</p>

      <p><b>Introduction:</b> We have all heard that we need to read our Bibles and that’s good, but there is something special about going beyond simply reading the Word and digging deeper into what God has said to us in the Bible. The big question is &quot;How do I study my Bible?&quot; There are so many Bible Study Methods available and today we are going to learn one of them. This one is called the SOAP Bible Study Method.</p>
      
      <p><b>Supplies Needed:</b> Bible, paper or journal, and pen</p>

      <p><b>Instructions:</b></p>

      
        Explain the basics of this method using the letters S.O.A.P.
          <ul>
            <li><b>S-Scripture:</b> Write out the Scripture passage you are studying.
            <li><b>O-Observation:</b> Answer these questions: What do you see? Who is the audience? Isthere any repetition? What word(s) stand out to you?</li>
            <li><b>A-Application:</b> Answer the following questions: What is God saying to me? How can I apply this in my own life? What changes do I need to make? What action should I take?</li>
            <li><b>P-Prayer:</b> Pray God’s word back to Him. If He revealed something to you, pray about it. Ask Him for strength to make the changes or take the action.</li>
          
        </li>
        <li>Practice: You can choose to have the team do the practice as a group or quietly as individuals. Walk the team through one letter at a time using Ephesians 4:1-3.
          <ul>
            <li>S-Give a few minutes to have everyone write the Scripture down.</li>
            <li>O-Ask the observation questions above. Have them write down the answers.</li>
            <li>A-Use the questions listed above and give a few minutes to answer.</li>
            <li>P-Take some time to have the individuals pray through what they just learned.</li>
          </ul>
        </li>
      </ul>
      
      <p><b>Review:</b> If you had the team do the practice section individually, give them some time to share as a group some of the observations and applications that stood out to them. Review why studying Scripture is important. Ask: &quot;How can you use this tool in your daily life? How can you use it with your youth group? How can you use it this week?&quot;</p>
      
      <p><b>Conclusion:</b> This tool may work for you as you study your Bible, but there are many other methods that are available for you to choose from. The point is that we need to study our Bibles and we can use this method to help others learn to study their Bibles as well.</p>
      
      <p >Concepts for this training tool were taken from online sources: https://lovegodgreatly.com/how-to-soap</p>

    
  </div>

</main>`,`<main appHighlight [highlight]="searchTerm" >

  <div >
			
    <div >
      
      <h3>Relational Treasure Hunt</h3>
      <p><b>Purpose/Objective:</b> To give the team a new perspective on how God answers prayers, even in the smallest things.</p>
      
      <p><b>Key Scripture:</b> Ephesians 2:10 &quot;For we are God’s masterpiece. He has created us anew in Christ Jesus, so we can do the good things he planned for us long ago.&quot;</p>

      <p><b>Introduction:</b> I want to ask you three questions. Silently, answer to yourself: Do you believe God listens at all times? Do you believe you’ve heard God speak to you before? Do you believe God answers every prayer? For question 1, affirm that God always listens. Pray silently as you assess their answers to question 2; they are valid being yes or no. Give hope that perhaps we need to listen through His Word, His people, and our silence; perhaps we need to be open to answers looking &quot;different&quot; than what we want. In question 3, you get the opportunity to speak into the &quot;answer process.&quot; Yes, No, and Waiting are all answers.</p>
      
      <p><b>Supplies Needed:</b> Paper, pen, record keeper</p>

      <p><b>Instructions:</b></p>

      
        Before we start, we’re going to be still and be in God’s presence (Review the ACTS pattern of prayer concept here). I want you to close your eyes. And as you close your eyes, allow God to share an object with you, in your mind. It can be any object. It can have color to it. It can be big or small. I’m going to allow a minute for you to be in God’s presence and for this object to be shared with you.
        <li>Now the record keeper is going to collect your name and corresponding objects. <i>(Go around the room, having each person share.)</i></li>
        <li>You have all identified your treasure for the week! And we are going to be treasure hunters! We are about to venture out into this community. The image that God has shared with you is special. It’s only for you. It’s your treasure.</li>
        <li>As we serve this week, we are going to pray for what we see and for what God lays on our hearts. As you pray for and talk with the person connected to your &quot;treasure&quot; you will get to hear part of their story or share part of yours. You can start by saying, &quot;God wanted me to talk with you! He put (object) on my heart earlier in the week, and you happen to (be holding it, have it on your shirt, etc). You are my treasure!&quot; From there, have a conversation with this person and pray for them.</li>
      
      
      <p><b>Review:</b> Share about a time when you found your &quot;treasure&quot; or use these examples: <i>Your object is a flamingo; a girl shows up to VBS with a flamingo on her shirt. Pray for her and her family. OR Your object is a rainbow; there is a restaurant called the &quot;Rainbow Cafe&quot; you come across. Go in and find a staff member or patron to talk and pray with.</i></p>
      
      <p><b>Conclusion:</b> You never know how God will show up. Later this week, we will take some time to share about how God used this &quot;Treasure Hunt&quot; this week.</p>

    
  </div>

</main>`,`<main appHighlight [highlight]="searchTerm" >
	<div >
			
    <div >
      
      <h1 >Incident Management Guidelines</h1>
      
      <p><b>Safety should be foremost in the minds of our leaders and team members.</b> This is without question our #1 priority. Planning should be done to prepare safe and healthy environments, wherever we are working or serving. Even with the best plans in place, situations may occur that can put us in harm’s way. How we respond in these situations can mean the difference between a situation and a crisis.</p>
      
      <p>The intention of this document is to manage incidents before they become a potential crisis situation, or a greater crisis. We have confidence in you as a leader; reviewing this document and having it on hand at all times will be a critical tool for you.</p>
      
      <p><b>The PPM Incident Management Team will be notified of any incidents that are considered emergency or could create a potential liability or crisis for PPM. Delay in notifying the Incident Management Team could seriously impact PPM’s options in handling the situation and averting a crisis.</b></p>
      
      <p>Incident Reports must be completed for each reportable incident that occurs during the course of any trip.
        Staff Member or Participant Incident Report (required)
        <li>Supervisor or Leader Report of an Incident (required)</li>
        <li>Witness Report of an Incident (if applicable)</li>
        <li>Missing Person(s) Incident Report (if applicable)</li>
      </p>
      
      <p>Incident Report Forms are available to you in the following places:</p>
      
        <li>Online through PPM’s Mission Control
          <ul>
            <li><a href="https://www.prayingpelicanmissions.org/missioncontrol" target="_blank">www.ppm.org/missioncontrol</a>
              <ul>Enter your email address
              <ul>Enter your previously assigned password</ul>
            </li>
            <li>Mission Control > Trips > Incidents</li>
            <li>Forms completed in Mission Control will automatically send to the Incident Management Team for review and appropriate follow-up.</li>
          </ul>
        </li>
        <li>Copies with the Incident Management section of the printed Leadership Handbook or with the PPM location operations leader.
          <ul>
            <li>Complete and scan to <a href="mailto:incident@prayingpelicanmissions.org" target="_blank">incident@prayingpelicanmissions.org</a></li>
          </ul>
        </li>
      </ul>
      
      <p><b><i>It is imperative that the Incident Reports are completed within 24 hours of each incident in order to ensure appropriate documentation and follow-up, minimizing the risk and liability to all parties involved.</i></b></p>
      

    
  </div>

</main>
`,`<main appHighlight [highlight]="searchTerm" >
  <div >
			
    <div >
      
      <h1 >Incident Management Team &amp; Responsibilities</h1>
      
      <p>The PPM Incident Management Team will be notified of any incidents that are considered emergency or could create a potential liability or crisis for PPM. Delay in notifying the Incident Management Team could seriously impact PPM’s options in handling the situation or averting a crisis.</p>
      
      <p><b>Situations may include, but are not limited to:</b> Serious Injury, Accident, Natural Disaster, Fire, Missing Person, Kidnap, Loss of Life, Robbery, Abuse, etc.</p>
      
      <p>During PPM business hours (8:30am to 4pm CST M-F), call the PPM Minneapolis Office at <a href="tel:8887764090" target="_blank">888.776.4090</a> or email to <a href="mailto:info@prayingpelicanmissions.org" target="_blank">info@prayingpelicanmissions.org</a> and you will be directed to an available Incident Management Team member. After hours and weekends, an Incident Management Team member should be contacted directly at the corresponding number below:</p>
      
      <div >
        <p><b>Members of the Incident Management Team are:</b></p>
        <p>Jim Noreen <a href="tel:6512168009" target="_blank">(651) 216-8009</a><br>Jeff Houdek <a href="tel:6129787066" target="_blank">(612) 978-7066</a><br>Laura Yoch <a href="tel:6513479336" target="_blank">(651) 347-9336</a><br>Therese Kaletka <a href="tel:6129655471" target="_blank">(612) 965-5471</a><br>Jennie Pakan <a href="tel:6513562269">(651) 356-2269</a></p>
      
      
      <h3>Incident Management Team Responsibilities</h3>
      <p ><b>Determine the level of potential liability to the organization.</b></p>
      <p>Upon receiving notice of a potential or existing crisis or emergency situation, the Incident Management Team member will assess the situation to determine if the situation warrants an incident management plan to be established.</p>
      
        If it is determined further review of the situation is warranted, the Incident Management Team will conference to formulate a plan.
        <li>If not, completed incident reports from the persons involved with the actual incident will be collected and retained on file at the Minneapolis Office.</li>
      
      
      <p ><b>If potential liability is anticipated, the Incident Management Team will formulate an incident management plan, including activities as follows:</b></p>
      <ul>
        <li>Notify respective PPM Location Leader of situation.</li>
        <li>Notify partnering ministry in location of service, if applicable.</li>
        <li>Establish regular line of communication with Trip Leader or Supervisor in charge where incident occurred to keep abreast of situation and provide guidance.</li>
        <li>Contact responding authorities or emergency services for updates, if applicable.</li>
        <li>Contact crisis management support services, if applicable.</li>
        <li>Determine if team member of PPM should travel to location of incident to assist.</li>
        <li>Notify legal counsel, if legal advice needed.
          <ul>
            <li>Determine name of legal counsel assigned through liability insurer.</li>
          </ul>
        </li>
        <li>Notify Board of Directors, if appropriate.</li>
        <li>Communicate situation with PPM staff or specific team members as appropriate.</li>
        <li>Notify applicable liability insurer.</li>
        <li>Document entire process along the way.</li>
      </ul>
      
      <p ><b>For both Domestic and International Claims:</b></p>
      <p>Notify liability insurer Marsh &amp; McLennan Agency of situation at (763) 746-8000 and/or claims@marshmma.com.</p>
      <ul>
        <li>Provide copies of completed Incident Reports</li>
        <li>Contact crisis management support services, if applicable.</li>
      </ul>
      
      <p>If the situation is &quot;newsworthy&quot;, create a Public Relations/Media Plan.</p>
    </div>
  </div>

</main>`,undefined,`<main appHighlight [highlight]="searchTerm" >
	<div >
			
    <div >
      
      <h1 >Work-related Injury or Serious Illness of Paid Staff</h1>
      
      <p ><b>Notify Human Resources at Minneapolis Office of Praying Pelican Missions for immediate filing of reports with Workers’ Compensation Insurance. Claims will be filed by Human Resources.</b></p>
      <p >hr@prayingpelicanmissions.org</a></p>
      <p><a href="tel:9524058956" target="_blank">(952) 405-8956</a></p>
      
      <p ><b>Applicable only to PAID USA staff members while working in the USA, excluding Puerto Rico</b></p>
      <p >Carrier: Accident Fund</p>
      <p >Policy Number: 100026397</p>
      <p >https://www.accidentfund.com/ph/file-a-claim/</a></p>
      <p >(866) 206-5851</a></p>
      <p >ClaimsExpress@accidentfund.com</a></p>
      
      <p ><b>Applicable only to PAID USA staff members while working outside USA, including Puerto Rico</b></p>
      <p >Carrier: ACE American</p>
      <p >Policy Number: PHFD38222181006</p>
      <p >Contact: Marsh &amp; McLennan Agency</p>
      <p >(763) 746-8000</a></p>
      <p >www.MarshMMA.com</a></p>
      
      <p ><b>Agent:</b></p>
      <p >Marsh &amp; McLennan Agency, LLC</p>
      <p >6160 Golden Hills Drive<br>Minneapolis, MN 55416</p>
      <p >(763) 746-8000</a></p>
      <p >www.MarshMMA.com</a></p>
      
      <p ><i>Note: Provide Human Resources with all completed injury/illness reports and any physician statements.</i></p>
      
    
  </div>

</main>
`,`<main appHighlight [highlight]="searchTerm" >

  <h1>Incident Management Guidelines</h1>
  <p><strong>Safety should be foremost in the minds of our leaders and team members</strong>. This is without question
    our # 1 priority. Planning should be done to prepare safe and healthy environments, wherever we are working or
    serving. Even with the best plans in place, situations may occur that can put us in harm’s way. How we respond in
    these situations can mean the difference between a situation and a crisis.</p>
  <p>The intention of this document is to manage incidents before they become a potential crisis situation, or a greater
    crisis. We have confidence in you as a leader; reviewing this document and having it on hand at all times will be a
    critical tool for you.</p>
  <p><strong>The PPM Incident Management Team will be notified of any incidents that are considered emergency or could
      create a potential liability or crisis for PPM. Delay in notifying the Incident Management Team could seriously
      impact PPM’s options in handling the situation and averting a crisis.</strong></p>
  <p>Incident Reports must be completed for each reportable incident that occurs during the course of any trip.</p>
  
    The Affected Person (required)
    <li>Supervisor or Leader Reporting an Incident (required)</li>
    <li>Witness of an Incident (if applicable)</li>
    <li>Missing Person(s) Incident Report (if applicable)</li>
  
  <p>Incident Report Forms are available to you online through PPM’s Mission Control.</p>
  <ul>
    <li>www.ppm.org/missioncontrol
      <ul>
        <li>Enter your email address</li>
        <li>Enter your previously assigned password</li>
      </ul>
    </li>
    <li>Mission Control &gt; Trips &gt; Incidents</li>
    <li>Forms completed in Mission Control will automatically be sent to the Incident Management Team for review and
      appropriate follow-up.</li>
  </ul>
  <p><strong><em>It is imperative that the Incident Reports are completed within 24 hours of each incident in order to
        ensure appropriate documentation and follow-up, minimizing the risk and liability to all parties
        involved.</em></strong></p>

</main>`,`<main appHighlight [highlight]="searchTerm" >
  <h1>Incident Management Team &amp; Responsibilities</h1>
  <p>The PPM Incident Management Team will be notified of any incidents that are considered emergency or could create a
    potential liability or crisis for PPM. Delay in notifying the Incident Management Team could seriously impact PPM’s
    options in handling the situation or averting a crisis.</p>
  <p><strong>Situations may include, but are not limited to:</strong> serious injury, accident, natural disaster, fire,
    missing person, kidnapping, loss of life, robbery, abuse, etc.</p>
  <p>During PPM business hours (8:30am to 4pm CST M-F), call the PPM Minneapolis Office at 888-776-4090 or email to
    info@prayingpelicanmissions.org and you will be directed to an available Incident Management Team member. After
    hours and weekends, an Incident Management Team member should be contacted directly at the corresponding number
    below:</p>
  <p><strong>Members of the Incident Management Team are:</strong></p>
  <p>Jim Noreen (651) 216-8009</p>
  <p>Laura Yoch (651) 347-9336</p>
  <p>Therese Kaletka (612) 965-5471</p>
  <p>Jennie Pakan (651) 356-2269</p>
  <p>Paul Byrge (612) 709-2940</p>
  <p>Alissa Danielson (651) 999-9364</p>
  <p><strong>Incident Management Team Responsibilities</strong></p>
  <p><strong>Determine the level of potential liability to the organization.</strong></p>
  <p>Upon receiving notice of a potential or existing crisis or emergency situation, the Incident Management Team member
    will assess the situation to determine if the situation warrants an incident management plan to be established.</p>
  
    If it is determined further review of the situation is warranted, the Incident Management Team will conference
      to formulate a plan.
    <li>If not, completed incident reports from the persons involved with the actual incident will be collected and
      retained on file at the Minneapolis Office.</li>
  
  <p><strong>If potential liability is anticipated, the Incident Management Team will formulate an incident management
      plan, including activities as follows:</strong></p>
  <ul>
    <li>Notify the respective PPM Location Leader of the situation.</li>
    <li>Notify partnering ministry in location of service, if applicable.</li>
    <li>Establish a regular line of communication with the Trip Leader or Supervisor in charge where the incident
      occurred to keep abreast of the situation and provide guidance.</li>
    <li>Contact responding authorities or emergency services for updates, if applicable.</li>
    <li>Contact crisis management support services, if applicable.</li>
    <li>Determine if team member of PPM should travel to location of incident to assist.</li>
    <li>Notify legal counsel, if legal advice needed.
      <ul>
        <li>Determine name of legal counsel assigned through liability insurer.</li>
      </ul>
    </li>
    <li>Notify Board of Directors, if appropriate.</li>
    <li>Communicate situation with PPM staff or specific team members as appropriate.</li>
    <li>Notify applicable liability insurer.</li>
    <li>Document entire process along the way.</li>
  </ul>
  <p><strong>For both Domestic and International Claims:</strong></p>
  <p>Notify liability insurer Marsh &amp; McLennan Agency of situation at (763) 746-8000 and/or claims@marshmma.com.</p>
  <ul>
    <li>Provide copies of completed Incident Reports</li>
    <li>Contact crisis management support services, if applicable.</li>
  </ul>
  <p>If the situation is “newsworthy,” create a Public Relations/Media Plan.</p>
  <p>
</main>`,undefined,`<main appHighlight [highlight]="searchTerm" >
  <h1>Work-related Injury or Serious Illness of Paid Staff</h1>
  <p><strong>Notify Human Resources at Minneapolis Office of Praying Pelican Missions for immediate filing of reports with Workers’ Compensation Insurance. Claims will be filed by Human Resources.</strong></p>
  <p><a href="mailto:ppmhr@prayingpelicanmissions.org">ppmhr@prayingpelicanmissions.org</a></p>
  <p><a href="tel:8887764090">888-776-4090</a></p>
  <p><strong>Applicable only to PAID USA staff members while working in the USA, excluding Puerto Rico</strong></p>
  <p>Carrier: Accident Fund</p>
  <p>Policy Number: 100026397</p>
  <p>Claim Reporting Online: <a href="https://www.accidentfund.com/ph/file-a-claim/">https://www.accidentfund.com/ph/file-a-claim/</a></p>
  <p>Phone Number: <a href="tel:8662065851">(866) 206-5851</a></p>
  <p>Email: <a>ClaimsExpress@accidentfund.com</a></p>
  <p><strong>Applicable only to PAID USA staff members while working outside USA, including Puerto Rico</strong></p>
  <p>Carrier: ACE American</p>
  <p>Policy Number: PHFD38222181006</p>
  <p>Contact: Marsh &amp; McLennan Agency</p>
  <p>Phone Number: <a href="tel:7637468000">(763) 746-8000</a></p>
  <p><a href="http://www.MarshMMA.com">www.MarshMMA.com</a> </p>
  <p><strong>Agent:</strong></p>
  <p>Marsh &amp; McLennan Agency, LLC</p>
  <p>6160 Golden Hills Drive</p>
  <p>Minneapolis, MN 55416</p>
  <p><a href="tel:7637468000">(763) 746-8000</a></p>
  <p><a href="http://www.MarshMMA.com">http://www.MarshMMA.com</a></p>
  <p><em>Note: Provide Human Resources with all completed injury/illness reports and any physician statements.</em></p>
  <p>
</main>`,`<main appHighlight [highlight]="searchTerm" >
  <h1>Support Services for International Locations Only</h1>
  <p><strong> (includes Puerto Rico)</strong></p>
  <p><strong>Applies to the following individuals when working in an international location:</strong></p>
  
    PPM year-round staff who reside in the U.S.
    <li>PPM international year-round staff</li>
    <li>PPM seasonal staff (paid or volunteer) who live or reside in the U.S.</li>
  
  <p><strong>Executive Assistance Services</strong></p>
  <p>Provides covered individuals with online pre-trip security and travel information and - while they are traveling -
    with 24-hour access to global providers of emergency medical, personal, legal and travel services; emergency medical
    and political evacuation or repatriation; and concierge services.</p>
  <p><strong>When to use Executive Assistance Live Services:</strong></p>
  <p>While traveling or working outside your home country</p>
  <ul>
    <li>Emergency medical or political evacuation</li>
    <li>Referral to doctor or hospital</li>
    <li>Hospital admission deposit</li>
    <li>Are hospitalized</li>
    <li>Emergency prescription medication replacement</li>
    <li>Emergency travel arrangements, return of traveling companion/dependents and vehicle return</li>
    <li>Language assistance or translation services</li>
    <li>Legal referral</li>
    <li>Critical travel document replacement (passport, credit card)</li>
    <li>Emergency cash advance</li>
    <li>Embassy or consulate contact information</li>
    <li>Concierge services</li>
  </ul>
  <p>Before you travel</p>
  <ul>
    <li>Medical assistance and travel medical emergency services</li>
    <li>Personal and pre-trip services</li>
    <li>Legal assistance</li>
    <li>Emergency cash (from personal funding source)</li>
    <li>Lost baggage or passport assistance</li>
    <li>Insurance coordination</li>
    <li>Evacuation and repatriation</li>
    <li>Emergency message center</li>
    <li>Other general assistance</li>
  </ul>
  <p><strong><em>When traveling or temporarily assigned outside your home country, call Europ Assistance USA - 24 hours
        a day worldwide</em></strong>. </p>
  <p>IDD + (800) 0200-8888 toll free outside U.S. and Canada</p>
  <p>1 + (202) 659-7777 collect outside U.S and Canada</p>
  <p>IDD + 1 + (202) 659-7777 direct dial outside U.S. and Canada</p>
  <p>1 + (800) 766-8206 U.S. and Canada</p>
  <p><strong>For Medical Emergencies have the following:</strong></p>
  <ol>
    <li>Name of caller, phone/fax number, relationship to patient</li>
    <li>Patient’s name, age, sex</li>
    <li>Description of patient’s condition</li>
    <li>Name, location and phone number of hospital</li>
    <li>Name and phone number of treating doctor; where and when treating doctor can be reached</li>
    <li>Health insurance, workers’ compensation and auto insurance information (if involved in an auto accident)</li>
    <li>Policy plan number (see below)</li>
    <li>Name of insured</li>
  </ol>
  <p><strong>For Political Emergencies have the following:</strong></p>
  <ol>
    <li>Name of caller, phone/fax number, relationship to evacuee(s)</li>
    <li>Evacuee(s) name, age, sex</li>
    <li>Description of political emergency and reason for evacuation</li>
    <li>Name, location and phone number where evacuee(s) can be reached</li>
    <li>Policy plan number (see below)</li>
    <li>Name of insured</li>
  </ol>
  <p><strong>ACE American Account Number: PHFD38222181006</strong></p>
  <p><strong>Executive Assistance Services Plan Number: 01 SP 585</strong></p>
  <p><em>An information sheet with the Executive Assistance Services details is available from Human Resources for
      purposes of folding down and carrying in your wallet/pouch/pack.</em></p>

</main>`,undefined,`<main appHighlight [highlight]="searchTerm" >
  <h1>Ministry Skills Training</h1>
  <p>The opportunity we have to be able to offer training tools to participants to use during the week long mission trip and take home with them for continued refining and use is unique in that they will be able to use these skills and practice them in a place where the mindset of ministry is priority. Once they use these tools here, they are more likely to put them into practice at home! So, whether this is time set aside on day one or a preparation for a planned ministry, use these ministry skills training guides to help your teams serve well.</p>
  <h2>Training Outlines</h2>
  
    Life Story Training, Relational Training, Ice Breakers, The Gospel in One Verse, Prayer Walking
  
  <h2>Best Practices</h2>
  <ul>
    <li><strong>Train your staff to train your team:</strong> Use some time in your staff meetings to review one of these training guides during the week. Have other staff members lead the training.</li>
    <li><strong>Be Prepared:</strong> When there is some down time, connect with your GL to see if a training time would be a good alternative and which training you would like to offer. This could happen with a weather delay or waiting for another team. </li>
    <li><strong>This tool will stay with them forever:</strong> This is a great time for participants to learn something new, try something they aren’t necessarily comfortable with, and practice in a safe environment. </li>
    <li><strong>Pray through the training:</strong> God can use these types of training in powerful ways. Pray for those going through it and watch for those who may have been stretched or impacted in a deeper way.</li>
    <li><strong>Allow for Time:</strong> Depending on the involvement of each of the individuals in these groups, the training could take as little as 20 minutes or as much as 2 hours. Plan it out, but be sensitive to how the group is responding and how God is moving.</li>
  </ul>
  <h2>Life Story Training</h2>
  <p><strong>Purpose:</strong> To build community in the group through the use of telling our life experiences that can also be a tool for use in the mission field.</p>
  <p><strong>Key Scripture:</strong> Hebrews 10:24-25 “And let us consider how we may spur one another on toward love and good deeds, not giving up meeting together, as some are in the habit of doing, but encouraging one another—and all the more as you see the Day approaching.”</p>
  <p><strong>Introduction:</strong> Sharing a life story is a tool that can be used across cultures by everyone. We have all lived our own lives and have experiences that are ours alone. We are the experts on our own lives. We’ll have the opportunity in a little while to get together in groups of four to five people and share our life story. We will each have seven minutes of sharing time. And for some reason if you finish before the seven minutes are up, those minutes still belong to you, so the group can ask you questions about what you’ve said. </p>
  <p><strong>Instructions:</strong></p>
  <ul>
    <li><strong>Example:</strong> Trip Leader or Assistant Leader gives their life story. The other person should do timing with a three-minute, one-minute warning and call time discreetly when the seven minutes is up. Sharing your story will give people an idea what they will be doing and time to think about their story. You will model wrapping up your story and using the full seven minutes. Ask for the person timing you to also model giving a brief affirmation of one or two words about you.</li>
    <li><strong>Work Time:</strong> Ask questions like “what can be included in a life story? Childhood, high school, adult years? Events, people, experiences, challenges, family faith journey?” Then give them seven minutes to write down some notes to share about as they think through their story.</li>
    <li><strong>Give Stories and Affirmations:</strong> Get in groups of four to five and share life stories in the room where everyone is gathered. Use a central timer and be clear to keep the groups on track allowing all people to share. As soon as one person is done sharing their story, each of the other people in the group will share a word or two of what they appreciate or qualities they see in that person. Give a full minute for affirmations before the next person in the group shares their life story until everyone has a turn. </li>
  </ul>
  <p><strong>Review:</strong> Ask, “Why did we just do this? What was challenging and what was easy? How can this be used in our lives back home? How can this be used on a short-term mission trip?”</p>
  <p><strong>Conclusion:</strong> You have just had the opportunity to get to know some people in the group better and added another tool to be used back home and on a mission trip. We encourage you to ask at least one person about their life this week.</p>
  <h2>Relational Training</h2>
  <p><strong>Purpose</strong>: To build community within the group and to give each person a tool for their ministry toolbox. This should enhance everyone’s experience on the mission trip and empower them for relational ministry any time.</p>
  <p><strong>Main Teaching Points</strong></p>
  <ul>
    <li>Meaningful, lasting relationships are formed when we reveal who we are to others and they reveal who they are to us.</li>
    <li>It’s easy to build a conversation if we use the conversational areas.</li>
    <li>People will reveal who they are if we are genuinely interested, ask good questions, and are a concerned and interested listener.</li>
  </ul>
  <p><strong>Introduction </strong>(4 minutes)</p>
  <p>One of our objectives on this mission trip is to build relationships with the locals here as well as the people in our group. We believe that true ministry happens through relationships. So knowing how to intentionally build relationships is a key for short term mission work and our lives in general.</p>
  <p>Use a brief Illustration that makes the point that meaningful, lasting relationships are formed when we reveal ourselves to others and they reveal themselves to us.</p>
  <p>Your Example: </p>
  <p>How do we get others to reveal who they are?  (Rhetorical)</p>
  <p>Now we’re going to work on 2 principles for building meaningful, lasting relationships.</p>
  <p><strong>Principle #1 – Be a Good Listener </strong>(6 minutes)</p>
  <ol>
    <li>Maybe you’ve been involved in a conversation or seen/heard one like this –</li>
  </ol>
  <p>Lots of talking, looking at your watch, self-interested, distracted, interrupting, close ended questions, etc.</p>
  <ol>
    <li>Demonstrate with a leader in the group being a bad listener.</li>
    <li>What was I doing wrong?  Get group members to respond.</li>
    <li>Charles Stanley said “God gave us 2 ears and 1 mouth so you think we’d get the point.”</li>
    <li>Being a good listener means loving them.  We spell love LVV</li>
  </ol>
  <p>Look at them </p>
  <p>Visual Approval (posture toward them, eye-contact) and </p>
  <p>Verbal Approval (to show we are listening like “oh?” “wow” “okay”)</p>
  <p>Bridge to point #2: “Even though we may be good listeners, there is more to being a good conversationalist than being a good listener. Raise your hand if you have ever met someone you wanted to get to know, but you didn’t know what to say to get the conversation going?…I can teach you something in the next hour that will allow you to always have something to talk about, so you never have that problem again. Are you interested?” </p>
  <p><strong>Principle #2 – Build Conversation Around Conversational Areas</strong> <em>(50 minutes)</em></p>
  <p>Introduce conversation stack. Teach the objects one at a time and the conversational areas, framing the shape of the objects with your hands to help give an illustration. Note: No writing! In order to have this ministry tool accessible, they memorize it in this short session. by "picture in their mind's eye" instead. As you help them visualize each object, it’s helpful to connect each of the items clearly -it's the odd connection to that next image that makes it memorable, so only a few repetitions of the image stack is needed.</p>
  <p><u>Step 1 – Teach The Objects</u></p>
  <p>1) nameplate </p>
  <p>2) house </p>
  <p>3) people </p>
  <p>4) work glove </p>
  <p>5) airplane </p>
  <p>6) tennis racket </p>
  <p><em>PIP #1</em> – Practice with partners for 1 minute, having each person repeat the objects. Introduce timing with a 10 second warning. </p>
  <p><u>Step 2 – Add The Meanings To The Objects</u></p>
  <p>1) nameplate			name</p>
  <p>2) house			where they live</p>
  <p>3) people			family and friends</p>
  <p>4) work glove			work, school, or how you spend your time</p>
  <p>5) airplane			travel</p>
  <p>6) tennis racket			sports, hobbies, interests</p>
  <p><em>PIP #2</em> – Practice in partners for 2 minutes.  Each person share the object and meaning, then switch roles.</p>
  <p><u>Step 3 – Teach Additional Objects</u></p>
  <p>7) light bulb </p>
  <p>8) PFC Soldier </p>
  <p>9) goal post </p>
  <p><u>Step 4 – Add Meanings To Objects</u></p>
  <p>7) light bulb			ideas</p>
  <p>8) PFC Soldier			problems, frustrations, concerns or challenges</p>
  <p>9) goal post			goals/dreams</p>
  <p><u>Step 5 –  Go Through All Nine Objects And Their Meanings</u></p>
  <p>1) nameplate			name</p>
  <p>2) house			where they live</p>
  <p>3) people			family and friends</p>
  <p>4) work glove			work, school, or how you spend your time</p>
  <p>5) airplane			travel</p>
  <p>6) tennis racket			sports, hobbies, interests</p>
  <p>7) light bulb			ideas</p>
  <p>8) PFC Soldier			problems, frustrations, concerns or challenges</p>
  <p>9) goal post			goals/dream</p>
  <p>Model a 2-minute interview with the same person you used before while demonstrating good listening skills. Make sure to go through all 9 areas. </p>
  <p>Review What are we practicing?</p>
  <li> Being a good listener   LVV</li>
  <li> Asking questions and building conversation around areas 1-9</li>
  <p><em>PIP #3 –</em> Change partners and use again for 3 minutes. Remind class members this is a real conversation even though we are practicing principles for good conversations. Switch roles.</p>
  <p>Add this area:</p>
  <p>10) praying hands		pray with them</p>
  <p><em>PIP #4 -</em> 3-minute conversation using all 10 areas. Pray for the person at the end. Close the conversation with a 10-second prayer.  Switch roles.</p>
  <p><strong>Conclusion </strong>(10 minutes)</p>
  <p>What did you enjoy about doing this?</p>
  <p>What did you learn about yourself?</p>
  <p>How does this apply to our lives back home and does this apply to this week?</p>
  <p>GREAT JOB!  We encourage you to put this tool in your ministry toolbox and bring it out and use it this week.  Make it your own and practice being a good listener and practicing good conversations.</p>
  <p>Credit Tentmakers with the materials and let class members know that this is just a sliver of the type of training people receive when they go through Tentmakers training.  © Tentmakers 2002. Used with permission.</p>
  <h2>Icebreakers</h2>
  <p><strong>Purpose:</strong> To help a team or teams that may not know each other to become more comfortable with one another and to use in a ministry setting such as a youth event.</p>
  <p><strong>Key Scripture:</strong> Proverbs 17:22a  “A cheerful heart is good medicine.” </p>
  <p><strong>Introduction:</strong> Sometimes the first few minutes or days on a trip can feel awkward. Since we are together for such a short time, it’s important that we get to know and feel comfortable with each other quickly. </p>
  <p><strong>Instructions:</strong> Choose one of the following Icebreakers to use with their team. </p>
  <ul>
    <li><strong>Draw your Coat of Arms: </strong>Need: paper/pens or crayons. Have the team get into groups of four. Each person thinks of a thing that could easily represent them. As a group, combine those 4 things into one coat of arms. Draw it as best as you can and share with the large group after all the groups are finished. You never know what each group will come up with!</li>
    <li><strong>Line up:</strong> The group's goal is to work together to arrange themselves into a line according to predetermined criteria. Start with some to practice verbal communication: arrange in alphabetical order by first name, last name, and in numerical order by age. Then move to nonverbal communication (they cannot speak): arrange by height, eye color, shirt color from lightest to darkest. </li>
    <li><strong>Name Game:</strong> Have the group sit in a circle where everyone can see the others. The first person says their name. The next person continues, but after saying their own name, they repeat the first person’s name. This continues with each person repeating one more name. Reassure people towards the end that it’s ok if they get stuck &amp; encourage the others to jump in to help if anyone is lost.</li>
    <li><strong>No Smiling</strong>: Instruct everyone to keep a straight face and do not smile under ANY circumstance in the first five minutes of the meeting. People turn into children with an instruction like this, and immediately start looking at others, seeing how they cope. The anticipation makes everyone giggly, so after a while they cannot suppress their laughter anymore. </li>
    <li><strong>Toilet paper: </strong>Need: a roll of toilet paper. Pass the toilet paper asking and instruct everyone to take as much as they think they'll need. Once everyone has done toilet paper, inform the group that for each square they will have to share one thing about themselves. Start with yourself then move around the circle until everyone has shared.</li>
    <li><strong>Unique and Shared:</strong> In groups of 4-5 people, let each group discover what they have in common, along with interesting characteristics that are unique to a person in the group. This icebreaker promotes unity as it gets people to realize that they have more common ground with their peers than they first might realize. Take a few minutes to share as a large group.</li>
    <li><strong>Whose Story is it? </strong>Need: paper, pens, a bowl. Instruct the group to write their funniest or weirdest story on a small piece of paper. Then fold the paper up and drop it into a bowl. The facilitator or the person leading the program randomly reads every story &amp; the group has to guess who the writer is.</li>
  </ul>
  <h2>Prayer Walking Training</h2>
  <p><strong>Purpose:</strong> To lead team members on a guided prayer journey through the community.</p>
  <p><strong>Key Scripture:</strong> Ephesians 6:18 “And pray in the Spirit on all occasions with all kinds of prayers and requests. With this in mind, be alert and always keep on praying for all the Lord’s people.”</p>
  <p><strong>Introduction:</strong> As Christians, prayer should be an essential part of our lives. It doesn’t need to be filled with fancy words, all God requires is you. He already knows what’s going on in your life, but He wants you to invite Him into your life and to allow Him to enter the conversation. Sometimes, prayer can be hard because we don’t know how to “pray correctly” or it can feel uncomfortable. The best way to become comfortable with anything is through practice. You were placed here, in this community, for a purpose. God brought you here for a reason. There are people here that the only way to help them is through prayer. </p>
  <p><strong>Instructions:</strong></p>
  <ul>
    <li><strong>Gather and explain the prayer walking plan: </strong>“This tool can be used as a pre-evangelism exercise, can increase our awareness of the need and the layout of the community, can create change in the spiritual climate, and can open doors for ministry opportunities.”</li>
    <li><strong>Break the team into groups of three or four and give them slightly different directions to walk.</strong> “<em>The goal is to pray as we are walking. Pray as if you are in a conversation with each other and God. Pray for things you notice. Pray for people you pass. Pray for the homes you see and pass by. Pray intentionally for blessings on the community, the churches, the leaders, softening of hearts, protection from sin, revival, strongholds in peoples’ lives and anything else that comes to mind. If God leads you to stop and talk to people or pray longer in one place–do it, but just keep praying and walking.”</em></li>
    <li><strong>Pray over the entire team and send them out for 30-60 minutes.</strong></li>
  </ul>
  <p><strong>Review:</strong> As the team starts coming back together, encourage them to continue praying and pray as a large group until everyone returns. Close the prayer time and ask a few questions to debrief the prayer walking time: <em> “What was the purpose of this prayer walk? How do you think you can continue this at home? What did you learn from this time of prayer and reflection? Do you think you accomplished anything?”</em></p>
  <p><strong>Conclusion:</strong> 1 Thessalonians 5:17 says “Pray without ceasing” and today we practiced that. We can have a conversation with God wherever we are and can pray for things as they come to our attention. The prayers don’t have to be long, but we can keep that conversation with God going and He loves to hear from us!</p>
  <h2>The Gospel in One Verse</h2>
  <p><strong>Purpose:</strong> To equip team members with a tool to share the Gospel with others.</p>
  <p><strong>Key Scripture:</strong> Romans 6:23 (NIV) “For the wages of sin is death, but the gift of God is eternal life in Christ Jesus our Lord.” </p>
  <p><strong>Supplies Needed:</strong> Bible, paper, pen</p>
  <p><strong>Introduction:</strong> Sharing the Gospel can seem intimidating, but knowing how to walk through the simple truth and how to explain it to someone else can help a lot. This is one way to present the Gospel with others.</p>
  <p><strong>Instructions:</strong></p>
  <ul>
    <li>Read Romans 6:23</li>
    <li>Write out and box in the following words taking time to talk through each one:
      <ul>
        <li><strong>WAGES:</strong> How would you define wages? Payment for work done or the reward we receive for what we have done. It is getting what we deserve.</li>
        <li><strong>SIN:</strong> What is “sin”? Sin is not as much an action as it is an attitude. It is often an attitude that is hostile to or ignores God.</li>
        <li><strong>DEATH:</strong> What do you think of when you think of death?</li>
        <li><strong>BUT:</strong> How does the word “but” change the sentence? “But” is the most important word in the verse because it indicates hope.</li>
        <li><strong>GIFT:</strong> How would you describe the difference between wages and a gift? A gift is not earned or worked for; it was purchased for us by someone else.</li>
        <li><strong>OF GOD:</strong> The verse indicates that there is a gift offered by God. No one else can give you this gift. Why does anyone want to give a precious gift to someone else?</li>
        <li><strong>ETERNAL LIFE:</strong> In your thinking, what is eternal life? Eternal life is the opposite of death. Rather than separation from God as in death, eternal life is harmony with God, a relationship with Him forever.</li>
      </ul>
    </li>
  </ul>
  <p><strong>Review:</strong> Get into groups of 4 and talk through Romans 3:23. See if you can add anything to what has already been said. Which word made you think the most? </p>
  <p><strong>Conclusion:</strong> The Gospel is the most important thing we can ever share with anybody. Romans 3:23 is absolutely true and knowing how you can share the Truth about this wonderful gift can empower you. Know that your job is not to save people, but it is to plant seeds around you. </p>
  
</main>`,undefined,undefined,undefined,undefined,undefined,undefined,`<main appHighlight [highlight]="searchTerm" >
<h1>More Ministry Skills Training</h1>
  <h2>The Canyon and the Cross</h2>
  <p><strong>Purpose:</strong> To equip team members with a tool to share the Gospel with others.</p>
  <p><strong>Key Scripture:</strong> John 3:16-17 “For God so loved the world that he gave his one and only Son, that whoever believes in him shall not perish but have eternal life. For God did not send his Son into the world to condemn the world, but to save the world through him.”</p>
  <p><strong>Introduction:</strong> Sharing the Gospel can seem intimidating, but knowing how to walk through the simple truth and how to explain it to someone else can help a lot. This is one way to present the Gospel with others.</p>
  <p><strong>Supplies Needed:</strong> A Bible, paper, pen</p>
  <p><strong>Instructions:</strong></p>
  
    Draw two cliffs, one on each side of the paper.<em> “The Grand Canyon is one mile deep and three miles across. It’s like the chasm that we drew, which separates us from God. It’s impossible for us to cross the chasm on our own! The good news is that God has provided a way across to Him.”</em>
    <li>Connect the cliffs by drawing a cross between them. Write and box in CHRIST JESUS. <em>“Jesus is the means by which we can cross the chasm and obtain the gift of eternal life. He says, ‘I am the way and the truth and the life. No one comes to the Father except through me.’ in John 14:6”</em></li>
    <li>To accept God’s gift to you means to recognize Jesus as Lord. Box and write in LORD. “<em>In recognizing Jesus Christ as our Lord and Savior, we acknowledge two things:”</em>
      <ul>
        <li>Write 1. CONFESS—”<em>Confessing means admitting that we are sinful and that we are wrong at times in our actions and attitudes—and then asking Jesus to forgive us.”</em></li>
        <li>Write 2. SURRENDER—”<em>To surrender means to put our lives under Jesus Christ’s authority. Our relationship with Jesus gives our lives new meaning and purpose. We surrender our lives to His leadership and conviction.”</em></li>
      
    </li>
    <li>On the left side of the bridge, draw a stickman figure with an arrow across the bridge. <em>“When a person acknowledges Jesus as Lord and Savior, he/she has crossed the bridge and has begun a relationship with God.”</em></li>
  </ul>
  <p><strong>Review:</strong> Find a partner and see if you can re-create what I just explained and drew. </p>
  <p><strong>Conclusion:</strong> God’s Spirit is the power to open a person’s heart to His salvation, not our polished presentation or illustration. It’s our privilege to be a partner with God in this process. As you prayerfully enter into conversations with others, God may guide the conversation in a way that will allow you to use this tool.</p>
  <p><strong>ACTS Prayer Method</strong></p>
  <p><strong>Purpose:</strong> To provide the team with a tool they can use now and in the future to walk through these priorities and purposes of prayer using the Biblical example that Jesus gave us.</p>
  <p><strong>Key Scripture:</strong> Luke 11:1-4 “‘Lord, teach us to pray, just as John taught his disciples.’ He said to them, ‘When you pray, say: ‘Father, hallowed be your name, your kingdom come. Give us each day our daily bread. Forgive us our sins, for we also forgive everyone who sins against us. And lead us not into temptation.’”</p>
  <p><strong>Introduction:</strong> What types of words come to mind when you think of prayer? (Give the team time to answer out loud) Prayer is a conversation; both parties need to have a chance to speak. We are going to enter into a time of worship and prayer. Specifically, we’ll be going through ACTS: a specific pattern of prayer: Adoration, Confession, Thanksgiving, and Supplication.</p>
  <p><strong>Supplies Needed:</strong> Paper/Journal, pen, and Bible</p>
  <p><strong>Instructions:</strong> Prepare the team to intentionally pray. Then begin and work through the pattern as a group. (Optional: this can be made into a “Concert of Prayer” with songs chosen along each theme and played/sang during the pattern with prayer dispersed throughout.)</p>
  <ul>
    <li>For <strong>Adoration,</strong> call out the different names of God. Any at all; say them out loud as they come to you. <em>Allow a couple of minutes for this. Pray (theme of adoration) to close this time</em>.</li>
    <li>For<strong> Confession,</strong> we are going to silently come before God to confess the sins in our life and to ask for forgiveness. Believe that when God gives you this forgiveness, the burden is lifted and we can live in His grace. <em>Give two minutes for the team to be in silent confession. Pray (theme of confession) to close this time</em>.</li>
    <li>For <strong>Thanksgiving</strong>, we are going to say, out loud, what we are specifically thankful for. <em>Allow a couple of minutes of this. Pray (theme of thanksgiving) to close this time</em>.</li>
    <li>For <strong>Supplication,</strong> we are going to pray for needs that are around us. We can pray for healing, for the community, for revival, for each other. Ask for requests and have the team begin praying. <em>After a few minutes, close the time with a general prayer of supplication or lift up requests that have been mentioned throughout the day/week.</em></li>
  </ul>
  <p><strong>Review:</strong> How can you use this tool in your life? In the Lord’s Prayer, do you see these elements? (Adoration: Father, hallowed be your name; Confession: forgive us our sins; Thanksgiving: this one is tougher–the daily bread portion is a remembrance of the manna God gave the Israelites in the desert as well as a request to continue that provision; Supplication: Give us this day our daily bread, lead us not into temptation, forgive us our sins)</p>
  <p><strong>Conclusion:</strong> Take this example of a pattern of prayer and set a goal to use it or another pattern you already have in place a couple times a week or even daily. Make prayer a regular part of your life knowing that it doesn’t have to be complicated. </p>
  <h2>Healing Conversations</h2>
  <p><strong>Purpose:</strong> To equip teams serving to be able to allow healing conversations to happen through learning the art of asking powerful questions and listening well. </p>
  <p><strong>Key Scripture:</strong> Romans 12:15 “Rejoice with those who rejoice, weep with those who weep.”</p>
  <p><strong>Introduction:</strong> Think of an event in your life when everything changed. Maybe it was a natural disaster, a move to a new school or church, the loss of a loved one. You may have felt helpless or hopeless. People we will encounter this week may have faced an event like that. When people are feeling helpless, hopeless, or powerless, we can give them a voice, enabling them to move from “victim” to “survivor.” This is not replacing professional counseling or therapy if that is needed. It is simply asking powerful questions and listening well.</p>
  <p><strong>Instructions:</strong> Processing a crisis happens over time. Today, we are going to talk through some powerful questions and keys to listening well, then we are going to practice with a partner. </p>
  <ul>
    <li><strong>Powerful Questions 101:</strong> Powerful questions will help cycle through facts, thoughts, and feelings. Here are some helpful hints: 1. Invite conversation by using open-ended questions; 2. Offer a starting place– “What have the last few weeks been like for you?”; 3. Clarify details–Who was with you when…”; 4. Help to dig deeper, “What has been the hardest part of this crisis?”; 5. Give opportunities to express feelings–”what emotions do you see on others’ faces?” 6. Find hope–”What good can you see in this hard time?”</li>
    <li><strong>Explain the basic “keys” to listening well:</strong> 1. Get curious–try to understand their experience; 2-SLOW down–put aside other distractions; 3. Pay Attention–watch and listen to the speaker; 4. Show you are listening–respond with nods and facial expressions; 5. Provide Feedback–ask clarifying questions and rephrase what you heard back to them; 6. Allow silence–even if it feels awkward; 7. Don’t interrupt; 8. Respond with respect–acknowledge that there is no wrong way to feel and share thoughts and opinions only when asked. 9. Never try to fix it or say “I understand.”</li>
    <li><strong>Practice:</strong> We are all going to practice listening to our peers as they process something that has changed the world: COVID-19. The youngest of the two gets to listen first. Typically, we don’t want to put a time limit on these kinds of conversations, but, for today, we are going to give you each 10 minutes.</li>
  </ul>
  <p><strong>Review:</strong> Was there anything that stood out to you when you were practicing asking questions and listening? Was it hard to not interrupt and share your experiences? How can you use this tool this week? Can you use this tool at home?</p>
  <p><strong>Conclusion:</strong> We live in a broken world where crisis exists. These experiences affect us and we need to process them. We can use these tools for ourselves, our families, and for those we encounter. God has given us such a great hope, let’s use these opportunities to be a blessing to others and help them process hard times.</p>
  <h2>S.O.A.P. Bible Study Method</h2>
  <p><strong>Purpose</strong>: To help teams continue to grow in their faith and give them confidence to be able to study the Bible on their own and lead a Bible Study. </p>
  <p><strong>Key Scripture</strong>: 2 Tim 3: 16-17 “All Scripture is breathed out by God and profitable for teaching, for reproof, for correction, and for training in righteousness, that the man of God may be complete, equipped for every good work.”</p>
  <p><strong>Introduction:</strong> We have all heard that we need to read our Bibles and that’s good, but there is something special about going beyond simply reading the Word and digging deeper into what God has said to us in the Bible. The big question is “How do I study my Bible?” There are so many Bible Study Methods available and today we are going to learn one of them. This one is called the SOAP Bible Study Method.</p>
  <p><strong>Supplies Needed:</strong> Bible, paper or journal, and pen</p>
  <p><strong>Instructions:</strong></p>
  <ul>
    <li>Explain the basics of this method using the letters S.O.A.P.
      <ul>
        <li><strong>S-Scripture:</strong> Write out the Scripture passage you are studying.</li>
        <li><strong>O-Observation:</strong> Answer these questions: What do you see? Who is the audience? Is there any repetition? What word(s) stand out to you?</li>
        <li><strong>A-Application:</strong> Answer the following questions: What is God saying to me? How can I apply this in my own life? What changes do I need to make? What action should I take?</li>
        <li><strong>P-Prayer:</strong> Pray God’s word back to Him. If He revealed something to you, pray about it. Ask Him for strength to make the changes or take the action.</li>
      </ul>
    </li>
    <li>Practice: You can choose to have the team do the practice as a group or quietly as individuals. Walk the team through one letter at a time using Ephesians 4:1-3.
      <ul>
        <li>S-Give a few minutes to have everyone write the Scripture down.</li>
        <li>O-Ask the observation questions above. Have them write down the answers.</li>
        <li>A-Use the questions listed above and give a few minutes to answer.</li>
        <li>P-Take some time to have the individuals pray through what they just learned.</li>
      </ul>
    </li>
  </ul>
  <p><strong>Review</strong>: <em>If you had the team do the practice section individually, give them some time to share as a group some of the observations and applications that stood out to them.</em> Review why studying Scripture is important. Ask: “How can you use this tool in your daily life? How can you use it with your youth group? How can you use it this week?” </p>
  <p><strong>Conclusion:</strong> This tool may work for you as you study your Bible, but there are many other methods that are available for you to choose from. The point is that we need to study our Bibles and we can use this method to help others learn to study their Bibles as well.</p>
  <p><em>Concepts for this training tool were taken from online sources: <a href="https://lovegodgreatly.com/how-to-soap">https://lovegodgreatly.com/how-to-soap</a></em></p>
  <h3>Relational Treasure Hunt</h3>
  <p><strong>Purpose:</strong> To give the team a new perspective on how God answers prayers, even in the smallest things.</p>
  <p><strong>Key Scripture:</strong> Ephesians 2:10 “For we are God’s masterpiece. He has created us anew in Christ Jesus, so we can do the good things he planned for us long ago.”</p>
  <p><strong>Introduction:</strong> I want to ask you three questions. Silently, answer to yourself: Do you believe God listens at all times? Do you believe you’ve heard God speak to you before? Do you believe God answers every prayer?<em> For question 1, affirm that God always listens. Pray silently as you assess their answers to question 2; they are valid being yes or no. Give hope that perhaps we need to listen through His Word, His people, and our silence; perhaps we need to be open to answers looking “different” than what we want. In question 3, you get the opportunity to speak into the “answer process.” Yes, No, and Waiting are all answers.</em></p>
  <p><strong>Supplies Needed:</strong> Paper, pen, record keeper</p>
  <p><strong>Instructions:</strong></p>
  <ul>
    <li>Before we start, we’re going to be still and be in God’s presence (Review the ACTS pattern of prayer concept here). I want you to close your eyes. And as you close your eyes, allow God to share an object with you, in your mind. It can be any object. It can have color to it. It can be big or small. I’m going to allow a minute for you to be in God’s presence and for this object to be shared with you.</li>
    <li>Now the record keeper is going to collect your name and corresponding objects. (<em>Go around the room, having each person share.</em>)</li>
    <li>You have all identified your treasure for the week! And we are going to be treasure hunters! We are about to venture out into this community. The image that God has shared with you is special. It’s only for you. It’s your treasure.</li>
    <li>As we serve this week, we are going to pray for what we see and for what God lays on our hearts. As you pray for and talk with the person connected to your “treasure” you will get to hear part of their story or share part of yours. You can start by saying, “God wanted me to talk with you! He put (object) on my heart earlier in the week, and you happen to (be holding it, have it  on your shirt, etc). You are my treasure!” From there, have a conversation with this person and pray for them.</li>
  </ul>
  <p><strong>Review:</strong> Share about a time when you found your “treasure” or use these examples: <em>Your object is a flamingo; a girl shows up to VBS with a flamingo on her shirt. Pray for her and her family. OR Your object is a rainbow; there is a restaurant called the “Rainbow Cafe” you come across. Go in and find a staff member or patron to talk and pray with.</em></p>
  <p><strong>Conclusion:</strong> You never know how God will show up. Later this week, we will take some time to share about how God used this “Treasure Hunt” this week.</p>
</main>`,];