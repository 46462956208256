import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type MenuNotifications = "pwaUpdateAvailable";

@Injectable({
  providedIn: 'root'
})
export class MenuNotificationService {

  notifications$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private notifications: string[] = [];


  constructor() { }

  addNotification(key: MenuNotifications) {
    if (!this.notifications.find(n => n === key)) {
      this.notifications = [...this.notifications, key];
      if (this.notifications.length === 1) {
        this.notifications$.next(true);
      }
    }
  }

  removeNotification(key: MenuNotifications) {
    const index = this.notifications.findIndex(n => n === key);
    if (index > -1) {
      this.notifications.splice(index, 1);
      if (this.notifications.length === 0 ) {
        this.notifications$.next(false);
      }
    }
  }
}
