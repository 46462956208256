/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    HttpRequest,
    HttpErrorResponse,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, from } from 'rxjs';
import { catchError, concatMap, switchMap, tap } from 'rxjs/operators';
import { ConfigService } from '../config.service';
import { AuthService } from './auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private router: Router,
        public auth: AuthService,
        private configService: ConfigService
    ) { }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        if (request.url.match(`${this.configService.dataApiUrl}`)) {
            return from(this.auth.getAuthToken()).pipe(
                switchMap((token) => {
                    const clonedRequest = this.addTokenHeader(request, token);

                    return next.handle(clonedRequest).pipe(catchError(error => {
                        if (error instanceof HttpErrorResponse &&
                            !clonedRequest.url.includes("refresh") &&
                            !clonedRequest.url.includes("google-oauth") &&
                            error.status === 401) {

                            return this.handle401Error(clonedRequest, next);
                        }
                        return throwError(() => error);
                    }));
                })
            );

        }
        else {
            return next.handle(request);
        }

    }

    private addTokenHeader(request: HttpRequest<any>, token: string) {
        if (this.configService.staffApiActive) {
            return request.clone({
                setHeaders: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'mcapi-key': "PLTxAQ5jS7qTSdKQf71nUCs4JKzeFNRVeqbKrQ8EFV1ESGTIi9ch6rZAW3XI0Nea"
                },
            });
        }
        else {
            return request.clone({
                setHeaders: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
        }
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {

            this.refreshTokenSubject.next(null);

            return this.auth.loggedIn().pipe(switchMap(loggedIn => {
                if (!loggedIn) {
                    return this.refreshFailure("Not logged in.  Cannot refresh");
                } else {
                    this.isRefreshing = true;
                    return this.auth.refreshLogin().pipe(
                            switchMap((token) => {
                                this.auth.setToken(token);
                                this.isRefreshing = false;
                                this.refreshTokenSubject.next(token.accessToken);

                                return next.handle(this.addTokenHeader(request, token.accessToken));
                            }),
                            catchError((err) => {
                                this.isRefreshing = false;
                               return this.refreshFailure(err);
                            })
                        );
                }

            }));
        }
        else {
            return this.waitForRefresh(request, next);
        }
    }

    private waitForRefresh(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.refreshTokenSubject.pipe(switchMap((token) => {
            if (token) {
                return next.handle(this.addTokenHeader(req, token));
            }
            else {
                return this.waitForRefresh(req, next);
            }
        }));
    }

    private refreshFailure(err: any): Observable<never> {
        this.isRefreshing = false;
        this.auth.clearToken();
        this.router.navigate(['/login'], { queryParams: { session: 'expired' } });
        console.log(err);
        return throwError(() => new Error("Token refresh failed"));
    }
}
