import { Injectable } from '@angular/core';
import Index from 'flexsearch/dist/module/index';

@Injectable({
  providedIn: 'root'
})
export class ContentIndexService {

  index: Index;

  constructor() { }


  loadIndex(pages: string[]) {
    this.index = new Index({language: "en", stemmer: "en"});
    for (let i = 0; i < pages.length; i++) {
      if (pages[i]) {
        this.index.add(i, pages[i]);
      }
    }
  }


  searchIndex(searchString: string): number[] {
    return this.index.search(searchString);
  }

}
