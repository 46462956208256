import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, Subject } from 'rxjs';
import { MenuNotificationService } from './menu-notification.service';
@Injectable()
export class PwaService {
  promptEvent;

  updateAvailable: BehaviorSubject<boolean>;

  private _updateAvailable = false;



  constructor(private swUpdate: SwUpdate,
    private menuNotificationService: MenuNotificationService) {
    window.addEventListener('beforeinstallprompt', event => {
      this.promptEvent = event;
    });

    this.updateAvailable = new BehaviorSubject<boolean>(this._updateAvailable);

    if (this.swUpdate.isEnabled) {

      this.swUpdate.unrecoverable.subscribe((event) => {
        console.error("Unrecoverable error.  Reloading application", event.reason);
        window.location.reload();
      });

      this.swUpdate.versionUpdates.subscribe((event) => {
        console.log("PWA VERSION EVENT", event);
        if (event.type === "VERSION_READY") {
          this._updateAvailable = true;
          menuNotificationService.addNotification("pwaUpdateAvailable");
          this.updateAvailable.next(this._updateAvailable);
        }

      });

      setInterval(async () => {
        //console.log("Checking for update");
        try {

          await this.swUpdate.checkForUpdate();
        }
        catch (error) {
          console.log(error);
        }
      }, .166 * 60 * 1000);
    }
    else {
      console.log("Browser does not support pwa");
    }
  }

  public get versionUpdates() {
    return this.swUpdate.versionUpdates;
  }

  get pwaAvailable(): boolean {
    return this.swUpdate.isEnabled;
  }

  checkForUpdate() {
    this.swUpdate.checkForUpdate();
  }

  async activateUpdate() {
    if (await this.swUpdate.activateUpdate()) {
      window.location.reload();
    }
  }

  promptForInstall() {
   this.promptEvent.prompt();

    this.promptEvent.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      this.promptEvent = null;
    });
  }


}
