import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PageHeaderComponent } from './ui/page-header/page-header.component';
import { RoutePagerComponent } from './ui/route-pager/route-pager.component';
import { TripSelectComponent } from './ui/trip-select/trip-select.component';
import { SwipeDirective } from './ui/swipe.directive';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { httpLoaderFactory } from '../localization/httpLoaderFactory';
import { SearchComponent } from './ui/search/search.component';
import { RouterModule } from '@angular/router';
import { HighlightDirective } from './ui/highlight.directive';


@NgModule({
  declarations: [
    PageHeaderComponent,
    RoutePagerComponent,
    TripSelectComponent,
    SwipeDirective,
  SearchComponent,
HighlightDirective],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [
    PageHeaderComponent,
    RoutePagerComponent,
    SwipeDirective,
    TripSelectComponent,
    SearchComponent,
    HighlightDirective
  ]
})
export class SharedModule { }
