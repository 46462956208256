import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {   concatMap, filter, firstValueFrom,    of, } from 'rxjs';
import { AuthService } from './auth/auth.service';
import {  MenuController } from '@ionic/angular';
import { PwaService } from './pwa.service';
import { ConflictData, LocalGuideService } from './pages/guide/localGuide.service';

import { StorageService } from './storage.service';
import { ModalController } from '@ionic/angular';
import { GuideConflictDialogComponent, GuideConflictDialogResponse } from './guide-conflict-dialog/guide-conflict-dialog.component';
import { TripsActive } from './api/classes/tripsActive';
import { TripSelectComponent } from './shared/ui/trip-select/trip-select.component';

import { ContentIndexService } from './content-index.service';
import { indexContents } from './pages/guide/content';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('tripSelect', { static: true }) tripSelect: TripSelectComponent;

  public appPages = [];
  public isConflictModalOpen = false;
  public guideConflictData: ConflictData;
  public trips: TripsActive[];



  constructor(
    private translationService: TranslateService,
    private router: Router,
    private authService: AuthService,
    private menu: MenuController,
    public pwaService: PwaService,
    private localGuideService: LocalGuideService,
    private storageService: StorageService,
    public modalController: ModalController,
    private indexService: ContentIndexService
  ) {

    this.indexService.loadIndex(indexContents);

    translationService.setDefaultLang('en');
    translationService.use('en');

    //google analytics
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.authService.getUserProfile().subscribe(
          profile => {
            let uid: string;
            if (profile?.ID) {
                uid =  profile.ID.toString();
            }

            let gtagParams: any = {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              page_path: event.urlAfterRedirects
            };

            if (uid) {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              gtagParams = { ...gtagParams, user_id: uid };
            }

            gtag('config', 'G-ZGHRE18CJE', gtagParams);
          }
        );

      });

    //EXAMPLE: How to chain multiple page links
    // firstValueFrom(this.translationService.get("homeMenu")).then(home => {
    //   this.appPages.push({ title: home, url: '/home', icon: 'home' });
    //   firstValueFrom(this.translationService.get("guideMenu")).then(guide => {
    //     this.appPages.push({ title: guide, url: '/guide', icon: 'book' });
    //   });
    // });

    firstValueFrom(this.translationService.get("guideMenu")).then(guide => {
      this.appPages.push({ title: guide, url: '/guide', icon: 'book' });
    });


  }
  ngOnInit(): void {

    //since this is the app component, we need to wait on the storage service to initialize fully.
    //There may be a better way to do this.
    this.storageService.isInitialized.subscribe(value => {
      if (value) {

      }
    });

    this.authService.onUserLoggedIn.subscribe((success) => {
      if (success) {

      }
    });

    this.authService.onUserLoggedOut.subscribe(() => {
      this.trips = [];
      this.tripSelect.selectedTripId = null;
    });

    this.localGuideService.syncConflict.pipe(concatMap(conflictData => {
      return of(conflictData);
    })).subscribe(async conflictData => {

      this.modalController.create({
        component: GuideConflictDialogComponent,
        componentProps: {
          conflictData: conflictData
        }
      }).then(modal => {
        modal.present().then(() => {
          modal.onWillDismiss<GuideConflictDialogResponse>().then(dialogResult => {
            if (dialogResult.data.resolutionType === "local") {
              this.localGuideService.resolveConflictWithLocal(dialogResult.data.page).subscribe((result) => { });
            }
            else if (dialogResult.data.resolutionType === "remote") {
              this.localGuideService.resolveConflictWithRemote(dialogResult.data.page).subscribe((result) => { });
            }
          });
        });
      });
    });
  }


  logout() {
    this.authService.logout().subscribe(result => {
      this.menu.close();
      this.router.navigate(["login"]);
    });
  }


}
