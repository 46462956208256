import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {  RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { httpLoaderFactory } from './localization/httpLoaderFactory';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { IonicStorageModule } from '@ionic/storage-angular';
import { JwtInterceptor } from './auth/token.interceptor';
import { PwaService } from './pwa.service';
import { MenuNotificationService } from './menu-notification.service';
import { GuideConflictDialogComponent } from './guide-conflict-dialog/guide-conflict-dialog.component';
import { SharedModule } from './shared/shared.module';
import { SearchComponent } from './shared/ui/search/search.component';
import { SearchPopoverComponent } from './search-popover/search-popover.component';
import { NotesPopoverComponent } from './notes-popover/notes-popover.component';
import { FormsModule } from '@angular/forms';





@NgModule({
    declarations: [
        AppComponent,
        GuideConflictDialogComponent,
        SearchPopoverComponent,
        NotesPopoverComponent
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:10000'
        }),
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        PwaService,
        MenuNotificationService,
    ],
    bootstrap: [AppComponent],
    exports: [SearchComponent]
})
export class AppModule { }
