import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConflictData, LocalGuideService, UserContentPage } from '../pages/guide/localGuide.service';


export type ResolutionType = "local" | "remote";

export interface GuideConflictDialogResponse {
  resolutionType: ResolutionType;
  page: UserContentPage;
}

@Component({
  selector: 'app-guide-conflict-dialog',
  templateUrl: './guide-conflict-dialog.component.html',
  styleUrls: ['./guide-conflict-dialog.component.scss'],
})
export class GuideConflictDialogComponent implements OnInit {

  @Input()
  conflictData: ConflictData;



  constructor(
    private localGuideService: LocalGuideService,
    private modalController: ModalController
  ) { }

  ngOnInit() { }


  keepLocal() {
     this.modalController.dismiss({
       resolutionType: "local",
       page: this.conflictData.page
     });
  }

  keepRemote() {
    this.modalController.dismiss({
      resolutionType: "remote",
      page: this.conflictData.page
    });
  }

}
